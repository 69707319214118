import { useBlocksYearStore } from '../../data/blocks-year-store';
import { ITag } from '../../../planner/planner-interfaces';
import { saveSelectedTagToStorage } from '../../domain/blocks-storage';

interface IBlocksTag {
    tag: ITag|null;
    title: string;
}

const BlocksTag = ({ tag, title }: IBlocksTag) => {

    const selectedTag = useBlocksYearStore(store => store.selectedTag);
    const setSelectedTag = useBlocksYearStore(store => store.setSelectedTag);
    const isTagSelected = selectedTag?.title === tag?.title;

    const onClick = () => {
        if(isTagSelected) {
            setSelectedTag(null);
            saveSelectedTagToStorage(null);
        }
        else{
            setSelectedTag(tag);
            saveSelectedTagToStorage(tag);
        }
    };

    return (
        <button
            onClick={ onClick }
            className={ `mr-2 mb-2 rounded px-2 py-1 ${ isTagSelected ? 'bg-cyan-700 text-slate-100' : 'bg-slate-100' }` }
            type="button">
            { title }
        </button>
    )
};

export default BlocksTag;