import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useEffect, useRef, useState } from 'react';
import { handleWiziwig } from '../../../../planner/domain/wiziwig-provider';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { IPlantType } from '../../../flowers-interfaces';
import { useFlowersStore } from '../../../data/flowers-store';
import { updatePlantType } from '../../../data/flowers-data-service';

interface IEditPlantType {
    plantType: IPlantType;
    close: () => void;
}

const EditPlantType = ({ plantType, close }: IEditPlantType) => {

    const nameRef = useRef<HTMLInputElement>(null);

    const setPlantTypesData = useFlowersStore(store => store.setPlantTypesData);

    const [name, setName] = useState('');
    const [nameTouched, setNameTouched] = useState(false);

    const [wateringFrequency, setWateringFrequency] = useState(0);
    const [wateringFrequencyTouched, setWateringFrequencyTouched] = useState(false);

    const [seasonalWeight, setSeasonalWeight] = useState(0);
    const [seasonalWeightTouched, setSeasonalWeightTouched] = useState(false);

    const [temperatureWeight, setTemperatureWeight] = useState(0);
    const [temperatureWeightTouched, setTemperatureWeightTouched] = useState(false);

    const [humidityWeight, setHumidityWeight] = useState(0);
    const [humidityWeightTouched, setHumidityWeightTouched] = useState(false);

    const [description, setDescription] = useState('');
    const [color, setColor] = useState('');
    const [isLoading, setLoading] = useState(false);

    const isNameValid = !nameTouched || name.trim().length > 0;
    const isWateringFrequencyValid = !wateringFrequencyTouched || wateringFrequency > 0;
    const isSeasonalWeightValid = !seasonalWeightTouched || seasonalWeight > 0;
    const isTemperatureWeightValid = !temperatureWeightTouched || temperatureWeight > 0;
    const isHumidityWeightValid = !humidityWeightTouched || humidityWeight > 0;

    useEffect(() => {
        nameRef?.current?.focus();
    }, []);

    useEffect(() => {
        setName(plantType.type_name);
        setDescription(plantType.type_desc);
        setColor(plantType.type_color);
        setWateringFrequency(plantType.base_watering_frequency);
        setSeasonalWeight(plantType.seasonal_weight);
        setTemperatureWeight(plantType.temperature_weight);
        setHumidityWeight(plantType.humidity_weight);
    }, [plantType]);

    const save = async () => {

        if(name.trim().length <= 0) {
            setNameTouched(true);
            return;
        }

        if(wateringFrequency <= 0) {
            setWateringFrequencyTouched(true);
            return;
        }

        if(seasonalWeight <= 0) {
            setSeasonalWeightTouched(true);
            return;
        }

        if(temperatureWeight <= 0) {
            setTemperatureWeightTouched(true);
            return;
        }

        if(humidityWeight <= 0) {
            setHumidityWeightTouched(true);
            return;
        }

        setLoading(true);

        const response = await updatePlantType(
            plantType.type_id,
            name,
            description,
            color,
            wateringFrequency,
            seasonalWeight,
            temperatureWeight,
            humidityWeight,
        );

        setLoading(false);

        if(!response) {
            showToast('Update plant type error.');
            return;
        }

        setPlantTypesData(response);
        close();
    };

    const onNameChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setName((evt.target as HTMLInputElement).value);
        setNameTouched(true);
    };

    const onDescChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setDescription((evt.target as HTMLTextAreaElement).value);
    };

    const onColorChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setColor((evt.target as HTMLInputElement).value);
    };

    const onBaseWateringFrequencyChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = Number((evt.target as HTMLInputElement).value) || 0;
        setWateringFrequency(value);
    };

    const onSeasonalWeightChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = Number((evt.target as HTMLInputElement).value) || 0;
        setSeasonalWeight(value);
    };

    const onTemperatureWeightChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = Number((evt.target as HTMLInputElement).value) || 0;
        setTemperatureWeight(value);
    };

    const onHumidityWeightChange = (evt: ChangeEvent<HTMLInputElement>) => {
        const value = Number((evt.target as HTMLInputElement).value) || 0;
        setHumidityWeight(value);
    };

    const onNameKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {
        if(handleWiziwig(evt, nameRef, (newText) => {
            setName(newText);
            setNameTouched(true);
        })){
            return;
        }

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    const onGeneralKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement|HTMLTextAreaElement>) => {

        if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
            evt.preventDefault();
            await save();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
        }
    };

    return (
        <div>
            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Plant Type Name</div>
                <input
                    ref={ nameRef }
                    value={ name }
                    onInput={ onNameChange }
                    onKeyDown={ onNameKeyDown }
                    className={ `border rounded px-4 py-2 ${ isNameValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isNameValid && <div className="text-red-700 text-xs mt-1">The name is required.</div>
                }
            </label>

            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Plant Type Description</div>
                <textarea
                    value={ description }
                    onInput={ onDescChange }
                    onKeyDown={ onGeneralKeyDown }
                    className={ `border rounded px-4 py-2` }
                    rows={ 4 }
                />
            </label>

            <label className="flex flex-col mb-4">
                <div className="font-bold text-slate-400 mb-2">Plant Type Color</div>
                <input
                    value={ color }
                    onInput={ onColorChange }
                    onKeyDown={ onGeneralKeyDown }
                    className={ `border rounded px-4 py-2` }
                    type="text"
                />
            </label>

            <div className="grid grid-cols-2 gap-4">
                <label className="flex flex-col mb-4">
                    <div className="font-bold text-slate-400 mb-2 text-sm md:text-base">Base Watering Frequency</div>
                    <input
                        value={ wateringFrequency }
                        onInput={ onBaseWateringFrequencyChange }
                        onKeyDown={ onGeneralKeyDown }
                        className={ `border rounded px-4 py-2` }
                        type="number"
                    />

                    {
                        !isWateringFrequencyValid &&
                        <div className="text-red-700 text-xs mt-1">The base watering frequency is required.</div>
                    }
                </label>

                <label className="flex flex-col mb-4">
                    <div className="font-bold text-slate-400 mb-2 text-sm md:text-base">Seasonal Weight</div>
                    <input
                        value={ seasonalWeight }
                        onInput={ onSeasonalWeightChange }
                        onKeyDown={ onGeneralKeyDown }
                        className={ `border rounded px-4 py-2` }
                        type="number"
                    />

                    {
                        !isSeasonalWeightValid &&
                        <div className="text-red-700 text-xs mt-1">The seasonal weight is required.</div>
                    }
                </label>

                <label className="flex flex-col mb-4">
                    <div className="font-bold text-slate-400 mb-2 text-sm md:text-base">Temperature Weight</div>
                    <input
                        value={ temperatureWeight }
                        onInput={ onTemperatureWeightChange }
                        onKeyDown={ onGeneralKeyDown }
                        className={ `border rounded px-4 py-2` }
                        type="number"
                    />

                    {
                        !isTemperatureWeightValid &&
                        <div className="text-red-700 text-xs mt-1 text-sm md:text-base">The temperature weight is required.</div>
                    }
                </label>

                <label className="flex flex-col mb-4">
                    <div className="font-bold text-slate-400 mb-2">Humidity Weight</div>
                    <input
                        value={ humidityWeight }
                        onInput={ onHumidityWeightChange }
                        onKeyDown={ onGeneralKeyDown }
                        className={ `border rounded px-4 py-2` }
                        type="number"
                    />

                    {
                        !isHumidityWeightValid &&
                        <div className="text-red-700 text-xs mt-1">The humidity weight is required.</div>
                    }
                </label>

            </div>

            <div className="flex items-center justify-end text-sm mt-4">

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' }/>
                }

                {
                    !isLoading &&
                    <>
                        <button
                            className="bg-slate-500 text-slate-100 rounded px-6 py-2 mr-4"
                            onClick={ close }
                            type="button">Close
                        </button>

                        <button
                            className="bg-teal-500 text-slate-100 rounded px-6 py-2"
                            onClick={ save }
                            type="button">Save
                        </button>
                    </>
                }
            </div>

        </div>
    )
};

export default EditPlantType;