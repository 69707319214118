import { useFlowersStore } from '../../../data/flowers-store';
import Plant from './plant';
import { getFilteredPlants } from '../../../domain/flowers-provider';

interface IPlantsList {
    onUpdate: () => void;
}

const PlantsList = ({ onUpdate }: IPlantsList) => {

    const plantsData = useFlowersStore(store => store.plantsData);
    const showNonAlive = useFlowersStore(store => store.showNonAlive);
    const plants = getFilteredPlants(plantsData?.plants, showNonAlive);

    return (
        <div>
            {
                plants.map(plant => {
                    return (
                        <Plant
                            key={ plant.plant_id }
                            plant={ plant }
                            onUpdate={ onUpdate }
                        />
                    )
                })
            }
        </div>
    )
};

export default PlantsList;