import { ArrowLeftIcon } from '../../../../../common/ui/icons';
import { useBlocksYearStore } from '../../../data/blocks-year-store';

const PrevYearButton = () => {

    const currentYear = useBlocksYearStore(store => store.currentYear);
    const setCurrentYear = useBlocksYearStore(store => store.setCurrentYear);

    const jumpToPrevMonth = () => {
        setCurrentYear(currentYear - 1);
    };

    return (
        <button
            onClick={ jumpToPrevMonth }
            type="button">
            <ArrowLeftIcon />
        </button>
    )
};

export default PrevYearButton;