import { useState, MouseEvent } from 'react';
import { useFlowersStore } from '../../../data/flowers-store';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { showToast } from '../../../../../common/domain/toast-provider';
import { deletePlantGalleryImage } from '../../../data/flowers-data-service';

interface IDeletePlantGalleryImageButton {
    id: number;
}

const DeletePlantGalleryImageButton = ({ id }: IDeletePlantGalleryImageButton) => {

    const [isLoading, setLoading] = useState(false);

    const setPlantsGalleryData = useFlowersStore(store => store.setPlantsGalleryData);

    const deleteHandler = async (evt: MouseEvent<HTMLButtonElement>) => {
        evt.stopPropagation();
        evt.preventDefault();

        setLoading(true);
        const response = await deletePlantGalleryImage(id);
        setLoading(false);

        if(!response) {
            showToast('Delete image error.');
            return;
        }

        setPlantsGalleryData(response);
        sendCloseActionsEvent();
    };

    return (
        <button
            onClick={ deleteHandler }
            type="button"
            title="Delete Image">

            {
                !isLoading &&
                <DeleteIcon size={ 20 } classes="pointer-events-none" />
            }

            {
                isLoading &&
                <PreloaderIcon size={ 20 } />
            }
        </button>
    )
};

export default DeletePlantGalleryImageButton;