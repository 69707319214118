import { IFoodCalendarDay, IFoodCalendarMonth } from '../../food-interfaces';

interface IMonthView {
    month: IFoodCalendarMonth;
}

const weekTitleClasses = 'bg-slate-50 text-slate-900 p-1 text-xs flex items-center justify-center';

const MonthView = ({ month }: IMonthView) => {

    const getDayClasses = (day: IFoodCalendarDay) => {
        if(day.calories > month.dailyCalories) {
            return 'text-red-700';
        }

        if(day.isTodayDay) {
            return 'text-sky-700 font-bold';
        }

        return 'text-black';
    };

    const emptyCells = [];

    for(let i= 0; i<month.startDayOfWeek; i++) {
        emptyCells.push(<div className="bg-slate-50 p-1">&nbsp;</div>);
    }

    return (
        <div className="border-l border-t p-2 text-slate-500">
            <div className="mb-2 flex items-center justify-between">
                <span>{ month.monthName }</span>

                {
                    !!month.avgCalories &&
                    <>
                        <span
                            className="text-sm mx-auto">{ month.goalWeight } кг</span>
                        <span
                            className="text-sm">{ Math.round(month.avgCalories) } / { month.dailyCalories } ккал</span>
                    </>
                }
            </div>

            <div className="grid grid-cols-7 gap-1">
            <div className={ weekTitleClasses }>ВК</div>
                <div className={ weekTitleClasses }>ПН</div>
                <div className={ weekTitleClasses }>ВТ</div>
                <div className={ weekTitleClasses }>СР</div>
                <div className={ weekTitleClasses }>ЧТ</div>
                <div className={ weekTitleClasses }>ПТ</div>
                <div className={ weekTitleClasses }>СБ</div>

                { emptyCells }

                {
                    month.days?.map(day => {
                        return (
                            <div key={ day.id }
                                 className={ `bg-slate-50 p-1 ${ day.isTodayDay ? 'text-sky-700' : '' }` }>
                                <div className="mb-1 text-right text-xs">{ day.dayName }</div>
                                <div className={ `text-center text-sm ${ getDayClasses(day) }` }>
                                    { day.calories > 0 && Math.round(day.calories).toLocaleString() }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
};

export default MonthView;