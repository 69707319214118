import { get, postJson, putJson } from '../../../common/data/common-data-service';
import { IBlocksResponse } from '../blocks-year-interfaces';
import { TodoType } from '../../planner/planner-interfaces';
import { ICalendarData } from '../../calendar/calendar-interfaces';

export const getBlocksYearData = async (from: number, to: number, tag_id: number) : Promise<IBlocksResponse> => {
    return await get(`/api/plan/blocks/get?from=${ from }&to=${ to }&tag_id=${tag_id}`);
};

export const blocksUpdateTodo = async (

    // Blocks data that is used to render blocks view ------
    from: number,
    to: number,
    tag_id: number,

    // Todo_ data -------------
    todo_id: number|string,
    new_title: string,
    new_desc: string,
    todo_date: number|null,
    todo_date_has_time: number,
    todo_is_active: number,
    todo_type: TodoType,
    update_nested: boolean,
    tags: string,
    todo_address: string,
    show_in_overview: number,
    ideal_final_result: string,
    habit_active?: boolean,
    habit_interval?: number,
    habit_qty_per_interval?: number,
    habit_week_days?: string,
    projectId?: number,
    sectionId?: number,
) : Promise<ICalendarData> => {
    return await putJson(`/api/plan/blocks/todo-update`, {

        // Blocks data that is used to render blocks view ------
        from,
        to,
        tag_id,

        // Todo_ data -------------
        todo_id,
        new_title,
        new_desc,
        todo_date,
        todo_date_has_time,
        todo_is_active,
        todo_type,
        update_nested,
        tags,
        todo_address,
        ideal_final_result,
        habit_active,
        habit_interval,
        habit_qty_per_interval,
        habit_week_days,
        show_in_overview,
        projectId,
        sectionId,
    });
};


// ------ BLOCKS ----------------------------

export const insertBlockForYBlocks = async (

    // Blocks data that is used to render blocks view ------
    from: number,
    to: number,
    tag_id: number,

    // The actual data ---------------
    todo_id: string|number,
    start_date: number,
    end_date: number
) => {
    return await postJson(`/api/plan/blocks/insert-for-yblocks`, {
        from,
        to,
        tag_id,
        todo_id,
        start_date,
        end_date,
    });
};

export const updateBlockForYBlocks = async (

    // Blocks data that is used to render blocks view ------
    from: number,
    to: number,
    tag_id: number,

    // The actual data ---------------
    block_id: number,
    start_date: number,
    end_date: number
) => {
    return await putJson(`/api/plan/blocks/update-for-yblocks`, {
        from,
        to,
        tag_id,
        block_id,
        start_date,
        end_date,
    });
};

export const deleteBlockForYBlocks = async (
    // Blocks data that is used to render blocks view ------
    from: number,
    to: number,
    tag_id: number,

    // The actual data ---------------
    block_id: number,
) => {
    return await postJson(`/api/plan/blocks/delete-for-yblocks`, {
        from,
        to,
        tag_id,
        block_id,
    });
};
