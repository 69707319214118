import { useFoodStore } from '../../../data/food-store';
import FoodGoal from './food-goal';

const FoodGoalsList = () => {
    const foodGoalsData = useFoodStore(store => store.foodGoalsData);

    return (
        <div>
            {
                foodGoalsData?.map((foodGoal, index) => {
                    return (
                        <FoodGoal
                            key={ foodGoal.id }
                            foodGoal={ foodGoal }
                            index={ index }
                        />
                    )
                })
            }
        </div>
    )
};

export default FoodGoalsList;