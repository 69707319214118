import { useBlocksYearStore } from '../../data/blocks-year-store';
import CommonBlocksYearMonthView from '../../../../common/ui/blocks-year/common-month-view';

const YearView = () => {

    const setOpenedEditTodo = useBlocksYearStore(store => store.setOpenedEditTodo);

    const data = useBlocksYearStore(store => store.data);

    return (
        <div className="grid md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 mt-4 gap-4">
            {
                data?.months?.map(month => {
                    return (
                        <CommonBlocksYearMonthView
                            key={ month.id }
                            month={ month }
                            setOpenedEditTodo={ setOpenedEditTodo }
                        />
                    )
                })
            }
        </div>
    )
};

export default YearView;