import { DecisionTreeEdge } from './decision-tree-edge';
import { v4 as uuidv4 } from 'uuid';

export const NODE_WIDTH = 160;
export const NODE_HEIGHT = 50;

export class DecisionTreeNode {
    id: string;
    text: string;

    isRootNode: boolean;
    entropy: number;

    edges: DecisionTreeEdge[];

    x: number;
    y: number;

    path?: JSX.Element;
    calculation?: JSX.Element;

    constructor(
        text: string,
        path: JSX.Element,
        isRootNode: boolean,
        entropy: number,
    ) {
        this.id = uuidv4();
        this.isRootNode = isRootNode;
        this.entropy = entropy;

        this.text = text;
        this.path = path;

        this.edges = [];

        this.x = 0;
        this.y = 0;
    }

    public draw() {

        const isLeaf = this.edges.length === 0;

        return (
            <g key={ `node-${ this.id }` }>
                <rect
                    x={ this.x }
                    y={ this.y }
                    width={ NODE_WIDTH }
                    height={ NODE_HEIGHT }
                    fill={ isLeaf ? '#f8f2c7' : '#f0f0f0' }
                    stroke={ isLeaf ? '#d6d0a5' : '#bcbcbc' }
                    rx={ isLeaf ? 0 : 20 }
                    ry={ isLeaf ? 0 : 20 }
                />

                <text
                    x={ this.x + NODE_WIDTH/2 }
                    y={ this.y + NODE_HEIGHT/2 }
                    textAnchor="middle"
                    fontSize={ 12 }
                    dominantBaseline="middle"
                >
                    { this.text } ({ this.entropy })
                </text>
            </g>
        )
    }

    public getRightX() {
        return this.x + NODE_WIDTH;
    }

    public getBottomY() {
        return this.y + NODE_HEIGHT;
    }
}