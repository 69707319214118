import { IDataFlowDiagramData } from './interfaces';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DrawIoEmbed, DrawIoEmbedRef, EventExport } from 'react-drawio';
import { createDfdDiagramXml } from './domain/dfd-diagram-provider';
import { copyImageToClipboard, downloadImage } from '../../../../../common/domain/io-provider';
import { getDfdDiagramFromStorage2, setDfdDiagramToStorage2 } from './domain/decision-diagram-storage';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IDataFlowDiagramData = {
        "entities": [
            {
                "id": "E1",
                "name": "מפיץ",
                "inputs": [
                    "הזמנות מוצרים למחסן",
                    "החזרת מוצרים פגומים למחסן"
                ],
                "outputs": [
                    "אספקת מוצרים מהמחסן",
                    "דו&quot;ח פעילות שבועי למנהל הרשת"
                ]
            },
            {
                "id": "E2",
                "name": "מחסנאי",
                "inputs": [
                    "הזמנות מספקים",
                    "החזרת מוצרים פגומים ליצרן"
                ],
                "outputs": [
                    "אספקת מוצרים למפיצים",
                    "דו&quot;ח חודשי למנהל"
                ]
            },
            {
                "id": "E3",
                "name": "ספק",
                "inputs": [
                    "הזמנת מוצרים מהמחסן"
                ],
                "outputs": [
                    "אספקת מוצרים למחסן"
                ]
            },
            {
                "id": "E4",
                "name": "מנהל רשת",
                "inputs": [
                    "דו&quot;ח חודשי מהמחסן",
                    "דו&quot;ח פעילות שבועי מכל מפיץ"
                ],
                "outputs": [
                    "בקשות לדיווחים מיוחדים למחסן"
                ]
            }
        ],
        "processes": [
            { "id": "1.0", "name": "ניהול מלאי במחסן" },
            { "id": "2.0", "name": "אספקת מוצרים למפיצים" },
            { "id": "3.0", "name": "ניהול מוצרים פגומים" },
            { "id": "4.0", "name": "טיפול בעומס במחסן" },
            { "id": "5.0", "name": "דיווח ובקרה" }
        ],
        "dataStores": [
            { "id": "D1", "name": "מאגר נתוני המלאי במחסן" },
            { "id": "D2", "name": "מאגר הזמנות מפיצים" },
            { "id": "D3", "name": "מאגר מוצרים פגומים" }
        ]
    }
;

const DfdDiagramHome2 = () => {

    const drawIoRef = useRef<DrawIoEmbedRef>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IDataFlowDiagramData>(initialData);

    useEffect(() => {
        if(!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onDiagramSave = async (data: EventExport) => {
        downloadImage(data.data, 'dfd-diagram.png');
        await copyImageToClipboard(data.data);
    };

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    useEffect(() => {
        const storageData = getDfdDiagramFromStorage2();
        if(!storageData) return;

        if(textAreaRef.current) {
            textAreaRef.current.value = storageData;
        }

        let parsed: IDataFlowDiagramData|null = null;

        try{
            parsed = JSON.parse(storageData) as IDataFlowDiagramData;
        }
        catch (error) {
            console.log(error);
        }

        if(!parsed) return;

        setData(parsed);
    }, []);

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setDfdDiagramToStorage2(evt.target.value);
    };

    return(
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">דיאגרמת בועות (DFD)</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    className="border rounded w-full p-2"
                    onChange={ onChange }
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={ {
                         height: UI_HEIGHT,
                     } }>
                    <DrawIoEmbed
                        ref={ drawIoRef }
                        urlParameters={ {
                            // ui: 'kennedy',

                            // Shows a 'Loading…' spinner while waiting
                            // for the diagram data to load in embed mode.
                            spin: true,

                            // Specifies whether libraries should be enabled in embed mode.
                            // The default is disabled.
                            // libraries: true,


                            noExitBtn: true,
                        } }
                        exportFormat={ 'png' }
                        onExport={ onDiagramSave }
                        xml={ createDfdDiagramXml(data) }
                    />
                </div>
            </div>

        </div>
    )
};

export default DfdDiagramHome2;
