import { useState } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { useFinanceStore } from '../../../data/finance-store';
import { IFinanceGasItem } from '../../../finance-interfaces';
import { deleteGasItem } from '../../../data/finance-data-service';

interface IDeleteGas {
    gas: IFinanceGasItem;
}

const DeleteGas = ({ gas }: IDeleteGas) => {

    const [isLoading, setLoading] = useState(false);

    const setGasData = useFinanceStore(store => store.setGasData);
    const gasYear = useFinanceStore(store => store.gasYear);
    const gasFilterCar = useFinanceStore(store => store.gasFilterCar);

    const deleteGasHandler = async () => {

        if(!confirm(`Are you sure you want to delete this gas item?`)) return;

        setLoading(true);
        const response = await deleteGasItem(gas.GasID, gasYear, gasFilterCar);
        setLoading(false);

        if(!response) {
            showToast('Delete gas error.');
            return;
        }

        setGasData(response);
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Gas"
                    className="gas__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteGasHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeleteGas;