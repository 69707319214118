import { IClassDiagram } from './interfaces';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DrawIoEmbed, DrawIoEmbedRef, EventExport } from 'react-drawio';
import { createDfdDiagramXml } from './domain/class-diagram-provider';
import { copyImageToClipboard, downloadImage } from '../../../../../common/domain/io-provider';
import { getClassDiagramFromStorage, setClassDiagramToStorage } from './domain/class-diagram-storage';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IClassDiagram = {
        "classes": [
            {
                "id": "1",
                "name": "Customer",
                "properties": [
                    { "name": "CustomerID" },
                    { "name": "Name" },
                    { "name": "Email" },
                    { "name": "Phone" }
                ],
                "methods": [
                    { "signature": "Retrieve(CustomerID)" },
                    { "signature": "Save(CustomerID)" }
                ]
            },
            {
                "id": "2",
                "name": "Order",
                "properties": [
                    { "name": "OrderID" },
                    { "name": "OrderDate" },
                    { "name": "TotalAmount" }
                ],
                "methods": [
                    { "signature": "PlaceOrder(CustomerID)" },
                    { "signature": "CancelOrder(OrderID)" }
                ]
            },
            {
                "id": "3",
                "name": "Product",
                "properties": [
                    { "name": "ProductID" },
                    { "name": "Name" },
                    { "name": "Price" },
                    { "name": "StockQuantity" }
                ],
                "methods": [
                    { "signature": "CheckStock(ProductID)" },
                    { "signature": "UpdateStock(ProductID, Quantity)" }
                ]
            },
            {
                "id": "4",
                "name": "Invoice",
                "properties": [
                    { "name": "InvoiceID" },
                    { "name": "InvoiceDate" },
                    { "name": "TotalAmount" }
                ],
                "methods": [
                    { "signature": "GenerateInvoice(OrderID)" },
                    { "signature": "Print(InvoiceID)" }
                ]
            },
            {
                "id": "5",
                "name": "Payment",
                "properties": [
                    { "name": "PaymentID" },
                    { "name": "PaymentDate" },
                    { "name": "Amount" },
                    { "name": "PaymentMethod" }
                ],
                "methods": [
                    { "signature": "ProcessPayment(OrderID, Amount)" },
                    { "signature": "Refund(PaymentID)" }
                ]
            }
        ],
        "relationships": [
            {
                "id": "r1",
                "name": "Places",
                "from": "1",
                "to": "2",
                "cardinalityFrom": "1",
                "cardinalityTo": "0..*"
            },
            {
                "id": "r2",
                "name": "Contains",
                "from": "2",
                "to": "3",
                "cardinalityFrom": "1",
                "cardinalityTo": "1..*"
            },
            {
                "id": "r3",
                "name": "Generates",
                "from": "2",
                "to": "4",
                "cardinalityFrom": "1",
                "cardinalityTo": "0..1"
            },
            {
                "id": "r4",
                "name": "Pays",
                "from": "1",
                "to": "5",
                "cardinalityFrom": "1",
                "cardinalityTo": "0..*"
            },
            {
                "id": "r5",
                "name": "RelatesTo",
                "from": "5",
                "to": "2",
                "cardinalityFrom": "1",
                "cardinalityTo": "1"
            }
        ]
    }
;

const ClassDiagramHome = () => {

    const drawIoRef = useRef<DrawIoEmbedRef>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IClassDiagram>(initialData);

    useEffect(() => {
        if(!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onDiagramSave = async (data: EventExport) => {
        downloadImage(data.data, 'class-diagram.png');
        await copyImageToClipboard(data.data);
    };

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    useEffect(() => {
        const storageData = getClassDiagramFromStorage();
        if(!storageData) return;

        if(textAreaRef.current) {
            textAreaRef.current.value = storageData;
        }

        let parsed: IClassDiagram|null = null;

        try{
            parsed = JSON.parse(storageData) as IClassDiagram;
        }
        catch (error) {
            console.log(error);
        }

        if(!parsed) return;

        setData(parsed);
    }, []);

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setClassDiagramToStorage(evt.target.value);
    };

    return(
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">דיאגרמת מחלקות</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>

                <p className="mx-4 text-sm text-sky-800">Нужно обязательно добавить методы на экзамене!</p>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    onChange={ onChange }
                    className="border rounded w-full p-2"
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={ {
                         height: UI_HEIGHT,
                     } }>
                    <DrawIoEmbed
                        ref={ drawIoRef }
                        urlParameters={ {
                            // ui: 'kennedy',

                            // Shows a 'Loading…' spinner while waiting
                            // for the diagram data to load in embed mode.
                            spin: true,

                            // Specifies whether libraries should be enabled in embed mode.
                            // The default is disabled.
                            // libraries: true,


                            noExitBtn: true,
                        } }
                        exportFormat={ 'png' }
                        onExport={ onDiagramSave }
                        xml={ createDfdDiagramXml(data) }
                    />
                </div>
            </div>

        </div>
    )
};

export default ClassDiagramHome;
