import { IActivityDiagram } from './interfaces';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DrawIoEmbed, DrawIoEmbedRef, EventExport } from 'react-drawio';
import { createActivityDiagramXml } from './domain/activity-diagram-provider';
import { copyImageToClipboard, downloadImage } from '../../../../../common/domain/io-provider';
import { getActivityDiagramFromStorage, setActivityDiagramToStorage } from './domain/activity-diagram-storage';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IActivityDiagram = {
        "lanes": [
            { "id": "customer", "name": "Customer" },
            { "id": "reception", "name": "Reception Desk" },
            { "id": "parking", "name": "Parking Lot" }
        ],
        "activityGroups": [
            {
                "id": "group1",
                "activities": [
                    { "id": "reserveCar", "name": "Reserve a Car", "laneId": "customer" },
                    { "id": "confirmReservation", "name": "Confirm Reservation", "laneId": "reception" },
                    { "id": "transferCar", "name": "Transfer Car", "laneId": "parking" }
                ],
                "transitions": [
                    { "from": "reserveCar", "to": "confirmReservation" },
                    { "from": "confirmReservation", "to": "transferCar" }
                ]
            },
            {
                "id": "group2",
                "activities": [
                    { "id": "pickUpCar", "name": "Pick-Up Car", "laneId": "customer" },
                    { "id": "printContract", "name": "Print Contract", "laneId": "reception" }
                ],
                "transitions": []
            },
            {
                "id": "group3",
                "activities": [
                    { "id": "returnCar", "name": "Return Car", "laneId": "customer" },
                    { "id": "checkCar", "name": "Check the Car", "laneId": "parking" }
                ],
                "transitions": [
                    { "from": "returnCar", "to": "checkCar" }
                ]
            },
            {
                "id": "group4",
                "activities": [
                    { "id": "payBill", "name": "Pay the Bill", "laneId": "customer" },
                    { "id": "debitCustomer", "name": "Debit Customer", "laneId": "reception" }
                ],
                "transitions": []
            },
            {
                "id": "group5",
                "activities": [
                    { "id": "closeReservation", "name": "Close Reservation", "laneId": "reception" },
                    { "id": "printInvoice", "name": "Print Invoice", "laneId": "reception" }
                ],
                "transitions": [
                    { "from": "closeReservation", "to": "printInvoice" }
                ]
            }
        ],
        "startEvent": {
            "id": "start",
            "type": "start",
            "transition": { "from": "start", "to": "reserveCar" }
        },
        "endEvent": {
            "id": "end",
            "type": "end",
            "transitions": [
                { "from": "printInvoice", "to": "end" }
            ]
        }
    }
;

const ActivityDiagramHome = () => {

    const drawIoRef = useRef<DrawIoEmbedRef>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IActivityDiagram>(initialData);

    useEffect(() => {
        if(!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onDiagramSave = async (data: EventExport) => {
        downloadImage(data.data, 'activity-diagram.png');
        await copyImageToClipboard(data.data);
    };

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    useEffect(() => {
        const storageData = getActivityDiagramFromStorage();
        if(!storageData) return;

        if(textAreaRef.current) {
            textAreaRef.current.value = storageData;
        }

        let parsed: IActivityDiagram|null = null;

        try{
            parsed = JSON.parse(storageData) as IActivityDiagram;
        }
        catch (error) {
            console.log(error);
        }

        if(!parsed) return;

        setData(parsed);
    }, []);

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setActivityDiagramToStorage(evt.target.value);
    };

    return(
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">דיאגרמת פעילויות</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    onChange={ onChange }
                    className="border rounded w-full p-2"
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={ {
                         height: UI_HEIGHT,
                     } }>
                    <DrawIoEmbed
                        ref={ drawIoRef }
                        urlParameters={ {
                            // ui: 'kennedy',

                            // Shows a 'Loading…' spinner while waiting
                            // for the diagram data to load in embed mode.
                            spin: true,

                            // Specifies whether libraries should be enabled in embed mode.
                            // The default is disabled.
                            // libraries: true,


                            noExitBtn: true,
                        } }
                        exportFormat={ 'png' }
                        onExport={ onDiagramSave }
                        xml={ createActivityDiagramXml(data) }
                    />
                </div>
            </div>

        </div>
    )
};

export default ActivityDiagramHome;
