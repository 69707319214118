import { useFlowersStore } from '../../../data/flowers-store';
import { trackWaterLog } from '../../../data/flowers-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { endOfDay, startOfDay } from 'date-fns';
import { calculateNextWateringDate, FLOWERS_WATER_LOG_UPDATED } from '../../../domain/flowers-provider';
import { IPlant } from '../../../flowers-interfaces';
import { getWeather } from '../../../domain/weather-provider';

interface ITrackBtn {
    plant: IPlant;
}

const TrackBtn = ({ plant }: ITrackBtn) => {

    const setWaterLogsData = useFlowersStore(store => store.setWaterLogsData);

    const onClick = async () => {

        const now = startOfDay(new Date());
        const today_end = endOfDay(now).getTime();

        const next_watering = calculateNextWateringDate(
            now.getTime(), // lastWatered
            plant.plantType,
            plant.location,
            getWeather(),
        );

        const response = await trackWaterLog(
            today_end,
            plant.plant_id,
            now.getTime(),
            next_watering,
            '',
        );

        if(!response) {
            showToast('Track water logs error.');
            return;
        }

        setWaterLogsData(response);

        document.dispatchEvent(new CustomEvent(FLOWERS_WATER_LOG_UPDATED));
    };

    return (
        <button
            onClick={ onClick }
            className="bg-teal-100 border border-teal-500 text-teal-700 rounded-xl py-1 my-4 flex items-center text-sm"
            type="button">
            <span className="ml-2 mr-4">🌺</span>
            <span className="mr-8">Complete</span>
        </button>
    )
};

export default TrackBtn;