import { useEffect } from 'react';
import { getPlantGalleryData } from '../../data/flowers-data-service';
import { useFlowersStore } from '../../data/flowers-store';
import { showToast } from '../../../../common/domain/toast-provider';
import UploadImage from './upload-image';
import Actions from '../../../../common/ui/actions';
import DeletePlantGalleryImageButton from './actions/delete-btn';

const ImagesList = () => {

    const plantsGalleryData = useFlowersStore(store => store.plantsGalleryData);
    const setPlantsGalleryData = useFlowersStore(store => store.setPlantsGalleryData);

    useEffect(() => {
        (async () => {
            const response = await getPlantGalleryData();

            if(!response) {
                showToast('Get gallery data error.');
                return;
            }

            setPlantsGalleryData(response || []);
        })();
    }, [setPlantsGalleryData]);

    return (
        <div className="grid md:grid-cols-3 gap-4">
            {
                plantsGalleryData.map(galleryItem => {
                    return (
                        <a href={ `/api/flowers/attachment/get?path=${ galleryItem.path }` }
                            key={ galleryItem.id }
                            className="flex flex-col items-center justify-center bg-slate-800 rounded px-2 pt-2">
                            <img
                                className="shadow rounded oveflow-hidden flex-1"
                                src={ `/api/flowers/attachment/get?path=${ galleryItem.path }` }
                                alt=""
                                title=""
                                style={{
                                    maxHeight: '300px',
                                }}
                            />

                            <Actions mr="mr-0" ml="ml-auto" dotsIconColor={ '#fff' }>
                                <DeletePlantGalleryImageButton id={ galleryItem.id } />
                            </Actions>
                        </a>
                    )
                })
            }

            <UploadImage />
        </div>
    )
};

export default ImagesList;