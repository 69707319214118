import { useFoodStore } from '../../../data/food-store';
import { setFoodCategoryToStorage } from '../../../domain/food-storage';

interface ICategoriesRadioFilter {
    categoryID: number;
    categoryName: string;
    foodItemsCount: number;
}

const FoodCategoriesRadioFilter = ({ categoryID, categoryName, foodItemsCount }: ICategoriesRadioFilter) => {

    const foodItemsSelectedCategoryID = useFoodStore(store => store.foodItemsSelectedCategoryID);
    const setFoodItemsSelectedCategoryID = useFoodStore(store => store.setFoodItemsSelectedCategoryID);
    const setFoodItemsPageNumber = useFoodStore(store => store.setFoodItemsPageNumber);

    const onChange = () => {
        setFoodItemsSelectedCategoryID(categoryID);
        setFoodItemsPageNumber(0);
        setFoodCategoryToStorage(categoryID);
    };

    return (
        <label key={ categoryID } className="mr-3 flex items-start mb-2">
            <input
                type="radio"
                name="food-radio-categories"
                value={ categoryID }
                checked={ foodItemsSelectedCategoryID === categoryID }
                onChange={ onChange }
            />
            <div className="flex items-start ml-2" style={{
                marginTop: '-2px',
            }}>
                <span className="mr-2">{ categoryName ?? 'Без Категории' }</span>

                {
                    foodItemsCount> 0 &&
                    <span className="text-xs">({ foodItemsCount })</span>
                }
            </div>
        </label>
    )
};

export default FoodCategoriesRadioFilter;