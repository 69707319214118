import FoodCategoriesTabsNavigation, { FoodGoalsTab } from './tabs-navigation';
import { useFoodStore } from '../../data/food-store';
import { useEffect } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import { getAllFoodGoals } from '../../data/food-data-service';
import FoodGoalsList from './list/food-goals-list';
import UpsertFoodGoal from './actions/upsert-food-goal';

const FoodGoalsHome = () => {

    const foodGoalsData = useFoodStore(store => store.foodGoalsData);
    const setFoodGoalsData = useFoodStore(store => store.setFoodGoalsData);
    const foodGoalsTab = useFoodStore(store => store.foodGoalsTab);

    useEffect(() => {
        (async () => {
            const response = await getAllFoodGoals();

            if(!response) {
                showToast('Get food goals error.');
                return;
            }

            setFoodGoalsData(response);
        })();
    }, [setFoodGoalsData]);

    return (
        <div className="xl:max-w-[800px] max-w-full mx-auto py-10">
            <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                Food Goals
            </h1>

            <FoodCategoriesTabsNavigation />

            {
                foodGoalsTab === FoodGoalsTab.Default &&
                foodGoalsData?.length > 0 &&
                <>
                    <FoodGoalsList />
                </>

            }

            {
                foodGoalsTab === FoodGoalsTab.AddNew &&
                <UpsertFoodGoal
                    type="insert"
                />
            }

        </div>
    )
};

export default FoodGoalsHome;