import { create } from 'zustand';
import { IBlocksCalendarYear } from '../blocks-year-interfaces';
import { ITag, ITodo } from '../../planner/planner-interfaces';
import { getSelectedTagFromStorage } from '../domain/blocks-storage';

interface BlocksYearState {
    currentYear: number;
    setCurrentYear: (_currentYear: number) => void;

    data: IBlocksCalendarYear|null;
    setData: (_data: IBlocksCalendarYear|null) => void;

    openedEditTodo: ITodo|null;
    setOpenedEditTodo: (_openedEditTodo: ITodo|null) => void;

    selectedTag: ITag|null;
    setSelectedTag: (_selectedTag: ITag|null) => void;
}

export const useBlocksYearStore = create<BlocksYearState>()((set) => ({

    currentYear: (new Date()).getFullYear(),
    setCurrentYear: (_currentYear: number) => {
        return set((state: BlocksYearState) => {
            return {
                ...state,
                currentYear: _currentYear,
            };
        });
    },

    data: null,
    setData: (_data: IBlocksCalendarYear|null) => {
        return set((state: BlocksYearState) => {
            return {
                ...state,
                data: _data,
            };
        });
    },

    openedEditTodo: null,
    setOpenedEditTodo: (_openedEditTodo: ITodo|null) => {
        return set((state: BlocksYearState) => {
            return {
                ...state,
                openedEditTodo: _openedEditTodo,
            };
        });
    },

    selectedTag: getSelectedTagFromStorage() || null,
    setSelectedTag: (_selectedTag: ITag|null) => {
        return set((state: BlocksYearState) => {
            return {
                ...state,
                selectedTag: _selectedTag,
            };
        });
    },

}))