import { Fragment } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Probability, round } from './probability';
import { BinningRule } from './binning-rule';

export enum DecisionTreeTableColumnType {
    DEFAULT = 0,
    LABEL = 1,
    INDEX = 2,
}

export const BINNING_THRESHOLD = 70;

export const bgColors: string[] = [
    'rgb(221, 240, 217)',
    '#d9eef0',
    '#d9d9f0',
    '#efd9f0',
    '#f0d9d9',
    '#f0ebd9',

    'rgb(175,246,166)',
    '#9cddea',
    '#a6a6ea',
    '#eba4ec',
    '#efacac',
    '#f3d79e',

    'rgb(127,189,119)',
    '#68a2ac',
    '#7575b6',
    '#b773b8',
    '#ba7c7c',
    '#bca16f',
];

export class DecisionTreeTableColumn {
    header: string;
    type: DecisionTreeTableColumnType;
    id: string;
    binningRules: BinningRule[];

    private values: string[];
    private initialValues: string[];
    private valuesBgColors: string[];

    constructor(type: DecisionTreeTableColumnType) {
        this.id = uuidv4();
        this.type = type;

        this.header = '';

        this.values = [];
        this.initialValues = []
        this.valuesBgColors = [];
        this.binningRules = [];
    }

    // ------------ PUBLIC ------------------

    public addValue(value: string) {
        const foundIndex = this.values.indexOf(value);
        this.values.push(value);
        this.valuesBgColors.push(foundIndex === -1 ? this.getNonUsedBgColor() : this.valuesBgColors[foundIndex]);
    }

    public setValueByIndex(index: number, value: string, initialValue?: string) {
        this.values[index] = value;
        const foundIndex = this.values.findIndex((item, i) => (item.toString() === value.toString()) && i !== index);
        this.valuesBgColors[index] = foundIndex === -1 ? this.getNonUsedBgColor() : this.valuesBgColors[foundIndex];

        if(this.initialValues[index] !== undefined) {
            this.initialValues[index] += `, ${ initialValue }`;
        }
        else{
            this.initialValues[index] = initialValue;
        }

    }

    public addValues(values: string[]) {
        this.values.push(...values);
    }

    public getValues() {
        return this.values;
    }

    public getValuesBgColors() {
        return this.valuesBgColors;
    }

    public getValuesCount() {
        return this.values.length;
    }

    public getValueByIndex(index: number) {
        return this.values[index];
    }

    public getInitialValueByIndex(index: number) {
        return this.initialValues[index];
    }

    public areAllValuesDifferent(): boolean {
        return (new Set(this.values)).size === this.values.length;
    }

    public deleteValuesByIndex(index: number) {
        this.values.splice(index, 1);
    }

    public getUniqueValues(): string[] {
        return [...new Set(this.values)];
    }

    public clone(): DecisionTreeTableColumn {
        const cloned = new DecisionTreeTableColumn(this.type);
        cloned.id = uuidv4();
        cloned.header = this.header;
        cloned.values = [...this.values];
        return cloned;
    }

    public draw() {
        return (
            <Fragment key={ this.id }>
                <div
                    className={ `${ this.getCellClassesByType() } py-2 px-4 border border-slate-200 text-center` }>{ this.header }</div>

                {
                    this.values.map((value, index) => {
                        return (
                            <div
                                key={ `value-${ value }` }
                                className={ `bg-white py-2 px-4 border border-slate-200 text-center` }
                                style={ {
                                    backgroundColor: this.type !== DecisionTreeTableColumnType.INDEX ? this.valuesBgColors[index] : '#fff',
                                } }>
                                <span>{ value }</span>

                                {
                                    this.initialValues[index] !== undefined &&
                                    <span className="mx-1">
                                        ({ this.initialValues[index] })
                                    </span>
                                }
                            </div>
                        )
                    })
                }

                <div className="text-sm">
                    { this.binningRules.map(rule => rule.draw()) }
                </div>
            </Fragment>
        )
    }

    /**
     * value_text ---> probability instance
     */
    public getProbabilities(): Map<string, Probability> {
        const map: Map<string, Probability> = new Map();

        for(let i=0; i<this.values.length; i++) {
            const value = this.values[i];
            const probability = map.get(value) || new Probability(i);

            probability.totalCount = this.getValuesCount();
            probability.value = value;
            probability.valueCount++;

            map.set(value, probability);
        }

        return map;
    }

    public drawProbabilities(label: DecisionTreeTableColumn) {
        const map = [...this.getProbabilities()];

        return (
            <div dir="ltr">
                {
                    map.map(([value, probability]) => {

                        const labelProbabilities = [...this.getValueLabelProbabilities(value, probability.valueCount, label)];

                        return (
                            <div key={ `prob-${ probability.id }` } className="flex flex-col" style={{
                                paddingBottom: '10px',
                            }}>
                                <div className="font-bold underline">{ value }:</div>
                                <div>{ probability.drawProbability(false) } = { probability.drawProbability(true) }</div>

                                <div className="flex flex-col mb-2">
                                    {
                                        labelProbabilities.map(([labelValue, labelProbability]) => {
                                            return (
                                                <div key={ `label-prob-${ labelProbability.id }` } className="flex">
                                                    <b>{ labelValue }:</b>
                                                    { labelProbability.drawProbabilityLogCalculation(false) } =
                                                    { labelProbability.drawProbabilityLogCalculation(true) } =
                                                    { labelProbability.getProbabilityLogCalculation().toFixed(2) }
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )

    }

    public getValueLabelProbabilities(value: string, valueCount: number, label: DecisionTreeTableColumn) {
        if(!this.values.includes(value)) {
            throw new Error('The value is not included in this column.')
        }

        const labelValues = label.getValues();
        const labelsMap: Map<string, Probability> = new Map();

        for(let i=0; i<this.values.length; i++) {
            if(this.values[i] !== value) continue;
            const labelValue = labelValues[i];

            const labelProbability = labelsMap.get(labelValue) || new Probability(i);

            labelProbability.value = labelValue;
            labelProbability.valueCount++;
            labelProbability.totalCount = valueCount;

            labelsMap.set(labelValue, labelProbability);
        }

        return labelsMap;
    }

    public getEntropy(label: DecisionTreeTableColumn) {
        const map = this.getProbabilities();
        let result = 0;

        for(const [value, probability] of map.entries()) {
            const prob = probability.getProbability();
            const labelProbabilities = this.getValueLabelProbabilities(value, probability.valueCount, label);

            let sum = 0;

            for(const labelProbability of labelProbabilities.values()) {
                sum += labelProbability.getProbabilityLogCalculation();
            }

            result += prob * sum;
        }

        return round(result);
    }

    public drawEntropyCalculations(label: DecisionTreeTableColumn) {
        const map = [...this.getProbabilities()];

        return (
            <span className="flex">
                {
                    map.map(([value, probability], i) => {

                        const labelProbabilities = [...this.getValueLabelProbabilities(value, probability.valueCount, label).values()];

                        return (
                            <span key={ `entropy-${ probability.id }` } className="flex">
                                { probability.drawProbability(true) } * (
                                {
                                    labelProbabilities.map((labelProbability, j) => {
                                        return (
                                            <span key={ `label-prob-${ labelProbability.id }` } className="flex">
                                                {/*{ labelProbability.drawProbabilityLogCalculation(true) }*/}
                                                { labelProbability.getProbabilityLogCalculation() }
                                                { j === labelProbabilities.length - 1 ? '' : ' + ' }
                                            </span>
                                        )
                                    })
                                })

                                { i === map.length - 1 ? '' : ' + ' }
                            </span>
                        )
                    })
                }
            </span>
        )
    }

    public areAllValuesNumbers() {
        for(const value of this.values) {
            if(value.trim() === '' || isNaN(Number(value.trim()))) return false;
        }
        return true;
    }

    public checkMissingOrWrongTextValues() {
        const wrongValues: {
            index: number;
            wrongValue: string;
        }[] = [];

        for(let i=0; i<this.values.length; i++) {
            const value = this.values[i];
            if(value.trim() === '' || value.includes('?')) {
                wrongValues.push({
                    index: i,
                    wrongValue: value,
                });
            }
        }

        return wrongValues;
    }

    public getMostFrequentValue(): string {
        const frequency = new Map<string, number>();

        for (const value of this.values) {
            const trimmed = value.trim();
            if (trimmed === '' || trimmed.includes('?')) continue;

            frequency.set(trimmed, (frequency.get(trimmed) || 0) + 1);
        }

        let maxCount = -1;
        let mostFrequent = '';

        for (const [value, count] of frequency.entries()) {
            if (count > maxCount) {
                maxCount = count;
                mostFrequent = value;
            }
        }

        return mostFrequent;
    }

    /**
     * If all values except one are numbers,
     * and only one is not --->
     * then it's a wrong/missing values.
     */
    public checkMissingOrWrongNumericValue(): {
       hasWrongOrMissingValue: boolean;
       index: number;
       wrongValue: string;
       average: number;
       median: number;
    } {

        let wrongValue = '';
        let numbersCount = 0;
        let nonNumberIndex = -1;
        let average = 0;
        let median = 0;
        let allNumbersAreIntegers = true;

        if(this.values.length > 1) {

            const numbers: number[] = [];

            // Count numeric values ------------------
            for(let i=0; i<this.values.length; i++) {
                const value = this.values[i].trim();
                const number = Number(value);

                if(value !== '' &&
                    !isNaN(number)) {
                    numbersCount++;
                    average += number;
                    numbers.push(number);

                    if(!Number.isInteger(number)) {
                        allNumbersAreIntegers = false;
                    }
                }
                else {
                    nonNumberIndex = i;
                    wrongValue = value;
                }
            }

            average = numbersCount === 0 ? 0 : average/numbersCount;
            numbers.sort((a, b) => a - b);

            if(numbers.length > 0) {
                const mid = Math.floor(numbers.length / 2);
                if(numbers.length % 2 === 0) {
                    median = (numbers[mid - 1] + numbers[mid]) / 2;
                }
                else{
                    median = numbers[mid];
                }
            }

            if(allNumbersAreIntegers) {
                average = Math.round(average);
                median = Math.round(median);
            }

            const hasWrongOrMissingValue = numbersCount === this.values.length - 1;
            if(hasWrongOrMissingValue) {

                return {
                    hasWrongOrMissingValue: true,
                    index: nonNumberIndex,
                    wrongValue,
                    average,
                    median,
                }
            }
        }

        return {
            hasWrongOrMissingValue: false,
            index: -1,
            wrongValue: '',
            average,
            median,
        };
    }

    /**
     * Discretization = binning
     */
    public shouldApplyDiscretization() {
        const uniqueValues = new Set(this.values).size;
        const totalValues = this.values.length;
        const percentUnique = (uniqueValues / totalValues) * 100;
        return percentUnique >= BINNING_THRESHOLD;
    }

    public getDiscretizationGroupsCount() {
        // return Math.floor(Math.sqrt(this.values.length));
        return Math.min(5, Math.floor(Math.sqrt(this.values.length)));
    }

    public getBinningRules() {
        const groupsCount = this.getDiscretizationGroupsCount();

        let min = Infinity;
        let max = -Infinity;

        for(const value of this.values) {
            const num = Number(value);
            min = Math.min(min, num);
            max = Math.max(max, num);
        }

        const step = Math.floor((max - min) / groupsCount);

        const binningRules: BinningRule[] = [];
        let prev = min;
        for(let i=0; i<groupsCount; i++) {
            const binningRule = new BinningRule(prev, prev + step, `${ prev }-${ prev + step }`);
            binningRules.push(binningRule);
            prev = prev + step + 1;
        }

        return binningRules;
    }

    public findBinningRule(num: number): BinningRule|null {
        for(const rule of this.binningRules) {
            if(num >= rule.min && num <= rule.max) return rule;
        }

        return null;
    }

    public applyDiscretization() {
        this.binningRules = this.getBinningRules();
        if(this.binningRules.length <= 1) return;

        const sorted = [...this.values].sort((a, b) => Number(a) - Number(b));

        for(let i=0; i<sorted.length; i++){
            const value = sorted[i];
            const num = Number(value);
            const rule = this.findBinningRule(num);
            if(!rule) continue;

            this.setValueByIndex(i, rule.name, value);
        }
    }

    // ------------ PRIVATE ------------------

    private getNonUsedBgColor(): string {
        const usedColors = new Set(this.valuesBgColors);
        for(let i=0; i<bgColors.length; i++) {
            const bgColor = bgColors[i];
            if(!usedColors.has(bgColor)) return bgColor;
        }
        return '';
    }

    private getCellClassesByType(): string {
        if(this.type === DecisionTreeTableColumnType.LABEL) return 'bg-yellow-100';
        if(this.type === DecisionTreeTableColumnType.INDEX) return 'bg-sky-100';
        return 'bg-slate-100';
    }
}