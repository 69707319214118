import { IERDData } from './interfaces';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DrawIoEmbed, DrawIoEmbedRef, EventExport } from 'react-drawio';
import { createDfdDiagramXml } from './domain/erd-diagram-provider';
import { copyImageToClipboard, downloadImage } from '../../../../../common/domain/io-provider';
import TableModel from './table-model';
import { getErdDiagramFromStorage, setErdDiagramToStorage } from './domain/erd-diagram-storage';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IERDData = {
        "entities": [
            {
                "id": "E1",
                "name": "Student",
                "properties": [
                    { "name": "StudentID", "isPrimaryKey": true },
                    { "name": "Name" },
                    { "name": "Email" }
                ]
            },
            {
                "id": "E2",
                "name": "Course",
                "properties": [
                    { "name": "CourseID", "isPrimaryKey": true },
                    { "name": "Title" },
                    { "name": "Credits" }
                ]
            },
            {
                "id": "E3",
                "name": "Professor",
                "properties": [
                    { "name": "ProfessorID", "isPrimaryKey": true },
                    { "name": "Name" },
                    { "name": "DepartmentID" }
                ]
            },
            {
                "id": "E4",
                "name": "Department",
                "properties": [
                    { "name": "DepartmentID", "isPrimaryKey": true },
                    { "name": "Name" },
                    { "name": "UniversityID" }
                ]
            },
            {
                "id": "E5",
                "name": "University",
                "properties": [
                    { "name": "UniversityID", "isPrimaryKey": true },
                    { "name": "Name" },
                    { "name": "Location" }
                ]
            },
            {
                "id": "E6",
                "name": "StudentAddress",
                "properties": [
                    { "name": "AddressID", "isPrimaryKey": true },
                    { "name": "StudentID" },
                    { "name": "Street" },
                    { "name": "City" },
                    { "name": "ZipCode" }
                ]
            }
        ],
        "relationships": [
            {
                "id": "R1",
                "name": "Enrolled In",
                "entities": [
                    { "entityId": "E1", "cardinality": "zero_or_many" },
                    { "entityId": "E2", "cardinality": "one_or_many" }
                ]
            },
            {
                "id": "R2",
                "name": "Taught By",
                "entities": [
                    { "entityId": "E2", "cardinality": "many" },
                    { "entityId": "E3", "cardinality": "one" }
                ]
            },
            {
                "id": "R3",
                "name": "Assigned To",
                "entities": [
                    { "entityId": "E2", "cardinality": "many" },
                    { "entityId": "E4", "cardinality": "one" }
                ]
            },
            {
                "id": "R4",
                "name": "Belongs To",
                "entities": [
                    { "entityId": "E4", "cardinality": "many" },
                    { "entityId": "E5", "cardinality": "one_or_one" }
                ]
            },
            {
                "id": "R5",
                "name": "Has Address",
                "entities": [
                    { "entityId": "E1", "cardinality": "one_or_many" },
                    { "entityId": "E6", "cardinality": "one" }
                ]
            }
        ]
    }
;

const ErdDiagramHome = () => {

    const drawIoRef = useRef<DrawIoEmbedRef>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IERDData>(initialData);

    useEffect(() => {
        if(!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onDiagramSave = async (data: EventExport) => {
        downloadImage(data.data, 'erd-diagram.png');
        await copyImageToClipboard(data.data);
    };

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    useEffect(() => {
        const storageData = getErdDiagramFromStorage();
        if(!storageData) return;

        if(textAreaRef.current) {
            textAreaRef.current.value = storageData;
        }

        let parsed: IERDData|null = null;

        try{
            parsed = JSON.parse(storageData) as IERDData;
        }
        catch (error) {
            console.log(error);
        }

        if(!parsed) return;

        setData(parsed);
    }, []);

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setErdDiagramToStorage(evt.target.value);
    };

    return(
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">דיאגרמת ישיויות קשרים (ERD)</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    className="border rounded w-full p-2"
                    onChange={ onChange }
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={{
                         height: UI_HEIGHT,
                     }}>
                    <DrawIoEmbed
                        ref={ drawIoRef }
                        urlParameters={ {
                            // ui: 'kennedy',

                            // Shows a 'Loading…' spinner while waiting
                            // for the diagram data to load in embed mode.
                            spin: true,

                            // Specifies whether libraries should be enabled in embed mode.
                            // The default is disabled.
                            // libraries: true,


                            noExitBtn: true,
                        } }
                        exportFormat={ 'png' }
                        onExport={ onDiagramSave }
                        xml={ createDfdDiagramXml(data) }
                    />
                </div>
            </div>

            <TableModel data={ data } />
        </div>
    )
};

export default ErdDiagramHome;
