import { ChangeEvent, useState } from 'react';
import { AttachmentIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { IPlant } from '../../../flowers-interfaces';
import { uploadPlantAttachment } from '../../../data/flowers-data-service';

interface IPlantUploadAttachmentButtonProps {
    plant: IPlant;
    onUpdate: () => void;
}

const PlantUploadAttachmentButton = ({ plant, onUpdate }: IPlantUploadAttachmentButtonProps) => {

    const [isLoading, setLoading] = useState(false);

    const uploadFileHandler = async (evt: ChangeEvent<HTMLInputElement>) => {

        if(!evt.target.files || evt.target.files.length <= 0){
            showToast('Upload plant attachment error.');
            return;
        }

        setLoading(true);

        for(const file of evt.target.files) {

            const formData = new FormData();
            formData.set('file', file);
            formData.set('plant_id', plant.plant_id.toString());

            const response = await uploadPlantAttachment(formData as FormData);

            if(!response || !response.isValid) {
                showToast('Upload plant attachment error.');
                setLoading(false);
                return;
            }
        }

        setLoading(false);
        showToast('Upload OK.', false);

        onUpdate();
        sendCloseActionsEvent();
    };

    return (
        <label
            title="Upload Plant Attachment"
            className="plant__attachment-btn flex bg-sky-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn">

            {
                !isLoading &&
                <AttachmentIcon size={ 20 } color="#204A6E" />
            }

            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#204A6E' } />
            }

            <input
                onChange={ uploadFileHandler }
                className="hidden"
                type="file"
                multiple={ true }
            />
        </label>
    )
};

export default PlantUploadAttachmentButton;