import PlantAttachment from './attachment';
import { IPlantAttachment } from '../../flowers-interfaces';

interface IAttachmentsList {
    attachments: IPlantAttachment[];
    onUpdate: () => void;
}

const AttachmentsList = ({ attachments, onUpdate }: IAttachmentsList) => {
    return (
        <div className={ `flex flex-col justify-center rounded-t md:rounded bg-slate-700 text-slate-100 pt-4 px-4` }>
            {
                attachments.map(attachment => {
                    return (
                        <PlantAttachment
                            key={ attachment.id }
                            attachment={ attachment }
                            onUpdate={ onUpdate }
                        />
                    )
                })
            }
        </div>
    )
};

export default AttachmentsList;