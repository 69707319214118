import { ArrowLeftIcon } from '../../../../../common/ui/icons';
import { useFlowersStore } from '../../../data/flowers-store';
import { addDays, startOfDay } from 'date-fns';

const PrevDayBtn = () => {

    const waterLogsDate = useFlowersStore(store => store.waterLogsDate);
    const setWaterLogsDate = useFlowersStore(store => store.setWaterLogsDate);

    const jumpToPrevDay = () => {
        const currentDate = new Date(waterLogsDate);
        const nextDay = startOfDay(addDays(currentDate, -1));
        setWaterLogsDate(nextDay.getTime());
    };

    return (
        <button
            title="Prev Day"
            onClick={ jumpToPrevDay }
            type="button">
            <ArrowLeftIcon />
        </button>
    )
};

export default PrevDayBtn;