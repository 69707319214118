import {IScreenDesign } from './interfaces';
import { useEffect, useRef, useState } from 'react';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IScreenDesign = {
        "header": {
            "systemName": "מערכת לניהול כרטיסים",
            "organizationName": "תיאטרון חובק עולם",
            "screenNumber": "1.2.3",
            "screenTitle": "קליטת הזמנה מתיאטרון",
            "dateTime": "01.01.2024 11:00",
            "userName": "דוד כהן"
        },
        "parentInfo": [
            { "title": "מספר הזמנה", "value": "101", "controlType": "textbox" },
            { "title": "סטטוס הזמנה", "value": "בפתיחה", "controlType": "dropdown" },
            { "title": "תאריך פתיחה", "value": "16/01/2024", "controlType": "textbox" },
            { "title": "תיאטרון", "value": "תיאטרון", "controlType": "dropdown" }
        ],
        "parentItemTitle": "הצגות",
        "table": {
            "headers": ["מספר הצגה", "שם הצגה", "תאריך", "מחיר כרטיס", "כמות", "מחיר כולל"],
            "rows": [
                ["1", "בוריס", "16/02/2024", "$70", "1", "$70"],
                ["4", "מערב", "16/03/2024", "$50", "2", "$100"],
                ["6", "ארץ", "16/04/2024", "$100", "1", "$100"]
            ]
        },
        "footer": {
            "totalAmount": 270,
            "functionKeys": [
                { "key": "ESC", "description": "יציאה" },
                { "key": "F5", "description": "הדפס הזמנה" },
                { "key": "F7", "description": "קליטת תשלום הזמנה" },
                { "key": "F7", "description": "אשר הזמנה" },
                { "key": "F8", "description": "שמירה" }
            ]
        }
    }
;

const ScreenHome = () => {

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IScreenDesign>(initialData);

    useEffect(() => {
        if (!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    return (
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">מסך</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    className="border rounded w-full p-2"
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={ {
                         height: UI_HEIGHT,
                     } }>
                    {
                        data &&
                        <div dir="rtl" className="flex flex-col items-start ml-auto" style={ {
                            width: '900px',
                        } }>

                            <header className="flex border bg-slate-100 px-12 py-3 gap-4 mb-6 w-full">
                                <div>
                                    <div>{ data.header.organizationName }</div>
                                    <div>{ data.header.systemName }</div>
                                </div>

                                <div className="mx-12 px-12 text-center">
                                    <div className="font-bold">
                                        <span>מסך</span>
                                        <span> { data.header.screenNumber } </span>
                                    </div>
                                    <div className="text-xl">{ data.header.screenTitle }</div>
                                </div>

                                <div>
                                    <div>
                                        <strong>תאריך:</strong>
                                        <span> { data.header.dateTime } </span>
                                    </div>
                                    <div>
                                        <strong>משתמש:</strong>
                                        <span> { data.header.userName } </span>
                                    </div>
                                </div>
                            </header>

                            <section className="grid grid-cols-2 gap-y-4 gap-x-10 mb-6">
                                {
                                    data.parentInfo.map((item, i) => {
                                        return (
                                            <div key={ `parent-control-${ i }` }>
                                                {
                                                    item.controlType === 'textbox' &&
                                                    <label className="flex items-center gap-4">
                                                        <span className="mr-4 block">{ item.title }</span>

                                                        <input
                                                            type="text"
                                                            value={ item.value }
                                                            className="border rounded px-2 max-w-full"
                                                            style={ {
                                                                width: '100px'
                                                            } }
                                                        />
                                                    </label>
                                                }

                                                {
                                                    item.controlType === 'dropdown' &&
                                                    <label className="flex items-center gap-4">
                                                        <span className="mr-4 block">{ item.title }</span>

                                                        <select
                                                            value={ item.value }
                                                            className="border rounded px-2 max-w-full"
                                                            style={ {
                                                                width: '150px'
                                                            } }>
                                                            <option value={ item.value }>{ item.value }</option>
                                                        </select>
                                                    </label>
                                                }
                                            </div>
                                        )
                                    })
                                }
                            </section>

                            <section className="mb-6">
                                <div className="font-bold mb-2">{ data.parentItemTitle }</div>

                                <table className="mb-6 w-full bg-white">
                                <thead>
                                    <tr>
                                        {
                                            data.table.headers.map((header, i) => {
                                                return (
                                                    <td key={ `header-${ i }` }
                                                        className="bg-slate-700 text-slate-100 px-6 py-2 border border-slate-500">{ header }</td>
                                                )
                                            })
                                        }
                                    </tr>
                                    </thead>

                                    <tbody>
                                    {
                                        data.table.rows.map((row, ri) => {
                                            return (
                                                <tr key={ `row-${ ri }` }>
                                                    {
                                                        row.map((cell, i) => {
                                                            return (
                                                                <td key={ `cell-${ i }` }
                                                                    className="px-6 py-2 border border-slate-500">
                                                                    <input
                                                                        type="text"
                                                                        value={ cell }
                                                                        className="border rounded px-2 max-w-full"
                                                                        style={ {
                                                                            width: '100px'
                                                                        } }
                                                                    />
                                                                </td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }

                                    <tr>
                                        <td className="font-bold px-6 py-2 border border-slate-500"
                                            colSpan={ data.table.headers.length - 1 }>סה״כ
                                        </td>
                                        <td className="px-6 py-2 border border-slate-500 bg-blue-50">
                                            <input
                                                type="text"
                                                value={ `$${ data.footer.totalAmount }` }
                                                className="border rounded px-2 max-w-full bg-blue-50 border-blue-300"
                                                style={ {
                                                    width: '100px'
                                                } }
                                            />
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </section>

                            <footer className="flex border bg-slate-100 px-6 py-3 gap-4 w-full text-sm">
                                {
                                    data.footer.functionKeys.map((functionKey, i) => {
                                        return (
                                            <button type="button" key={ `func-btn-${ i }` }
                                                    className="rounded bg-slate-700 text-slate-100 px-6 py-1">
                                            <span> { functionKey.key } </span>
                                                <span> { functionKey.description } </span>
                                            </button>
                                        )
                                    })
                                }
                            </footer>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
};

/*
  "footer": {
            "totalAmount": 270,
            "functionKeys": [
                { "key": "ESC", "description": "יציאה" },
                { "key": "F5", "description": "הדפס הזמנה" },
                { "key": "F7", "description": "קליטת תשלום הזמנה" },
                { "key": "F7", "description": "אשר הזמנה" },
                { "key": "F8", "description": "שמירה" }
            ]
        }
 */

export default ScreenHome;
