import { getJsonFromLocalStorage, saveJsonToLocalStorage } from '../../../common/data/storage-provider.js';
import { ITag } from '../../planner/planner-interfaces';

const BLOCKS_SELECTED_TAG_KEY = 'BLOCKS_SELECTED_TAG_KEY';

export const saveSelectedTagToStorage = (tag: ITag) => {
    saveJsonToLocalStorage(BLOCKS_SELECTED_TAG_KEY, tag);
};

export const getSelectedTagFromStorage = () => {
    return getJsonFromLocalStorage(BLOCKS_SELECTED_TAG_KEY) as ITag;
};