import { ArrowRightIcon } from '../../../../../common/ui/icons';
import { useFlowersStore } from '../../../data/flowers-store';
import { addDays, startOfDay } from 'date-fns';

const NextDayBtn = () => {

    const waterLogsDate = useFlowersStore(store => store.waterLogsDate);
    const setWaterLogsDate = useFlowersStore(store => store.setWaterLogsDate);

    const jumpToNextDay = () => {
        const currentDate = new Date(waterLogsDate);
        const nextDay = startOfDay(addDays(currentDate, 1));
        setWaterLogsDate(nextDay.getTime());
    };

    return (
        <button
            title="Next Day"
            onClick={ jumpToNextDay }
            type="button">
            <ArrowRightIcon />
        </button>
    )
};

export default NextDayBtn;