import { useEffect, useState } from 'react';
import { getFoodDropDownData } from '../../data/food-data-service';
import { IFoodCategory } from '../../food-interfaces';
import { showToast } from '../../../../common/domain/toast-provider';
import ListBoxWithSearch from '../../../../common/ui/list-box-with-search/list-box-with-search';
import { IListBoxWithSearchGroup } from '../../../../common/ui/list-box-with-search/interfaces';

interface IFoodDropDown {
    foodId: number|string;
    setFoodId: (_foodId: number|string) => void;
    maxHeight?: string;
}

const getListBoxWithSearchData = (foodCategories: IFoodCategory[]) : IListBoxWithSearchGroup[] => {
    const groups: IListBoxWithSearchGroup[] = [];

    for(const category of foodCategories) {

        const group: IListBoxWithSearchGroup = {
            id: category.id,
            title: category.name,
            items: category?.foods?.map(food => {
               return {
                    id: food.id,
                   text: food.name,
               };
            }) || [],
        };

        groups.push(group);
    }

    return groups;
};

const FoodDropDown = ({ foodId, setFoodId, maxHeight }: IFoodDropDown) => {

    const [groups, setGroups] = useState<IListBoxWithSearchGroup[]>([]);

    useEffect(() => {
        (async () => {
            const response = await getFoodDropDownData();

            if(!response) {
                showToast('Get drop down data error.');
                return;
            }

            setGroups(getListBoxWithSearchData(response || []));
        })();
    }, []);

    const onSelect = (_groupId: number|string, itemId: number|string) => {
        setFoodId(itemId);
    };

    return (
        <>
            <ListBoxWithSearch
                groups={ groups }
                onSelect={ onSelect }
                selectedItemId={ foodId }
                placeholder={ 'Select Food' }
                maxHeight={ maxHeight }
            />
        </>
    )
};

export default FoodDropDown;