import { ArrowDownIcon } from '../../../../../common/ui/icons';
import { FoodItemsSort, FoodItemsSortDirection } from '../../../food-interfaces';
import { useFoodStore } from '../../../data/food-store';

interface ISortButtons {
    sortType: FoodItemsSort;
}

const ACTIVE_COLOR = '#c900ff';

const SortButtons = ({ sortType }: ISortButtons) => {

    const foodItemsSort = useFoodStore(store => store.foodItemsSort);
    const foodItemsSortDirection = useFoodStore(store => store.foodItemsSortDirection);

    const setFoodItemsSort = useFoodStore(store => store.setFoodItemsSort);
    const setFoodItemsSortDirection = useFoodStore(store => store.setFoodItemsSortDirection);

    const isActive = foodItemsSort === sortType;
    const isAsc = foodItemsSortDirection === FoodItemsSortDirection.ASC;

    const onUpClick = () => {
        setFoodItemsSort(sortType);
        setFoodItemsSortDirection(FoodItemsSortDirection.DESC);
    };

    const onDownClick = () => {
        setFoodItemsSort(sortType);
        setFoodItemsSortDirection(FoodItemsSortDirection.ASC);
    };

    return (
        <div>
            <button
                onClick={ onUpClick }
                type="button">
                <ArrowDownIcon
                    strokeWidth={ isActive && !isAsc ? 2 : 1 }
                    color={ isActive && !isAsc ? ACTIVE_COLOR : '#000' }
                    style={{
                        transform: 'rotate(180deg)',
                    }}
                />
            </button>

            <button
                onClick={ onDownClick }
                type="button">
                <ArrowDownIcon
                    strokeWidth={ isActive && isAsc ? 2 : 1 }
                    color={ isActive && isAsc ? ACTIVE_COLOR : '#000' }
                />
            </button>
        </div>
    )
};

export default SortButtons;