import { NavLink } from 'react-router-dom';
import { closeMobileMenu } from '../../../menu/ui/mobile-menu-provider';

const FlowersMenu = () => {

    return (
        <div className="side-menu flex flex-col xl:w-[250px] bg-slate-800 text-slate-100 leading-7 h-screen color-scheme-dark">
            <div className="overflow-auto p-3">

                <div className="text-sm pb-3 pt-3 text-slate-400">Flowers</div>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/flowers` }
                    end={ true }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(84, 168, 209)',
                        }}
                    />
                    <p>Water Logs 💦</p>
                </NavLink>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/flowers/plants` }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(102, 97, 255)',
                        }}
                    />
                    <p>Plants 🪴</p>
                </NavLink>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/flowers/types` }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(84,209,186)',
                        }}
                    />
                    <p>Plant Types</p>
                </NavLink>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/flowers/locations` }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(205, 209, 84)',
                        }}
                    />
                    <p>Locations 🪟</p>
                </NavLink>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/flowers/gallery` }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(255,0,0)',
                        }}
                    />
                    <p>Gallery 🌺</p>
                </NavLink>

            </div>
        </div>
    )
};

export default FlowersMenu;