import Popup from '../../../../../common/ui/popup';
import { ChangeEvent, KeyboardEvent as ReactKeyboardEvent, useCallback, useEffect, useRef, useState } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { useFoodStore } from '../../../data/food-store';
import { endOfDay, format, startOfDay } from 'date-fns';
import { updateFoodLog } from '../../../data/food-data-service';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import FoodDropDown from '../../foods/food-drop-down';
import { getCompactMarkdownEditorPlugins } from '../../../../../common/markdown/markdown-editor-provider';

const EditFoodLogPopup = () => {

    const editorRef = useRef<MDXEditorMethods>(null);

    const foodLogEditPopup = useFoodStore(store => store.foodLogEditPopup);
    const setFoodLogEditPopup = useFoodStore(store => store.setFoodLogEditPopup);

    const foodLogDate = useFoodStore(store => store.foodLogDate);
    const setFoodLogData = useFoodStore(store => store.setFoodLogData);

    const [isLoading, setLoading] = useState(false);

    const [date, setDate] = useState<number|null>(Date.now());
    const [dateTouched, setDateTouched] = useState(false);
    const isDateValid = !dateTouched || date > 0;

    const [foodId, setFoodId] = useState<string|number>(0);
    const [foodIdTouched, setFoodIdTouched] = useState(false);
    const isFoodIdValid = !foodIdTouched || Number(foodId) > 0;

    const [quantity, setQuantity] = useState('0');
    const [quantityTouched, setQuantityTouched] = useState(false);
    const isQuantityValid = !quantityTouched || Number(quantity) > 0;

    useEffect(() => {
        if(!foodLogEditPopup) return;

        setDate(foodLogEditPopup.date);
        setDateTouched(false);

        setFoodId(foodLogEditPopup.food_id);
        setFoodIdTouched(false);

        setQuantity(foodLogEditPopup.quantity.toString());
        setQuantityTouched(false);

        editorRef.current?.setMarkdown(foodLogEditPopup.description || '');

    }, [foodLogEditPopup]);

    const close = useCallback(() => {
        setFoodLogEditPopup(null);
    }, [setFoodLogEditPopup]);

    const save = useCallback(async () => {

        if(Number(foodId) <= 0) {
            setFoodIdTouched(true);
            return;
        }

        if(date <= 0) {
            setDateTouched(true);
            return;
        }

        if(Number(quantity.trim()) <= 0) {
            setQuantityTouched(true);
            return;
        }

        setLoading(true);

        const desc = editorRef.current?.getMarkdown() || '';
        const fromDate = startOfDay(foodLogDate).getTime();
        const toDate = endOfDay(foodLogDate).getTime();

        const response = await updateFoodLog(
            fromDate,
            toDate,

            foodLogEditPopup.id,
            Number(foodId),
            date,
            Number(quantity.trim()),
            desc.trim(),
        );

        setLoading(false);

        if(!response) {
            showToast('Edit food log error.');
            return;
        }

        close();
        setFoodLogData(response);
    }, [close, date, foodId, foodLogDate, foodLogEditPopup?.id, quantity, setFoodLogData]);

    const onDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if(!evt.target.value) {
            setDate(null);
            return;
        }

        const _date = new Date(evt.target.value);
        setDate(_date.getTime());
    };

    const onQuantityChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setQuantity(evt.target.value);
        setQuantityTouched(true);
    };

    const onKeyDown = async (evt: ReactKeyboardEvent<HTMLInputElement>) => {

        if(evt.code === 'Escape') {
            close();
            return;
        }

        if(evt.code === 'Enter') {
            await save();
            return;
        }
    };

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'Escape') {
                evt.stopPropagation();
                close();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save, close]);

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                const $box = (evt.target as HTMLElement).closest('[data-type="edit-food-log"]');
                if(!$box) return;

                evt.preventDefault();
                await save();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    return (
        <Popup onClose={ close } width={ 700 } height={ 640 }>
            <div className="p-4 flex flex-col overflow-auto text-left font-normal text-gray-900" data-type="edit-food-log">

                <label className="flex flex-col mb-4">
                    <div className="font-bold text-slate-400 mb-2">Food Type</div>
                    <FoodDropDown
                        foodId={ foodId }
                        setFoodId={ setFoodId }
                        maxHeight={ '200px' }
                    />

                    {
                        !isFoodIdValid && <div className="text-red-700 text-xs mt-1">The food type is required.</div>
                    }
                </label>

                <div className="grid md:grid-cols-2 gap-x-4 gap-y-2 mb-4">

                    <div>
                        <label
                            className="font-bold text-slate-400 block mb-2"
                            htmlFor="food-log-date">Date</label>

                        <div>
                            <input
                                id="food-log-date"
                                type="datetime-local"
                                className="w-full text-sm md:text-base border rounded text-slate-800 outline-stone-200 px-2 md:px-4 py-2"
                                onKeyDown={ onKeyDown }
                                onChange={ onDateChange }
                                value={ date ? format(date, `yyyy-MM-dd'T'HH:mm`) : '' }
                            />

                            {
                                !isDateValid && <div className="text-red-700 text-xs mt-1">The date is required.</div>
                            }
                        </div>
                    </div>

                    <div>
                        <label
                            className="font-bold text-slate-400 block mb-2"
                            htmlFor="food-log-quantity">Quantity</label>

                        <div className="flex flex-col">
                            <input
                                id="food-log-quantity"
                                value={ quantity }
                                onInput={ onQuantityChange }
                                onKeyDown={ onKeyDown }
                                className={ `text-center md:text-left text-sm md:text-base border rounded px-2 md:px-4 py-2 ${ isQuantityValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                                type="number"
                            />

                            {
                                !isQuantityValid &&
                                <div className="text-red-700 text-xs mt-1">The quantity is required.</div>
                            }
                        </div>
                    </div>
                </div>

                <label className="font-bold flex flex-col text-slate-400 mb-2">Description</label>
                <div className="markdown-editor border rounded-lg mb-4">
                    <MDXEditor
                        ref={ editorRef }
                        markdown={ '' }
                        plugins={ getCompactMarkdownEditorPlugins() }
                    />
                </div>

                <div className="flex items-center justify-end text-sm mt-4">
                    <button
                        className="bg-stone-400 text-slate-100 rounded px-4 py-2 mr-2"
                        onClick={ close }
                        type="button">Cancel
                    </button>

                    {
                        isLoading &&
                        <PreloaderIcon size={ 24 } color={ '#717985' } />
                    }

                    {
                        !isLoading &&
                        <button
                            className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                            onClick={ save }
                            type="button">Save</button>
                    }
                </div>

            </div>
        </Popup>
    )
};

export default EditFoodLogPopup;