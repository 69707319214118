import { v4 as uuidv4 } from 'uuid';

export const round = (num: number): number => {
    return Math.round(num * 100) / 100
};

export class Probability {
    id: string;
    value: string;
    valueCount: number;
    totalCount: number;
    index: number;

    constructor(index: number) {
        this.index = index;
        this.id = uuidv4();

        this.totalCount = 0;
        this.valueCount = 0;
        this.value = '';
    }

    public getProbability() {
        if(this.totalCount === 0) return 0;
        return this.valueCount/this.totalCount;
    }

    public getProbabilityLogCalculation() {
        const temp = this.getProbability();
        if(temp === 0) return 0;
        return round(-temp * Math.log2(temp) + 0); // +0 is added to avoid negative zeros in unit tests and drawings
    }

    public drawProbability(isResult: boolean) {
        const probability = this.getProbability();

        if(isResult) {
            return (
                <span className="mx-1">
                    { probability.toFixed(2) }
                </span>
            )
        }
        else{
            return (
                <span className="mr-1">
                    { this.valueCount }/{ this.totalCount }
                </span>
            )
        }
    }

    public drawProbabilityLogCalculation(isResult: boolean) {
        const temp = this.getProbability();
        if(temp === 0){
            return (
                <span className="mx-1">
                    0
                </span>
            );
        }

        return (
            <span className="mx-1">
                -{ this.drawProbability(isResult) } * log<sub>2</sub>({ this.drawProbability(isResult) })
            </span>
        )
    }
}