import {
    IClassDiagram,
    IClassDiagramElement, IClassDiagramRelationshipElement,
    IUMLClassElement,
    IUMLClassMethodElement,
    IUMLClassPropertyElement,
} from '../interfaces';
import { v4 as uuidv4 } from 'uuid';

const CLASS_WIDTH = 180;
const CLASS_TOP_HEIGHT = 30;
const CLASS_MARGIN_X = 150;
const CLASS_MARGIN_Y = 100;
const CLASS_PROPERTY_HEIGHT = 25;
const CLASS_METHOD_HEIGHT = 25;
const CLASS_DIVIDER_HEIGHT = 2;
const CLASSES_PER_ROW = 2;

const prepareData = (data: IClassDiagram) : IClassDiagramElement|null => {
    if(!data) return null;

    const dataClasses = data.classes || [];
    const dataRelationships = data.relationships || [];

    // Classes ---------------------
    const classes: IUMLClassElement[] = [];

    let y = 0;
    let x = 0;
    for(const dataClass of dataClasses) {

        const classElement: IUMLClassElement = {
            id: `class-${ dataClass.id }`,
            text: dataClass.name,
            x,
            y,
            width: CLASS_WIDTH,
            height: CLASS_TOP_HEIGHT,
            properties: [],
            methods: [],
            methodsStartY: 0,
        };

        let propY = CLASS_TOP_HEIGHT;

        for(let i=0; i<dataClass.properties.length; i++) {
            const property = dataClass.properties[i];
            const propertyElement: IUMLClassPropertyElement = {
                id: uuidv4(),
                text: property.name,
                x: 0,
                y: propY,
                width: CLASS_WIDTH,
                height: CLASS_PROPERTY_HEIGHT,
            };
            classElement.properties.push(propertyElement);
            propY += CLASS_PROPERTY_HEIGHT;
        }

        let methodY = propY + CLASS_DIVIDER_HEIGHT;
        classElement.methodsStartY = methodY;

        if(dataClass.methods.length > 0) {
            for(let i=0; i<dataClass.methods.length; i++) {
                const method = dataClass.methods[i];
                const methodElement: IUMLClassMethodElement = {
                    id: uuidv4(),
                    text: method.signature,
                    x: 0,
                    y: methodY,
                    width: CLASS_WIDTH,
                    height: CLASS_METHOD_HEIGHT,
                };
                classElement.methods.push(methodElement);
                methodY += CLASS_METHOD_HEIGHT;
            }
        }
        else{
            const methodElement: IUMLClassMethodElement = {
                id: uuidv4(),
                text: '',
                x: 0,
                y: methodY,
                width: CLASS_WIDTH,
                height: CLASS_METHOD_HEIGHT,
            };
            classElement.methods.push(methodElement);
        }

        classElement.height = CLASS_TOP_HEIGHT +
                              CLASS_PROPERTY_HEIGHT * classElement.properties.length;

        if(dataClass.methods.length > 0) {
            classElement.height += CLASS_DIVIDER_HEIGHT + CLASS_METHOD_HEIGHT * classElement.methods.length
        }

        classes.push(classElement);


        if((classes.length) % CLASSES_PER_ROW === 0) {
            x = 0;
            y += classElement.height + CLASS_MARGIN_Y;
        }
        else{
            x += CLASS_WIDTH + CLASS_MARGIN_X;
        }
    }

    // Relationships -------------

    const relationships: IClassDiagramRelationshipElement[] = [];

    for(const dataRelationship of dataRelationships) {
        const relationship: IClassDiagramRelationshipElement = {
            id: uuidv4(),
            source: `class-${ dataRelationship.from }`,
            target: `class-${ dataRelationship.to }`,
            text: dataRelationship.name + ' ▶', // ◀
            cardinalityFrom: dataRelationship.cardinalityFrom,
            cardinalityTo: dataRelationship.cardinalityTo,
        };

        relationships.push(relationship);
    }

    return {
        id: uuidv4(),
        classes,
        relationships,
    };
};

export const createDfdDiagramXml = (data: IClassDiagram): string => {

    const preparedData = prepareData(data);
    if(!preparedData) return '';

    const initialXml = `
<mxfile host="draw.io">
  <diagram name="דיאגרמת מחלקות">
    <mxGraphModel background="#FFFFFF">
      <root>
        <mxCell id="0"/>
        <mxCell id="1" parent="0"/>
        
        ${ preparedData.classes.map(classElement => renderClass(classElement)).join('') }
        ${ preparedData.relationships.map(relationship => renderRelationship(relationship)).join('') }
        
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>`;

    return initialXml.trim();
};

const renderClass = (classElement: IUMLClassElement) => {
    return `
        <mxCell 
            id="${ classElement.id }" 
            value="${ classElement.text }" 
            style="swimlane;fontStyle=0;align=center;verticalAlign=top;childLayout=stackLayout;horizontal=1;startSize=30;horizontalStack=0;resizeParent=1;resizeParentMax=0;resizeLast=0;collapsible=0;marginBottom=0;html=1;whiteSpace=wrap;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ classElement.x }" 
              y="${ classElement.y }" 
              width="${ classElement.width }" 
              height="${ classElement.height }" 
              as="geometry"
              />
        </mxCell>
        
        ${ classElement.properties.map(propertyElement => {
            return `
                <mxCell 
                    id="${ propertyElement.id }" 
                    value="${ propertyElement.text }" 
                    style="text;html=1;strokeColor=none;fillColor=none;align=right;verticalAlign=middle;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;whiteSpace=wrap;" 
                    vertex="1" 
                    parent="${ classElement.id }">
                  <mxGeometry 
                      y="${ propertyElement.y }" 
                      width="${ propertyElement.width }" 
                      height="${ propertyElement.height }" 
                      as="geometry" 
                  />
                </mxCell>
            `;
        }).join('') }
        
        ${ 
            classElement.methods.length > 0 ? `
            <mxCell 
                 id="${ uuidv4() }"
                 value="" 
                 style="line;strokeWidth=1;fillColor=none;align=left;verticalAlign=middle;spacingTop=-1;spacingLeft=3;spacingRight=3;rotatable=0;labelPosition=right;points=[];portConstraint=eastwest;" 
                 vertex="1" 
                 parent="${ classElement.id }">
              <mxGeometry 
                  y="${ classElement.methodsStartY }" 
                  width="${ classElement.width }" 
                  height="${ CLASS_DIVIDER_HEIGHT }" 
                  as="geometry" 
              />
            </mxCell>
            ` : ''
        }
    
        ${ classElement.methods.map(methodElement => {
            return `
                <mxCell 
                    id="${ methodElement.id }" 
                    value="${ methodElement.text }" 
                    style="text;html=1;strokeColor=none;fillColor=none;align=right;verticalAlign=middle;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;points=[[0,0.5],[1,0.5]];portConstraint=eastwest;whiteSpace=wrap;" 
                    vertex="1" 
                    parent="${ classElement.id }">
                  <mxGeometry 
                      y="${ methodElement.y }" 
                      width="${ methodElement.width }" 
                      height="${ methodElement.height }" 
                      as="geometry" 
                  />
                </mxCell>
            `;
        }).join('') }
    `;
};

const renderRelationship = (relationship: IClassDiagramRelationshipElement) => {
    return `
    <mxCell 
        id="${ relationship.id }" 
        style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=1;exitY=0.5;exitDx=0;exitDy=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;endArrow=none;startFill=0;" 
        edge="1" 
        parent="1" 
        source="${ relationship.source }" 
        target="${ relationship.target }">
      <mxGeometry relative="1" as="geometry" />
    </mxCell>
    
    <mxCell 
        id="${ uuidv4() }" 
        value="${ relationship.cardinalityFrom }" 
        style="edgeLabel;html=1;align=center;verticalAlign=middle;resizable=0;points=[];" 
        vertex="1" 
        connectable="0" 
        parent="${ relationship.id }">
      <mxGeometry x="-0.8533" y="0" relative="1" as="geometry">
        <mxPoint as="offset" />
      </mxGeometry>
    </mxCell>
    
    <mxCell 
        id="${ uuidv4() }" 
        value="${ relationship.text }" 
        style="edgeLabel;html=1;align=center;verticalAlign=middle;resizable=0;points=[];" 
        vertex="1" 
        connectable="0" 
        parent="${ relationship.id }">
      <mxGeometry x="0.0267" y="0" relative="1" as="geometry">
        <mxPoint as="offset" />
      </mxGeometry>
    </mxCell>
    
    <mxCell 
        id="${ uuidv4() }" 
        value="${ relationship.cardinalityTo }" 
        style="edgeLabel;html=1;align=center;verticalAlign=middle;resizable=0;points=[];" 
        vertex="1" 
        connectable="0" 
        parent="${ relationship.id }">
      <mxGeometry x="0.84" y="0" relative="1" as="geometry">
        <mxPoint as="offset" />
      </mxGeometry>
    </mxCell>
    `;
};

/*
<mxGraphModel dx="1411" dy="793" grid="1" gridSize="10" guides="1" tooltips="1" connect="1" arrows="1" fold="1" page="1" pageScale="1" pageWidth="827" pageHeight="1169" math="0" shadow="0">
  <root>
    <mxCell id="0" />
    <mxCell id="1" parent="0" />
    <mxCell id="5mBaRZAxij4e465RL3WF-21" style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=1;exitY=0.5;exitDx=0;exitDy=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;endArrow=none;startFill=0;" edge="1" parent="1" source="5mBaRZAxij4e465RL3WF-19" target="5mBaRZAxij4e465RL3WF-20">
      <mxGeometry relative="1" as="geometry" />
    </mxCell>
    <mxCell id="5mBaRZAxij4e465RL3WF-22" value="bbb" style="edgeLabel;html=1;align=center;verticalAlign=middle;resizable=0;points=[];" vertex="1" connectable="0" parent="5mBaRZAxij4e465RL3WF-21">
      <mxGeometry x="0.0267" y="3" relative="1" as="geometry">
        <mxPoint as="offset" />
      </mxGeometry>
    </mxCell>
    <mxCell id="5mBaRZAxij4e465RL3WF-23" value="aaa" style="edgeLabel;html=1;align=center;verticalAlign=middle;resizable=0;points=[];" vertex="1" connectable="0" parent="5mBaRZAxij4e465RL3WF-21">
      <mxGeometry x="-0.8533" y="3" relative="1" as="geometry">
        <mxPoint as="offset" />
      </mxGeometry>
    </mxCell>
    <mxCell id="5mBaRZAxij4e465RL3WF-24" value="ccc" style="edgeLabel;html=1;align=center;verticalAlign=middle;resizable=0;points=[];" vertex="1" connectable="0" parent="5mBaRZAxij4e465RL3WF-21">
      <mxGeometry x="0.84" y="3" relative="1" as="geometry">
        <mxPoint as="offset" />
      </mxGeometry>
    </mxCell>

  </root>
</mxGraphModel>

 */
