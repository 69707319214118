import Actions from '../../../../../common/ui/actions';
import { Car, IFinanceGasItem } from '../../../finance-interfaces';
import { useState } from 'react';
import { format } from 'date-fns';
import DeleteGas from '../actions/delete-gas';
import { EditIcon } from '../../../../../common/ui/icons';
import UpsertGasItem from '../actions/upsert-gas-item';

interface IGasItemProps {
    gas: IFinanceGasItem;
}

enum GasItemMode {
    Default = 0,
    Edit = 1,
}

const GasItem = ({ gas }: IGasItemProps) => {

    const [mode, setMode] = useState<GasItemMode>(GasItemMode.Default);

    const startEdit = () => {
        setMode(GasItemMode.Edit);
    };

    const stopEdit = () => {
        setMode(GasItemMode.Default);
    };

    return (
        <div className="border-b pb-2 mb-2">
            {
                mode === GasItemMode.Default &&
                (
                    <>
                        <div className="grid grid-cols-6 gap-4">

                            <div>{ gas.date ? format(gas.date, 'dd MMM, yyyy') : '' }</div>
                            <div>{ gas.GasKM } Km</div>
                            <div>{ gas.GasAmount } ₪</div>
                            <div>{ gas.GasBusinessName } station</div>
                            <div>{ gas.GasCarID === Car.Hyundai ? 'Hyundai' : 'Jaecoo' }</div>

                            <Actions classes="finance-business__actions" mr="mr-0" gridSize={ 2 }>

                                <DeleteGas gas={ gas }/>

                                <button
                                    title="Edit Business"
                                    className="finance__edit-business flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                                    onClick={ startEdit }
                                    type="button">
                                    <EditIcon color={ '#6e7781' } size={ 20 }/>
                                </button>
                            </Actions>
                        </div>

                        {
                            gas.GasDescription &&
                            <div className="text-gray-500 text-sm mt-2">{ gas.GasDescription }</div>
                        }
                    </>
                )
            }

            {
                mode === GasItemMode.Edit &&
                <UpsertGasItem
                    isNew={ false }
                    gas={ gas }
                    close={ stopEdit }
                />
            }
        </div>
    )
};

export default GasItem;