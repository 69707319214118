import { SunIcon } from '../../../../../../common/ui/icons';
import { useFinanceStore } from '../../../../data/finance-store';
import { setTransactionsMonthToStorage, setTransactionsYearToStorage } from '../../../../domain/finance-storage';

const TodayBtn = () => {

    const setTransactionsSelectedMonth = useFinanceStore(store => store.setTransactionsSelectedMonth);
    const setTransactionsSelectedYear = useFinanceStore(store => store.setTransactionsSelectedYear);

    const jumpToNextMonth = () => {
        const today = new Date();
        const currentMonth = today.getMonth();
        const currentYear = today.getFullYear();

        setTransactionsSelectedMonth(currentMonth);
        setTransactionsMonthToStorage(currentMonth);

        setTransactionsSelectedYear(currentYear);
        setTransactionsYearToStorage(currentYear);
    };

    return (
        <button
            onClick={ jumpToNextMonth }
            type="button">
            <SunIcon
                color={ '#d99b00' }
                classes="mx-1"
            />
        </button>
    )
};

export default TodayBtn;