import TodayButton from './buttons/today-button';
import NextYearButton from './buttons/next-year-button';
import PrevYearButton from './buttons/prev-year-button';
import { useBlocksYearStore } from '../../data/blocks-year-store';
import BlocksTags from '../tags/tags';

const TopPanel = () => {

    const currentYear = useBlocksYearStore(store => store.currentYear);

    return (
        <>
            <div className="flex flex-col md:flex-row items-center mb-4">

                <div className="text-xl font-semibold">Year Blocks: { currentYear }</div>

                <div className="flex items-center mx-6">
                    <TodayButton/>

                    <div className="flex items-center mx-6">
                        <PrevYearButton/>
                        <NextYearButton/>
                    </div>

                </div>

            </div>

            <BlocksTags/>
        </>
    )
};

export default TopPanel;