import Actions from '../../../../../common/ui/actions';
import { useState } from 'react';
import { IPlant } from '../../../flowers-interfaces';
import PlantTypeColorIndicator from '../../plant-types/plant-type-color-indicator';
import PlantLifecycleText from '../../plant-life-cycle/plant-life-cycle-text';
import PlantLocationText from '../../plant-locations/plant-location-text';
import EditPlant from '../../plants/actions/edit-plant';
import DeletePlant from '../../plants/actions/delete-plant';
import { EditIcon } from '../../../../../common/ui/icons';
import PlantUploadAttachmentButton from '../../attachment/actions/upload-btn';
import TrackBtn from '../actions/track-btn';
import OpenPlantCalendarBtn from '../../plants/actions/open-plant-calendar';
import AttachmentsList from '../../attachment/attachments-list';
import { useFlowersStore } from '../../../data/flowers-store';
import { endOfDay } from 'date-fns';

interface IPlantProps {
    plant: IPlant;
    onUpdate: () => void;
}

enum Mode {
    Default = 0,
    Edit = 1,
}

const WaterLog = ({ plant, onUpdate }: IPlantProps) => {

    const [mode, setMode] = useState<Mode>(Mode.Default);

    const waterLogsDate = useFlowersStore(store => store.waterLogsDate);
    const isTodayChosen = endOfDay(new Date(waterLogsDate)).getTime() == endOfDay(new Date()).getTime();

    const startEdit = () => {
        setMode(Mode.Edit);
    };

    const stopEdit = () => {
        setMode(Mode.Default);
    };

    return (
        <div className="border-b pb-4 mb-4">
            {
                mode === Mode.Default &&
                (
                    <>
                        <div className="flex items-center">

                            <PlantTypeColorIndicator plantType={ plant.plantType }/>

                            <button
                                className={ `flex-1 text-left ${ plant.is_alive ? '' : 'line-through' }` }
                                type="button"
                                onClick={ startEdit }>
                                { plant.plant_name }
                            </button>

                            {
                                plant.quantity > 1 &&
                                <div className="mx-4 text-xs">({ plant.quantity })</div>
                            }

                            <Actions classes="plant-type__actions" mr="mr-0" ml="ml-auto" gridSize={ 2 }>
                                <DeletePlant plant={ plant }/>

                                <button
                                    title="Edit Plant"
                                    className="edit-plant-type flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                                    onClick={ startEdit }
                                    type="button">
                                    <EditIcon color={ '#6e7781' } size={ 20 }/>
                                </button>

                                <PlantUploadAttachmentButton
                                    plant={ plant }
                                    onUpdate={ onUpdate }
                                />

                                <OpenPlantCalendarBtn
                                    plant={ plant }
                                />
                            </Actions>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 md:gap-4 mt-4">
                            {
                                plant.attachments?.length > 0 &&
                                <AttachmentsList
                                    attachments={ plant.attachments }
                                    onUpdate={ onUpdate }
                                />
                            }

                            <div className="flex flex-col items-start px-4 pt-4 md:pt-2 text-slate-500 border border-slate-300 rounded-b md:rounded bg-slate-50">
                                {
                                    plant.location &&
                                    <div className="flex items-center mb-4">
                                        <PlantLocationText
                                            location={ plant.location }
                                        />

                                        {
                                            !!plant.location.is_indoor &&
                                            <div className="mx-4">🏚️</div>
                                        }
                                    </div>
                                }

                                <PlantLifecycleText
                                    plantLifeCycleType={ plant.plant_life_cycle_type }
                                />

                                {
                                    isTodayChosen &&
                                    <TrackBtn
                                        plant={ plant }
                                    />
                                }

                                {/*{
                                    plant.plant_desc &&
                                    <div
                                        className="markdown-editor text-slate-700 border-t border-slate-300 pt-4 mt-4"
                                        dangerouslySetInnerHTML={ { __html: markdown2Html(plant.plant_desc) } }
                                    />
                                }*/}

                            </div>
                        </div>
                    </>
                )
            }

            {
                mode === Mode.Edit &&
                <EditPlant
                    plant={ plant }
                    close={ stopEdit }
                />
            }
        </div>
    )
};

export default WaterLog;