import {
    IUseCaseDiagram, IUseCaseDiagramActorElement, IUseCaseDiagramElement, IUseCaseElement,
} from '../interfaces';
import { v4 as uuidv4 } from 'uuid';

const ACTOR_WIDTH = 30;
const ACTOR_HEIGHT = 60;

const USE_CASE_WIDTH = 200;
const USE_CASE_HEIGHT = 80;

const MARGIN_Y = 100;
const MARGIN_X = 150;
const BORDER_PADDING = 20;

const USE_CASES_ROW_COUNT = 2;

const prepareData = (data: IUseCaseDiagram) : IUseCaseDiagramElement|null => {
    if(!data) return null;

    const result: IUseCaseDiagramElement = {
        actors: [],
        useCases: [],

        associations: data.associations,
        extends: data.extends,
        includes: data.includes,

        border: {
            id: uuidv4(),
            x: 0,
            y: 0,
            width: 0,
            height: 0,
        }
    };

    // Use cases --------------------
    let y = BORDER_PADDING;
    let x = ACTOR_WIDTH + MARGIN_X + BORDER_PADDING;

    for(let i = 0; i < data.useCases.length; i++) {
        const useCase = data.useCases[i];

        const useCaseElement: IUseCaseElement = {
            id: useCase.id,
            name: useCase.name,
            x,
            y,
            width: USE_CASE_WIDTH,
            height: USE_CASE_HEIGHT,
        };

        result.useCases.push(useCaseElement);

        if(result.useCases.length % USE_CASES_ROW_COUNT === 0) {
            x = ACTOR_WIDTH + MARGIN_X + BORDER_PADDING;
            y += useCaseElement.height + MARGIN_Y;
        }
        else{
            x += USE_CASE_WIDTH + MARGIN_X;
        }
    }

    // Border ----------------------
    result.border.x = ACTOR_WIDTH + MARGIN_X;
    result.border.y = 0;
    result.border.width = USE_CASE_WIDTH * USE_CASES_ROW_COUNT + MARGIN_X * (USE_CASES_ROW_COUNT - 1) + BORDER_PADDING * 2;
    result.border.height = y - MARGIN_Y + BORDER_PADDING;


    // Actors -----------------------
    const actorsSectionHeight = data.actors.length * ACTOR_HEIGHT + MARGIN_Y * (data.actors.length - 1);
    y = (result.border.height - actorsSectionHeight) / 2;

    for(let i = 0; i < data.actors.length; i++) {
        const actor = data.actors[i];

        const actorElement: IUseCaseDiagramActorElement = {
            id: actor.id,
            name: actor.name,
            x: 0,
            y,
            width: ACTOR_WIDTH,
            height: ACTOR_HEIGHT,
        };

        result.actors.push(actorElement);

        y += actorElement.height + MARGIN_Y;
    }

    return result;
};

export const createUseCaseDiagramXml = (data: IUseCaseDiagram): string => {

    const preparedData = prepareData(data);
    if(!preparedData) return '';

    const initialXml = `
<mxfile host="draw.io">
  <diagram name="דיאגרמת תרחישים">
    <mxGraphModel background="#FFFFFF">
      <root>
        <mxCell id="0"/>
        <mxCell id="1" parent="0"/>
        
        ${ preparedData.actors.map(actorElement => renderActor(actorElement)).join('') }
        
        <!-- use cases border -->
        <mxCell id="${ preparedData.border.id }" value="" style="rounded=0;whiteSpace=wrap;html=1;" vertex="1" parent="1">
           <mxGeometry 
              x="${ preparedData.border.x }" 
              y="${ preparedData.border.y }" 
              width="${ preparedData.border.width }" 
              height="${ preparedData.border.height }" 
              as="geometry" 
           />
        </mxCell>
        
        ${ preparedData.useCases.map(useCaseElement => renderUseCase(useCaseElement)).join('') }
        
        <!-- actor to use-case connection -->
        ${
            preparedData.associations.map(association => {
                return `
                <mxCell id="${ uuidv4() }" style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=1;exitY=0.5;exitDx=0;exitDy=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;endArrow=none;startFill=0;" edge="1" parent="1" target="${ association.useCaseId }" source="${ association.actorId }">
                  <mxGeometry relative="1" as="geometry" />
                </mxCell>
                `;
            }).join('') 
        }
        
        <!-- extend connections -->
        ${
            preparedData.extends.map(extendItem => {
                const id = uuidv4();

                return `
                    <mxCell id="${ id }" style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=1;exitY=0.5;exitDx=0;exitDy=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;" edge="1" parent="1" source="${ extendItem.extendedUseCaseId }" target="${ extendItem.baseUseCaseId }">
                      <mxGeometry relative="1" as="geometry" />
                    </mxCell>
                
                    <mxCell id="${ uuidv4() }" value="&amp;lt;&amp;lt;extend&amp;gt;&amp;gt;" style="edgeLabel;html=1;align=center;verticalAlign=middle;resizable=0;points=[];" vertex="1" connectable="0" parent="${ id }">
                      <mxGeometry x="0.04" y="-2" relative="1" as="geometry">
                        <mxPoint as="offset" />
                      </mxGeometry>
                    </mxCell>
                `;
            }).join('') 
        }
        
        <!-- include connections -->
        ${
            preparedData.includes.map(includeItem => {
                
                const id = uuidv4();
                
                return `
                    <mxCell id="${ id }" style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=1;exitY=0.5;exitDx=0;exitDy=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;" edge="1" parent="1" source="${ includeItem.baseUseCaseId }" target="${ includeItem.includedUseCaseId }">
                      <mxGeometry relative="1" as="geometry" />
                    </mxCell>
                
                    <mxCell id="${ uuidv4() }" value="&amp;lt;&amp;lt;include&amp;gt;&amp;gt;" style="edgeLabel;html=1;align=center;verticalAlign=middle;resizable=0;points=[];" vertex="1" connectable="0" parent="${ id }">
                      <mxGeometry x="0.04" y="-2" relative="1" as="geometry">
                        <mxPoint as="offset" />
                      </mxGeometry>
                    </mxCell>
                `;
            }).join('') 
        }
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>`;

    return initialXml.trim();
};

const renderActor = (actorElement: IUseCaseDiagramActorElement) => {
    return `
        <!-- actor -->
        <mxCell 
            id="${ actorElement.id }" 
            value="${ actorElement.name }" 
            style="shape=umlActor;verticalLabelPosition=bottom;verticalAlign=top;html=1;outlineConnect=0;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ actorElement.x }" 
              y="${ actorElement.y }" 
              width="${ actorElement.width }" 
              height="${ actorElement.height }" 
              as="geometry" 
          />
        </mxCell>
    `;
};

const renderUseCase = (useCaseElement: IUseCaseElement) => {
    return `
        <!-- use case -->
        <mxCell 
            id="${ useCaseElement.id }" 
            value="${ useCaseElement.name }" 
            style="ellipse;whiteSpace=wrap;html=1;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ useCaseElement.x }" 
              y="${ useCaseElement.y }" 
              width="${ useCaseElement.width }" 
              height="${ useCaseElement.height }" 
              as="geometry" 
          />
        </mxCell>
    `;
};