const EntropyFormula = () => {
    return (
        <div className="font-bold mb-4 border border-slate-200 bg-slate-100 p-4 text-base">
            <math xmlns="http://www.w3.org/1998/Math/MathML">
                <mi>H</mi>
                <mo>(</mo>
                <mi>X</mi>
                <mo>)</mo>
                <mo>=</mo>
                <mo>&#x2212;</mo>
                {/* eslint-disable react/no-unknown-property */ }
                <mstyle displaystyle="true">
                    <munderover>
                        <mo>&#x2211;</mo>
                        <mrow>
                            <mi>i</mi>
                            <mo>=</mo>
                            <mn>1</mn>
                        </mrow>
                        <mi>n</mi>
                    </munderover>
                </mstyle>
                <mi>P</mi>
                <mo>(</mo>
                <msub>
                    <mi>x</mi>
                    <mi>i</mi>
                </msub>
                <mo>)</mo>
                <mo>&#x2061;</mo>
                <mo>log</mo>
                <mi>P</mi>
                <mo>(</mo>
                <msub>
                    <mi>x</mi>
                    <mi>i</mi>
                </msub>
                <mo>)</mo>
            </math>
        </div>
    )
};

export default EntropyFormula;