import { useAuthStatus } from '../auth/hooks/auth-status-hook';
import { MouseEvent, useEffect } from 'react';
import Preloader from '../../common/ui/preloader';
import MobileMenuBtn from '../../menu/ui/mobile-menu-btn';
import Menu from '../../menu/menu';
import YearBlocksHome from './blocks-year-home';

const YearBlocksIndex = () => {

    const { loading } = useAuthStatus();

    useEffect(() => {
        document.title = `Year Blocks | Productivity`;
    }, []);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">

            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden bg-white">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu showIcons={ true } hideSideBarToggle={ true } />
                    </div>

                    <YearBlocksHome />

                </div>
            }
        </div>
    )

};

export default YearBlocksIndex;