import { checkIsImage } from '../../../../common/domain/file-provider';
import DeleteAttachmentButton from './actions/delete-btn';
import { IPlantAttachment } from '../../flowers-interfaces';
import { useState } from 'react';
import EditPlantAttachmentButton from './actions/edit-attachment-btn';
import EditAttachment from './edit-attachment';

interface IPlantAttachmentProps {
    attachment: IPlantAttachment;
    width?: string;
    onUpdate: () => void;
}

enum AttachmentMode {
    DEFAULT = 0,
    EDIT = 1,
}

const PlantAttachment = ({ attachment, width, onUpdate }: IPlantAttachmentProps) => {

    const [mode, setMode] = useState<AttachmentMode>(AttachmentMode.DEFAULT);

    const path = `/api/flowers/attachment/get?path=${ attachment.path }`;
    const isImage = checkIsImage(path);

    const startEditDescription = () => {
        setMode(AttachmentMode.EDIT);
    };

    const stopEditDescription = () => {
        setMode(AttachmentMode.DEFAULT);
    };

    return (
        <div className="flex flex-col w-full pb-4">
            {
                isImage &&
                <a
                    className="attachment__box flex flex-col w-full"
                    href={ path }
                    rel="noreferrer"
                    target="_blank">

                    <div className="relative flex w-full">
                        {/*<img
                            className="rounded max-w-full"
                            src={ path }
                        />*/}

                        <div
                            className="rounded max-w-full"
                            style={{
                                background: `url('${ path }') no-repeat 50% 50%`,
                                backgroundSize: 'contain',
                                width: width || '100%',
                                height: '350px',
                            }}
                        />

                        <div className="attachment__action hidden flex justify-center items-center mb-2 absolute right-[3px] bottom-0 bg-white rounded p-1">

                            <DeleteAttachmentButton
                                attachment={ attachment }
                                onUpdate={ onUpdate }
                            />

                            <EditPlantAttachmentButton
                                startEditDescription={ startEditDescription }
                            />
                        </div>
                    </div>

                    {
                        attachment.description &&
                        <div className="text-sm italic mt-2">{ attachment.description }</div>
                    }

                </a>
            }

            {
                !isImage &&
                <a className="attachment__box flex flex-col leading-6"
                   href={ path }
                   rel="noreferrer"
                   target="_blank">

                    <div className="flex items-center relative">
                        <div className="border rounded py-2 px-4 bg-white mr-4">{ attachment.path }</div>
                        <div className="attachment__action hidden">

                            <DeleteAttachmentButton
                                attachment={ attachment }
                                onUpdate={ onUpdate }
                            />

                            <EditPlantAttachmentButton
                                startEditDescription={ startEditDescription }
                            />
                        </div>
                    </div>

                    {
                        attachment.description &&
                        <div className="italic mt-1">{ attachment.description }</div>
                    }
                </a>
            }

            {
                mode === AttachmentMode.EDIT &&
                <EditAttachment
                    attachment={ attachment }
                    stopEditDescription={ stopEditDescription }
                    onUpdate={ onUpdate }
                />
            }
        </div>
    )
};

export default PlantAttachment;