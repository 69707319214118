import { IBlocksCalendarMonth } from '../../../projects/blocks-year/blocks-year-interfaces';
import CommonBlocksYearCellView from './common-cell-view';
import { ITodo } from '../../../projects/planner/planner-interfaces';

interface IMonthView {
    month: IBlocksCalendarMonth;
    setOpenedEditTodo: (todo: ITodo|null) => void;
}

const weekTitleClasses = 'bg-slate-700 text-slate-100 p-1 text-xs flex items-center justify-center border-slate-500';
const weekTitleClassesWithRightBorder = `${ weekTitleClasses } border-r`;

const CommonBlocksYearMonthView = ({ month, setOpenedEditTodo }: IMonthView) => {

    const today = new Date();
    const monthStartDate = new Date(month.monthStartDate);
    const hasToday = today.getFullYear() === monthStartDate.getFullYear() &&
                     today.getMonth() === monthStartDate.getMonth();

    return (
        <div className="text-slate-500">

            <div className={ `mb-2 flex items-center justify-between ${ hasToday ? 'text-cyan-700' : '' }` }>
                <span>{ month.monthName }</span>

                {/*{
                    !!month.avgCalories &&
                    <span className="text-sm">{ Math.round(month.avgCalories) } ккал</span>
                }*/ }
            </div>

            <div className="grid grid-cols-7 border-2 border-black bg-slate-500">
                <div className={ weekTitleClassesWithRightBorder }>ВК</div>
                <div className={ weekTitleClassesWithRightBorder }>ПН</div>
                <div className={ weekTitleClassesWithRightBorder }>ВТ</div>
                <div className={ weekTitleClassesWithRightBorder }>СР</div>
                <div className={ weekTitleClassesWithRightBorder }>ЧТ</div>
                <div className={ weekTitleClassesWithRightBorder }>ПТ</div>
                <div className={ weekTitleClasses }>СБ</div>

                {
                    month.cells?.map(cell => {
                        return (
                            <CommonBlocksYearCellView
                                key={ cell.id }
                                cell={ cell }
                                setOpenedEditTodo={ setOpenedEditTodo }
                            />
                        )
                    })
                }
            </div>
        </div>
    )
};

export default CommonBlocksYearMonthView;