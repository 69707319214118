import { TransactionSource } from '../finance-interfaces';

const FINANCE_TRANSACTIONS_MONTH = 'FINANCE_TRANSACTIONS_MONTH';
const FINANCE_TRANSACTIONS_YEAR = 'FINANCE_TRANSACTIONS_YEAR';

export const getTransactionsMonthFromStorage = () : number => {
    const month = Number(window.localStorage.getItem(FINANCE_TRANSACTIONS_MONTH));

    const date = new Date();
    return isNaN(month) ? date.getMonth() : month;
};

export const setTransactionsMonthToStorage = (month: number) => {
    window.localStorage.setItem(FINANCE_TRANSACTIONS_MONTH, month.toString());
};

export const getTransactionsYearFromStorage = () : number => {
    const year = window.localStorage.getItem(FINANCE_TRANSACTIONS_YEAR);

    const date = new Date();
    return Number(year) || date.getFullYear();
};

export const setTransactionsYearToStorage = (year: number) => {
    window.localStorage.setItem(FINANCE_TRANSACTIONS_YEAR, year.toString());
};

export const getTransactionSourceName = (transactionSource: TransactionSource) => {
    switch (transactionSource) {

        case TransactionSource.BankLeumi: {
            return 'Bank Leumi';
        }

        case TransactionSource.Max8792: {
            return 'Leumi Card (Max) - Мой';
        }

        case TransactionSource.Max8893: {
            return 'Leumi Card (Max) - Сашин';
        }

        case TransactionSource.LeumiCardMax: {
            return 'Leumi Card (Max) - Смешанные данные';
        }

        case TransactionSource.IsraCard: {
            return 'Isra Card - Сашин';
        }

        case TransactionSource.VisaCal: {
            return 'Visa Cal (Суперсаль) - Смешанные данные';
        }

        case TransactionSource.VisaCal5550: {
            return 'Visa Cal (Суперсаль) - Мой';
        }

        case TransactionSource.VisaCal8910: {
            return 'Visa Cal (Суперсаль) - Сашин';
        }

        case TransactionSource.Cash: {
            return 'Cash';
        }

        case TransactionSource.CashCard: {
            return 'Cash Card';
        }
    }
};