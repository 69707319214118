import {
    eachDayOfInterval,
    eachMonthOfInterval,
    endOfYear,
    startOfYear,
    startOfMonth,
    endOfMonth,
    format,
    isToday,
    startOfDay,
    getDay,
} from 'date-fns';
import {
    IFoodCalendarDay,
    IFoodCalendarMonth,
    IFoodCalendarResponse,
    IFoodCalendarYear,
    IFoodLog
} from '../food-interfaces';
import { getDailyCalories, getLastGoalWeight } from './food-provider';

export const createFoodCalendar = (year: number, response: IFoodCalendarResponse) : IFoodCalendarYear => {

    const logs = response?.foodLogs || [];
    const goals = response?.foodGoals || [];

    const groupedLogsMap = logs.reduce((acc, log) => {

            const dateStart = startOfDay(log.date).getTime();

            if (!acc.has(dateStart)) {
                acc.set(dateStart, []);
            }

            acc.get(dateStart)?.push(log);

            return acc;
        },
        new Map<number, IFoodLog[]>()
    );

    const date = new Date(year, 0, 1);

    const yearGoalWeight = getLastGoalWeight(endOfYear(date).getTime(), goals);

    const result: IFoodCalendarYear = {
        year,
        months: [],
        avgCalories: 0,
        goalWeight: yearGoalWeight,
        dailyCalories: getDailyCalories(yearGoalWeight),
    };

    let yearAvgCalories = 0;
    let yearDaysWithNonZeroCalories = 0;

    const months: Date[] = eachMonthOfInterval({
        start: startOfYear(date),
        end: endOfYear(date),
    });

    for(const monthStartDate of months) {

        const goalWeight = getLastGoalWeight(endOfMonth(monthStartDate).getTime(), goals);

        const calendarMonth: IFoodCalendarMonth = {
            id: `${ year }-${ monthStartDate.getTime() }`,
            monthStartDate,
            monthName: format(monthStartDate, 'MMMM yyyy'),
            days: [],
            startDayOfWeek: getDay(monthStartDate), // 0 (Sunday) to 6 (Saturday)
            avgCalories: 0,
            goalWeight,
            dailyCalories: getDailyCalories(goalWeight)
        };

        const days = eachDayOfInterval({
            start: startOfMonth(monthStartDate),
            end: endOfMonth(monthStartDate),
        });

        let monthCaloriesSum = 0;
        let daysWithNonZeroCalories = 0;

        for(const dayStartDate of days) {

            const dayLogs = groupedLogsMap.get(dayStartDate.getTime()) || [];
            const calories = dayLogs.reduce((sum, item) => sum + item.calories * item.quantity, 0);
            const isTodayDay = isToday(dayStartDate);

            if(!isTodayDay) {
                monthCaloriesSum += calories;
                yearAvgCalories += calories;

                if(calories > 0) {
                    daysWithNonZeroCalories++;
                    yearDaysWithNonZeroCalories++;
                }
            }

            const calendarDay: IFoodCalendarDay = {
                id: `${ year }-${ monthStartDate.getTime() }-${ dayStartDate.getTime() }`,
                dayName: format(dayStartDate, 'd'),
                dayStartDate,
                isTodayDay,
                calories,
            };

            calendarMonth.days.push(calendarDay);
        }

        calendarMonth.avgCalories = daysWithNonZeroCalories > 0 ? (monthCaloriesSum/daysWithNonZeroCalories) : 0;

        result.months.push(calendarMonth);
    }

    result.avgCalories = yearDaysWithNonZeroCalories > 0 ? (yearAvgCalories/yearDaysWithNonZeroCalories) : 0;

    return result;
};