import {
    ICollaborationDiagramClassElement,
} from '../interfaces';
import { ISequenceDiagram } from '../../sequence-diagram/interfaces';
// import { v4 as uuidv4 } from 'uuid';

const ACTOR_WIDTH = 30;
const ACTOR_HEIGHT = 60;

const CLASS_WIDTH = 150;
const CLASS_HEIGHT = 80;
const CLASS_MARGIN_X = 150;
const CLASS_MARGIN_Y = 150;

const prepareData = (data: ISequenceDiagram) : ICollaborationDiagramClassElement[]|null => {
    if(!data) return null;

    // const classes: ICollaborationDiagramClassElement[] = [];

    const classesMap = new Map<string, ICollaborationDiagramClassElement>(); // class id ---> class

    const actorClassElement: ICollaborationDiagramClassElement = {
        id: data.actor.id,
        name: data.actor.name,

        x: 0,
        y: 0,
        width: ACTOR_WIDTH,
        height: ACTOR_HEIGHT,

        parent: null,
        children: [],
        messages: [],

        isActor: true,
    };
    // classes.push(actorClassElement);
    classesMap.set(actorClassElement.id, actorClassElement);

    for(const classItem of data.classes) {
        const classElement: ICollaborationDiagramClassElement = {
            id: classItem.id,
            name: classItem.name,

            x: 0,
            y: 0,
            width: CLASS_WIDTH,
            height: CLASS_HEIGHT,

            parent: null,
            messages: [],
            children: [],

            isActor: false,
        };
        // classes.push(classElement);
        classesMap.set(classElement.id, classElement);
    }

    let messagesIndex = 1;
    for(const { from, to, label } of data.messages) {
        const fromClass = classesMap.get(from);
        if(!fromClass) continue;

        const toClass = classesMap.get(to);
        if(!toClass) continue;

        toClass.parent = fromClass;

        const foundChild = fromClass.children.find(child => child.id === toClass.id);
        if(!foundChild) {
            fromClass.children.push(toClass);
        }

        toClass.messages.push({
            text: label,
            index: messagesIndex,
        });

        messagesIndex++;
    }

    // Use BFS to create node rows

    const result: ICollaborationDiagramClassElement[] = [];
    const queue: ICollaborationDiagramClassElement[] = [actorClassElement];

    // let level = 0;
    let x= 0;
    let y = CLASS_HEIGHT + CLASS_MARGIN_Y;

    while (queue.length > 0) {

        let levelSize = queue.length;

        while (levelSize > 0) {
            const node = queue.shift() as ICollaborationDiagramClassElement;
            result.push(node);

            for(const child of node.children) {
                child.x = x;
                child.y = y;
                x += CLASS_WIDTH + CLASS_MARGIN_X;
                queue.push(child);
            }

            levelSize--;
        }

        // console.log(level, temp);
        // level++;

        x = 0;
        y += CLASS_HEIGHT + CLASS_MARGIN_Y;
    }

    return result;
};

export const createSequenceDiagramXml = (data: ISequenceDiagram): string => {

    const preparedData = prepareData(data);
    if(!preparedData) return '';

    const initialXml = `
<mxfile host="draw.io">
  <diagram name="דיאגרמת שיתוף פעולה">
    <mxGraphModel background="#FFFFFF">
      <root>
        <mxCell id="0"/>
        <mxCell id="1" parent="0"/>
        
        ${ preparedData.map(node => renderNode(node)).join('') }
        
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>`;

    return initialXml.trim();
};


const renderNode = (node: ICollaborationDiagramClassElement): string => {

    const containerID = node.id;

    return (
        `
        ${
            node.isActor ?

                `
                <!-- actor -->
                <mxCell
                    id="${ node.id }"
                    value="${ node.name }"
                    style="shape=umlActor;verticalLabelPosition=bottom;verticalAlign=top;html=1;outlineConnect=0;"
                    vertex="1"
                    parent="1">
                  <mxGeometry
                      x="${ node.x }"
                      y="${ node.y }"
                      width="${ node.width }"
                      height="${ node.height }"
                      as="geometry"
                  />
                </mxCell>
                ` :

                `<mxCell 
                    id="${ containerID }" 
                    value="${ node.name }" 
                    style="rounded=0;whiteSpace=wrap;html=1;fillColor=#ffe6cc;strokeColor=#d79b00;spacingLeft=15;spacingRight=15;" 
                    vertex="1" 
                    parent="1">
                  <mxGeometry x="${ node.x }" y="${ node.y }" width="${ node.width }" height="${ node.height }" as="geometry"/>
                </mxCell>
                `
        }
        
        ${
            node.parent ? `
            <mxCell 
                 id="${ containerID + '-connection-to-parent' }" 
                 style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=0.5;exitY=0;exitDx=0;exitDy=0;entryX=0.5;entryY=1;entryDx=0;entryDy=0;entryPerimeter=0;endArrow=none;endFill=0;startArrow=classic;" 
                 value="${ node.messages.map(msg => `&lt;div&gt;${ msg.index }: ${ msg.text }&lt;/div&gt;`).join('') }"
                 parent="${ containerID }"  
                 target="${ node.parent.id }" 
                 source="${ containerID }"
                 edge="1">
              <mxGeometry relative="1" as="geometry"/>
            </mxCell>
            
           
            ` : ''
        }
        `
    );
};

/*
<mxCell
               id="mJKERZCr9raA4mstPmX3-8"
               value="Text&lt;div&gt;aaa&lt;/div&gt;&lt;div&gt;aaa&lt;/div&gt;" style="text;html=1;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;"
               vertex="1"
               parent="1">
              <mxGeometry x="-30" y="-340" width="100" height="70" as="geometry" />
            </mxCell>
 */