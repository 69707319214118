import { IDialogueDiagramDataItem, IDialogueDiagramNode } from '../interfaces';
import { v4 as uuidv4 } from 'uuid';

const NODE_WIDTH = 150;
const LINK_HEIGHT = 25;
const NODE_HEIGHT = 80 + LINK_HEIGHT;
const NODE_PADDING_X = 25;
const NODE_PADDING_Y = 70;
const TEXT_HEIGHT = 25;

const prepareData = (data: IDialogueDiagramDataItem[]) : IDialogueDiagramNode[] => {
    if(!data) return [];

    const map = new Map<string, IDialogueDiagramNode>(); // node_id --> node

    // Create all prepared nodes.
    for(const node of data) {
        const preparedNode: IDialogueDiagramNode = {
            renderID: uuidv4(),
            id: node.id,
            label: node.label,
            links: node.links?.join(', ') ?? '',
            children: [],
            x: 0,
            y: 0,
        };

        map.set(node.id, preparedNode);
    }

    // Set appropriate children in the nodes.
    for(const node of data) {
        const preparedNode = map.get(node.id);
        if(!preparedNode) continue;

        for(const childId of node.children) {
            const preparedChildNode = map.get(childId);
            if(!preparedChildNode) continue;

            preparedChildNode.parent = preparedNode;
            preparedNode.children.push(preparedChildNode);
        }

        map.set(node.id, preparedNode);
    }

    // Find root node
    let rootNode: IDialogueDiagramNode = null;

    for(const [_nodeId, node] of map) {
        if(!node.parent) {
            rootNode = node;
            break;
        }
    }

    // Use BFS to create node rows
    const result: IDialogueDiagramNode[] = [];
    const queue: IDialogueDiagramNode[] = [rootNode];

    // let level = 0;
    let x= 0;
    let y = NODE_HEIGHT + NODE_PADDING_Y;

    while (queue.length > 0) {

        let levelSize = queue.length;

        while (levelSize > 0) {
            const node = queue.shift() as IDialogueDiagramNode;
            result.push(node);

            for(const child of node.children) {
                child.x = x;
                child.y = y;
                x += NODE_WIDTH + NODE_PADDING_X;
                queue.push(child);
            }

            levelSize--;
        }

        // console.log(level, temp);
        // level++;

        x = 0;
        y += NODE_HEIGHT + NODE_PADDING_Y;
    }

    return result;
};

export const createDialogueDiagramXml = (data: IDialogueDiagramDataItem[]): string => {

    const preparedData = prepareData(data);
    if(!preparedData) return '';

    const initialXml = `
<mxfile host="draw.io">
  <diagram name="עץ התפריטים">
    <mxGraphModel background="#FFFFFF">
      <root>
        <mxCell id="0"/>
        <mxCell id="1" parent="0"/>
        
        ${ preparedData.map(node => renderNode(node)).join('') }
        
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>`;

    return initialXml.trim();
};

const renderNode = (node: IDialogueDiagramNode): string => {

    const containerID = node.renderID;

    return (
        `<mxCell 
            id="${ containerID }" 
            value="${ node.id }" 
            style="swimlane;rounded=0;whiteSpace=wrap;html=1;fillColor=#ffffff;fontStyle=1;" 
            vertex="1" 
            parent="1">
          <mxGeometry x="${ node.x }" y="${ node.y }" width="${ NODE_WIDTH }" height="${ NODE_HEIGHT }" as="geometry"/>
        </mxCell>
        
        <mxCell 
            id="${ containerID + '-text' }" 
            value="${ node.label }" 
            style="text;fontSize=14;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;whiteSpace=wrap;html=1;" 
            parent="${ containerID }"  
            vertex="1">
          <mxGeometry y="${ TEXT_HEIGHT }" width="${ NODE_WIDTH }" height="${ NODE_HEIGHT - LINK_HEIGHT - TEXT_HEIGHT }" as="geometry"/>
        </mxCell>
        
        <mxCell 
            id="${ containerID + '-divider' }" 
            value="" 
            style="line;strokeWidth=1;fillColor=none;align=left;verticalAlign=middle;spacingTop=-1;spacingLeft=3;spacingRight=3;rotatable=0;strokeColor=inherit;" 
            parent="${ containerID }"
            vertex="1">
          <mxGeometry y="${ NODE_HEIGHT - LINK_HEIGHT }" width="${ NODE_WIDTH }" height="8" as="geometry" />
        </mxCell>
        
        <mxCell 
            id="${ containerID + '-links' }" 
            value="${ node.links  }" 
            style="text;fontSize=12;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;whiteSpace=wrap;html=1;" 
            parent="${ containerID }"  
            vertex="1">
          <mxGeometry y="${ NODE_HEIGHT - LINK_HEIGHT + 3 }" width="${ NODE_WIDTH }" height="${ LINK_HEIGHT }" as="geometry"/>
        </mxCell>
        
        ${
            node.parent ? `
            <mxCell 
                 id="${ containerID + '-connection-to-parent' }" 
                 style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=0.5;exitY=0;exitDx=0;exitDy=0;entryX=0.5;entryY=1;entryDx=0;entryDy=0;entryPerimeter=0;endArrow=none;endFill=0;" 
                 parent="${ containerID }"  
                 target="${ node.parent.renderID }" 
                 source="${ containerID }"
                 edge="1">
              <mxGeometry relative="1" as="geometry"/>
            </mxCell>
            ` : ''
        }
        `
    );
};
