import { useFoodStore } from '../../../data/food-store';
import FoodItem from './food-item';
import SortButtons from '../actions/sort-btns';
import { FoodItemsSort } from '../../../food-interfaces';

const FoodItemsList = () => {

    const foodItemsData = useFoodStore(store => store.foodItemsData);

    return (
        <div className="w-full overflow-auto">
            <table className="w-full border border-collapse mb-3">
                <thead>
                <tr>
                    <th className="border px-4 py-2 bg-yellow-50">&nbsp;</th>
                    <th className="border px-4 py-2 text-left bg-yellow-50 align-top">
                        <div className="flex items-center justify-center gap-4">
                            <p>Название</p>
                            <SortButtons sortType={ FoodItemsSort.Name } />
                        </div>
                    </th>
                    <th className="border px-4 py-2 text-left bg-yellow-50 align-top">
                        <div className="flex items-center justify-center gap-4">
                            <p>Калории</p>
                            <SortButtons sortType={ FoodItemsSort.Calories }/>
                        </div>
                    </th>
                    <th className="border px-4 py-2 text-left bg-yellow-50 align-top">
                        <div className="flex items-center justify-center gap-4">
                            <p>
                                Белки<br/>
                                <span className="text-xs font-normal">граммы</span>
                            </p>
                            <SortButtons sortType={ FoodItemsSort.Protein }/>
                        </div>
                    </th>
                    <th className="border px-4 py-2 text-left bg-yellow-50 align-top">
                        <div className="flex items-center justify-center gap-4">
                            <p>
                                Жиры<br/>
                                <span className="text-xs font-normal">граммы</span>
                            </p>
                            <SortButtons sortType={ FoodItemsSort.Fats }/>
                        </div>
                    </th>
                    <th className="border px-4 py-2 text-left bg-yellow-50 align-top">
                        <div className="flex items-center justify-center gap-4">
                            <p>
                                Углеводы<br/>
                                <span className="text-xs font-normal">граммы</span>
                            </p>
                            <SortButtons sortType={ FoodItemsSort.Carbohydrates }/>
                        </div>
                    </th>
                    <th className="border px-4 py-2 bg-yellow-50">&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {
                    foodItemsData?.foods.map(
                        (food, index) => {

                            return (
                                <FoodItem
                                    key={ food.id }
                                    food={ food }
                                    index={ index }
                                />
                            );
                        })

                }
                </tbody>
            </table>
        </div>
    )
};

export default FoodItemsList;