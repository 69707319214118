import { NavLink } from 'react-router-dom';

interface IOpenuSolverMenuItem {
    text: string;
    link?: string;
    isTitle: boolean;
    number?: number;
}

const OpenuSolverMenuItem = ({ text ,link, isTitle, number }: IOpenuSolverMenuItem) => {

    return (
        <>
            {
                isTitle &&
                <div className="text-left flex-1 ml-2 text-slate-400 mb-2">
                    { text }
                </div>
            }

            {
                !isTitle &&
                <NavLink
                    to={ link }
                    end={ true }
                    className="text-left flex-1 ml-6 mb-2">
                    { number === undefined ? '•' : `${ number }. ` } <span className="underline">{ text }</span>
                </NavLink>
            }
        </>
    )
};

export default OpenuSolverMenuItem;