import PrevDayBtn from './actions/prev-day-btn';
import TodayBtn from './actions/today-btn';
import NextDayBtn from './actions/next-day-btn';

const DatesPanel = () => {
    return (
        <div className="flex items-center">
            <PrevDayBtn />
            <TodayBtn />
            <NextDayBtn />
        </div>
    )
};

export default DatesPanel;