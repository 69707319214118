import { useState } from 'react';
import { showToast } from '../../../../../common/domain/toast-provider';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { IFoodGoal } from '../../../food-interfaces';
import { useFoodStore } from '../../../data/food-store';
import { deleteFoodGoal } from '../../../data/food-data-service';

interface IDeleteFoodGoal {
    foodGoal: IFoodGoal;
}

const DeleteFoodGoal = ({ foodGoal }: IDeleteFoodGoal) => {

    const [isLoading, setLoading] = useState(false);

    const setFoodGoalsData = useFoodStore(store => store.setFoodGoalsData);

    const deleteGoalHandler = async () => {

        if(!confirm(`Are you sure you want to delete this food goal?`)) return;

        setLoading(true);
        const response = await deleteFoodGoal(foodGoal.id);
        setLoading(false);

        if(!response) {
            showToast('Delete goal error.');
            return;
        }

        setFoodGoalsData(response);
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Food Goal"
                    className="food-goal__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteGoalHandler }
                    type="button">
                    <DeleteIcon size={ 20 } color="#BE185D" />
                </button>
            }
        </>
    )
};

export default DeleteFoodGoal;