import { useState } from 'react';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useNavigate } from 'react-router-dom';
import { deleteDeck } from '../../../data/flashcards-data-service';
import { formatFlashCardsTree } from '../../../domain/flashcards-provider';
import { useFlashCardsStore } from '../../../data/flashcards-store';

interface IDeleteDeckProps {
    deck_id: number;
    deck_name: string;
    cards_count: number;
}

const DeleteDeckButton = ({ deck_id, deck_name, cards_count }: IDeleteDeckProps) => {

    const [isLoading, setLoading] = useState(false);

    const navigate = useNavigate();

    const setSelectedDeck = useFlashCardsStore(store => store.setSelectedDeck);
    const flashCardsListType = useFlashCardsStore(store => store.flashCardsListType);
    const setFlashCardsTree = useFlashCardsStore(store => store.setFlashCardsTree);

    const deleteDeckHandler = async () => {

        if(!confirm(`Are you sure you want to delete the deck "${ deck_name }"${ cards_count > 0 ? `and ${ cards_count } cards` : '' }?`)) return;

        setLoading(true);
        const response = await deleteDeck(flashCardsListType, deck_id);
        setLoading(false);

        if(!response.isValid) {
            showToast('Delete deck error.');
            return;
        }

        setFlashCardsTree(formatFlashCardsTree(response));

        setSelectedDeck(undefined);

        navigate('/flashcards');
    };

    return (
        <>
            {
                isLoading &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isLoading &&
                <button
                    title="Delete Deck"
                    className="deck__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteDeckHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }


        </>
    )
};

export default DeleteDeckButton;