const MISSING_SVG_ERR = 'Either an SVG element or an SVG string must be provided.';

const formatExt = (ext?: string) : string => {
    if(!ext || !ext.trim()) return 'svg';

    return ext.trim().toLowerCase();
};

/**
 * Create SVG document from string
 */
export const createSVGFromString = (props: {
    document?: Document;
    svg: string;
}) : SVGSVGElement => {
    const {
        document: _document,
        svg,
    } = props;

    const doc = _document || window.document;

    const $box = doc.createElement('div');
    $box.innerHTML = svg;
    return $box.firstElementChild as SVGSVGElement;
};

export const getSVGAsString = ($svg: SVGSVGElement) => {
    return $svg.outerHTML;
};

export const download = (props: {
    $svg?: SVGSVGElement,
    svgString?: string;
    outfileName?: string;
    ext?: string; // svg, png, jpg, jpeg
}) => {
    return new Promise<{
        isError: boolean;
        msg?: string;
    }>((resolve, reject) => {
        if(!props.$svg && !props.svgString){
            reject({
                isError: true,
                msg: MISSING_SVG_ERR,
            });
            return;
        }

        const _svgString = props.$svg ? getSVGAsString(props.$svg) : props.svgString;
        const svgString = `<?xml version="1.0" encoding="UTF-8"?>${ _svgString }`;

        const formattedExt = formatExt(props.ext);
        const formattedFileName = `${ props.outfileName || 'out'}.${ formattedExt }`;

        if(formattedExt === 'svg' || formattedExt === 'drawio'){

            const blob = new Blob([svgString], {type : 'text/plain'});
            const url = URL.createObjectURL(blob);

            const link = document.createElement('a');
            link.href = url;
            link.title = '';
            link.download = formattedFileName;
            link.click();

            URL.revokeObjectURL(url);
            resolve({
                isError: false,
            });
        }
        else{
            const $svg = props.$svg || createSVGFromString({
                svg: props.svgString || '',
            });

            const $canvas = document.createElement('canvas');
            $canvas.width = $svg.width.baseVal.value;
            $canvas.height = $svg.height.baseVal.value;

            const ctx = $canvas.getContext('2d');
            if(!ctx){
                reject({
                    isError: true,
                });
                return;
            }

            const data = (new XMLSerializer()).serializeToString($svg);
            const blob = new Blob([data], {type: 'image/svg+xml;charset=utf-8'});
            const url = URL.createObjectURL(blob);

            const $img = new Image();
            $img.onload = () => {
                ctx.clearRect(0, 0, $canvas.width, $canvas.height);
                ctx.drawImage($img, 0, 0);

                URL.revokeObjectURL(url);

                $canvas.toBlob(cBlob => {

                    if(!cBlob){
                        resolve({
                            isError: true,
                        });
                        return;
                    }

                    const cUrl = URL.createObjectURL(cBlob);
                    const link = document.createElement('a');
                    link.href = cUrl;
                    link.title = '';
                    link.download = formattedFileName;
                    link.click();

                    URL.revokeObjectURL(cUrl);

                    resolve({
                        isError: false,
                    });
                });
            };

            $img.onerror = () => {
                resolve({
                    isError: true,
                });
            };

            $img.src = url;
        }
    });
};

export const downloadImage = (imageData: string, imageName: string) => {
    const link = document.createElement('a');
    link.href = imageData;
    link.download = imageName;

    // Trigger the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const copyImageToClipboard = async (imageData: string) => {
    try {
        const response = await fetch(imageData);
        const blob = await response.blob();

        await navigator.clipboard.write([
            new ClipboardItem({
                'image/png': blob,
            })
        ]);
    }
    catch (error) {
        console.error(error);
    }
};

export const copySvgToClipboard1 = async (svgData: string) => {
    try {
        const blob = new Blob([svgData], { type: 'image/svg+xml' });

        await navigator.clipboard.write([
            new ClipboardItem({
                'image/svg+xml': blob,
            })
        ]);

    }
    catch (error) {
        console.error(error);
    }
};

export const copySvgToClipboard = async (svgData: string) => {
    try {
        const img = new Image();
        img.src = svgData;

        img.onload = async () => {
            // Create a Canvas and draw the SVG image
            const canvas = document.createElement('canvas');
            canvas.width = img.width;
            canvas.height = img.height;

            const ctx = canvas.getContext('2d');
            if (!ctx) throw new Error('Canvas context is not supported');

            ctx.drawImage(img, 0, 0);

            // Convert to PNG Blob
            canvas.toBlob(async (blob) => {
                if (!blob) throw new Error('Failed to create PNG blob');

                // Copy to clipboard as PNG
                await navigator.clipboard.write([
                    new ClipboardItem({ 'image/png': blob })
                ]);

                console.log('SVG converted to PNG and copied to clipboard!');
            }, 'image/png');
        };
    }
    catch (error) {
        console.error('Failed to copy SVG:', error);
    }
};

