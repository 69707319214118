import { useEffect, useState } from 'react';
import { ITodo } from '../../../../projects/planner/planner-interfaces';
import { PlusIcon } from '../../icons';
import CommonEditBlockItem from './common-edit-block-item';

interface ICommonTodoBlocksEdit {
    todo: ITodo;
    close: () => void|null;
    onInsert: (
        todo_id: string|number,
        startDate: number,
        endDate: number
    ) => Promise<void>;
    onUpdate: (
        block_id: number,
        startDate: number,
        endDate: number
    ) => Promise<void>;
    onDelete(block_id: number): Promise<void>;
}

export enum CommonTodoBlocksEditMode {
    DEFAULT = 0,
    START_ADD = 1
}

const CommonTodoBlocksEdit = ({ todo, close, onInsert, onUpdate, onDelete }: ICommonTodoBlocksEdit) => {

    const [mode, setMode] = useState<CommonTodoBlocksEditMode>(CommonTodoBlocksEditMode.DEFAULT);

    const startAdd = () => {
        setMode(CommonTodoBlocksEditMode.START_ADD);
    };

    const stopAdd = () => {
        setMode(CommonTodoBlocksEditMode.DEFAULT);
    };

    useEffect(() => {

        const onKeyDown = (evt: KeyboardEvent) => {
            if(evt.code === 'Escape' && close) {
                close();
            }
        };

        document.addEventListener('keydown', onKeyDown);

        return () => {
            document.removeEventListener('keydown', onKeyDown);
        }
    }, [close]);

    return (
        <div className="flex flex-col text-sm my-2">

            {
                todo.blocks?.map(block => {
                    return (
                        <div
                            key={ block.block_id }>
                            <CommonEditBlockItem
                                todo_id={ todo.todo_id }
                                block={ block }
                                cancelNewBlock={ stopAdd }
                                close={ close }
                                onInsert={ onInsert }
                                onUpdate={ onUpdate }
                                onDelete={ onDelete }
                            />
                        </div>
                    )
                })
            }

            {
                (mode === CommonTodoBlocksEditMode.START_ADD) &&
                <CommonEditBlockItem
                    todo_id={ todo.todo_id }
                    block={ null }
                    cancelNewBlock={ stopAdd }
                    close={ close }
                    onInsert={ onInsert }
                    onUpdate={ onUpdate }
                    onDelete={ onDelete }
                />
            }

            <div className="flex items-center mt-2">

                {
                    close &&
                    <button
                        onClick={ close }
                        className="bg-stone-400 text-slate-100 rounded px-6 py-1 mr-2"
                        type="button">
                        Close
                    </button>
                }

                {
                    (mode === CommonTodoBlocksEditMode.DEFAULT) &&
                    <button
                        onClick={ startAdd }
                        className="flex bg-slate-500 text-slate-100 rounded justify-center items-center px-6 py-1 mr-2"
                        type="button">
                        <PlusIcon color={ '#fff' } classes="mr-2" /> Block
                    </button>
                }

            </div>
        </div>
    )
};

export default CommonTodoBlocksEdit;