import {
    EntityElement,
    ProcessElement,
    DataStoreElement,
    IDataFlowDiagramData,
    DfdDiagramElement,
} from '../interfaces';
import { v4 as uuidv4 } from 'uuid';

const ELEMENTS_MARGIN_Y = 100;
const ELEMENTS_MARGIN_X = 300;

const ENTITY_WIDTH = 120;
const ENTITY_HEIGHT = 50;

const PROCESS_WIDTH = 150;
const PROCESS_HEIGHT = 80;
const PROCESS_MARGIN_X = 100;
const PROCESS_MARGIN_Y = 50;
const PROCESS_TOP_NUMBER_HEIGHT = 20;

const DATA_STORE_WIDTH = 180;
const DATA_STORE_HEIGHT = 40;
const DATA_NUMBER_WIDTH = 40;

const ARROW_MARGIN_Y = 25
const ARROW_LENGTH = 180;

const prepareData = (data: IDataFlowDiagramData) : DfdDiagramElement|null => {
    if(!data) return null;

    const dataProcesses = data.processes ?? [];
    const dataEntities = data.entities ?? [] ?? [];
    const dataDataStores = (data.dataStores ?? []);

    const entities: EntityElement[] = dataEntities.map(entity => {

        return {
            id: entity.id,
            name: entity.name,
            x: 0,
            y: 0,
            width: ENTITY_WIDTH,
            height: ENTITY_HEIGHT,
            data: entity,
            inputs: entity.inputs || [],
            outputs: entity.outputs || [],
        }
    });

    let y = 0;
    let x = 0;

    for(let i=0; i<entities.length; i++) {
        const entity = entities[i];
        entity.x = x;
        entity.y = y;
        y += entity.height + ELEMENTS_MARGIN_Y;
    }

    x = ENTITY_WIDTH + ELEMENTS_MARGIN_X;
    y = 0;

    const processes: ProcessElement[] = dataProcesses.map((process, i) => {
        return {
            id: process.id,
            index: i,
            number: `${ i + 1 }.0`,
            name: process.name,
            x,
            y: ELEMENTS_MARGIN_Y,
            width: PROCESS_WIDTH,
            height: PROCESS_HEIGHT,
            data: process,
        }
    });

    for(let i=0; i<processes.length; i++) {
        const process = processes[i];
        process.y = y;
        y += process.height + PROCESS_MARGIN_Y;
    }

    x += PROCESS_WIDTH + PROCESS_MARGIN_X;
    y = 0;

    const dataStores: DataStoreElement[] = dataDataStores.map((dataStore, i) => {
        return {
            id: dataStore.id,
            index: `D${ i + 1 }`,
            name: dataStore.name,
            x,
            y: ELEMENTS_MARGIN_Y * 2,
            width: DATA_STORE_WIDTH,
            height: DATA_STORE_HEIGHT,
            data: dataStore,
        }
    });

    for(let i=0; i<dataStores.length; i++) {
        const dataStore = dataStores[i];
        dataStore.y = y;
        y += dataStore.height + ELEMENTS_MARGIN_Y;
    }

    return {
        id: uuidv4(),
        entities,
        processes,
        dataStores,
    };
};

export const createDfdDiagramXml = (data: IDataFlowDiagramData): string => {

    const preparedData = prepareData(data);
    if(!preparedData) return '';

    const initialXml = `
<mxfile host="draw.io">
  <diagram name="דיאגרמת בועות">
    <mxGraphModel background="#FFFFFF">
      <root>
        <mxCell id="0"/>
        <mxCell id="1" parent="0"/>
        
        ${ preparedData.entities.map(entity => renderEntity(entity)).join('') }
        ${ preparedData.processes.map(process => renderProcess(process)).join('') }
        ${ preparedData.dataStores.map(dataStore => renderDataStore(dataStore)).join('') }
        
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>`;

    return initialXml.trim();
};

const renderEntity = (entity: EntityElement) => {
    let y = entity.y - ARROW_MARGIN_Y;
    const x = entity.x + entity.width + 10;

    return `
        <mxCell 
            id="${ entity.id }" 
            value="${ entity.name }" 
            style="rounded=0;whiteSpace=wrap;html=1;fillColor=#ffffff;fontStyle=1;fontSize=14;spacingLeft=5;spacingRight=5;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ entity.x }" 
              y="${ entity.y }" 
              width="${ ENTITY_WIDTH }" 
              height="${ ENTITY_HEIGHT }" 
              as="geometry"
              />
        </mxCell>
        
        ${
            entity.inputs.map(input => {
                const id = uuidv4();
                y += ARROW_MARGIN_Y;
                
                return `
                    <mxCell id="${ id }" value="" style="endArrow=classic;html=1;rounded=0;" edge="1" parent="1">
                      <mxGeometry width="50" height="50" relative="1" as="geometry">
                        <mxPoint x="${ x }" y="${ y }" as="sourcePoint" />
                        <mxPoint x="${ x + ARROW_LENGTH }" y="${ y }" as="targetPoint" />
                      </mxGeometry>
                    </mxCell>
                    
                    <mxCell id="${ uuidv4() }" value="${ input }" style="edgeLabel;html=1;align=center;verticalAlign=middle;resizable=0;points=[];fontSize=13;" vertex="1" connectable="0" parent="${ id }">
                      <mxGeometry x="-0.01" y="1" relative="1" as="geometry">
                        <mxPoint as="offset" />
                      </mxGeometry>
                    </mxCell>
                `
            }).join('')}
        
        ${
        
            entity.outputs.map(output => {
                const id = uuidv4();
                y += ARROW_MARGIN_Y;
                
                return `
                        <mxCell id="${ id }" value="" style="endArrow=none;startArrow=classic;html=1;rounded=0;" edge="1" parent="1">
                          <mxGeometry width="50" height="50" relative="1" as="geometry">
                            <mxPoint x="${ x }" y="${ y }" as="sourcePoint" />
                            <mxPoint x="${ x + ARROW_LENGTH }" y="${ y }" as="targetPoint" />
                          </mxGeometry>
                        </mxCell>
                        
                        <mxCell id="${ uuidv4() }" value="${ output }" style="edgeLabel;html=1;align=center;verticalAlign=middle;resizable=0;points=[];fontSize=13;" vertex="1" connectable="0" parent="${ id }">
                          <mxGeometry x="-0.01" y="1" relative="1" as="geometry">
                            <mxPoint as="offset" />
                          </mxGeometry>
                        </mxCell>
                    `
            }).join('')}
    `;
};

const renderProcess = (process: ProcessElement) => {

    return `
        <!-- the box -->
        <mxCell 
            id="${ process.id }" 
            value="${ process.name }" 
            style="rounded=1;whiteSpace=wrap;html=1;fillColor=#ffffff;fontSize=12;spacingLeft=15;spacingRight=15;spacingTop=${ PROCESS_TOP_NUMBER_HEIGHT }" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ process.x }" 
              y="${ process.y }" 
              width="${ PROCESS_WIDTH }" 
              height="${ PROCESS_HEIGHT }" 
              as="geometry"
              />
        </mxCell>
        
        <!-- horizontal divider -->
        <mxCell 
            id="${ process.id + '-divider' }" 
            value="" 
            style="line;strokeWidth=1;fillColor=none;align=left;verticalAlign=middle;spacingTop=-1;spacingLeft=3;spacingRight=3;rotatable=0;strokeColor=inherit;" 
            parent="${ process.id }"
            vertex="1">
          <mxGeometry y="${ PROCESS_TOP_NUMBER_HEIGHT }" width="${ PROCESS_WIDTH }" height="8" as="geometry" />
        </mxCell>
        
        <mxCell 
            id="${ process.id + '-number' }" 
            value="${ process.number }" 
            style="text;fontStyle=1;fontSize=14;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;whiteSpace=wrap;html=1;" 
            parent="${ process.id }"  
            vertex="1">
          <mxGeometry y="${ 4 }" width="${ PROCESS_WIDTH }" height="${ PROCESS_TOP_NUMBER_HEIGHT }" as="geometry"/>
        </mxCell>
    `;
};

const renderDataStore = (dataStore: DataStoreElement) => {
    return `
        <mxCell 
            id="${ dataStore.id }" 
            value="${ dataStore.name }" 
            style="rounded=0;whiteSpace=nowrap;html=1;fillColor=#ffffff;fontSize=12;spacingLeft=${ DATA_NUMBER_WIDTH + 5 };spacingRight=5;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ dataStore.x }" 
              y="${ dataStore.y }" 
              width="${ DATA_STORE_WIDTH }" 
              height="${ DATA_STORE_HEIGHT }" 
              as="geometry"
              />
        </mxCell>
        
        <!-- number like D1, D2, ... -->
        <mxCell 
            id="${ dataStore.id + '-number' }" 
            value="${ dataStore.index }" 
            style="text;fontStyle=1;fontSize=12;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;whiteSpace=nowrap;html=1;" 
            parent="${ dataStore.id }"  
            vertex="1">
          <mxGeometry 
              width="${ DATA_NUMBER_WIDTH }" 
              height="${ DATA_STORE_HEIGHT }" 
              as="geometry"
          />
        </mxCell>
        
        <!-- vertical divider -->
        <mxCell 
            id="${ dataStore.id + '-divider' }" 
            value="" 
            style="line;strokeWidth=1;fillColor=none;align=left;verticalAlign=middle;spacingTop=-1;spacingLeft=3;spacingRight=3;rotatable=0;strokeColor=inherit;shape=line;direction=north;" 
            parent="${ dataStore.id }"
            vertex="1">
          <mxGeometry 
              x="${ DATA_NUMBER_WIDTH }" 
              y="0"
              width="2" 
              height="${ DATA_STORE_HEIGHT }" 
              as="geometry" 
          />
        </mxCell>
    `;
};
