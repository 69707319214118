import { IStateDiagramNode, IStateDiagram } from '../interfaces';
import { v4 as uuidv4 } from 'uuid';

const NODE_WIDTH = 150;
const NODE_HEIGHT = 80;
const NODE_MARGIN_X = 35;
const NODE_MARGIN_Y = 100;
const START_CIRCLE_SIZE = 30;
const END_CIRCLE_SIZE = 60;

const prepareData = (data: IStateDiagram) : IStateDiagramNode[] => {
    if(!data) return [];

    const states = data.states || [];
    const transitions = data.transitions || [];

    const map = new Map<string, IStateDiagramNode>(); // node_id --> node

    const endStates = new Set(data.finalStates);

    // Create all prepared nodes.
    for(const state of states) {

        const isStartState = state.id === data.initialState;
        const isEndState = endStates.has(state.id);

        const width = isEndState ? END_CIRCLE_SIZE : (isStartState ? START_CIRCLE_SIZE : NODE_WIDTH);
        const height = isEndState ? END_CIRCLE_SIZE : (isStartState ? START_CIRCLE_SIZE : NODE_HEIGHT);

        const node: IStateDiagramNode = {
            renderID: uuidv4(),
            id: state.id,
            label: isStartState ? '' : state.name,
            children: [],
            parent: null,
            x: 0,
            y: 0,
            width,
            height,
            arrowText: '',
            isStartState,
            isEndState,
        };

        map.set(state.id, node);
    }

    // Set appropriate children in the nodes.
    for(const transition of transitions) {
        const fromNode = map.get(transition.from);
        if(!fromNode) continue;

        const toNode = map.get(transition.to);
        if(!toNode) continue;

        const found = fromNode.children.find(child => child.id === toNode.id);
        if(found) continue;

        fromNode.children.push(toNode);
        toNode.parent = fromNode;
        toNode.arrowText = transition.event;
    }

    // Find root node
    let rootNode: IStateDiagramNode = null;

    for(const [_nodeId, node] of map) {
        if(!node.parent) {
            rootNode = node;
            break;
        }
    }

    // Use BFS to create node rows
    const result: IStateDiagramNode[] = [];
    const queue: IStateDiagramNode[] = [rootNode];

    // let level = 0;
    let x= 0;
    let y = NODE_HEIGHT + NODE_MARGIN_Y;

    while (queue.length > 0) {

        let levelSize = queue.length;

        while (levelSize > 0) {
            const node = queue.shift() as IStateDiagramNode;
            result.push(node);

            for(const child of node.children) {
                child.x = x;
                if(child.isEndState) {
                    child.x += (NODE_WIDTH - END_CIRCLE_SIZE) / 2;
                }

                child.y = y;
                x += NODE_WIDTH + NODE_MARGIN_X;
                queue.push(child);
            }

            levelSize--;
        }

        // console.log(level, temp);
        // level++;

        x = 0;
        y += NODE_HEIGHT + NODE_MARGIN_Y;
    }

    return result;
};

export const createStateDiagramXml = (data: IStateDiagram): string => {

    const preparedData = prepareData(data);
    if(!preparedData) return '';

    const initialXml = `
<mxfile host="draw.io">
  <diagram name="דיאגרמת מצבים">
    <mxGraphModel background="#FFFFFF">
      <root>
        <mxCell id="0"/>
        <mxCell id="1" parent="0"/>
        
        ${ preparedData.map(node => renderNode(node)).join('') }
        
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>`;

    return initialXml.trim();
};

const renderNode = (node: IStateDiagramNode): string => {

    const containerID = node.renderID;

    return (
        `
        
        ${
            node.isEndState ? 
                
                `
                <!-- end state: double circle -->
                <mxCell 
                     id="${ containerID }"  
                     value=""
                     style="group" 
                     vertex="1" 
                     connectable="0" 
                     parent="1">
                  <mxGeometry x="${ node.x }" y="${ node.y }" width="${ node.width }" height="${ node.height }" as="geometry"/>
                </mxCell>
                
                <mxCell 
                    id="${ uuidv4() }" 
                    value="" 
                    style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;" 
                    vertex="1" 
                    parent="${ containerID }">
                  <mxGeometry width="${ END_CIRCLE_SIZE }" height="${ END_CIRCLE_SIZE }" as="geometry" />
                </mxCell>
                
                <mxCell 
                    id="${ uuidv4() }" 
                    value="" 
                    style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;fillColor=#000000;" 
                    vertex="1" 
                    parent="${ containerID }">
                  <mxGeometry x="${ END_CIRCLE_SIZE/4 }" y="${ END_CIRCLE_SIZE/4 }" width="${ END_CIRCLE_SIZE/2 }" height="${ END_CIRCLE_SIZE/2 }" as="geometry" />
                </mxCell>
                ` : 
                
                `<mxCell 
                    id="${ containerID }" 
                    value="${ node.label }" 
                    style="${ node.isStartState ? 'ellipse;whiteSpace=wrap;html=1;aspect=fixed;fillColor=#000000;' : 'rounded=0;whiteSpace=wrap;html=1;fillColor=#ffe6cc;strokeColor=#d79b00;spacingLeft=15;spacingRight=15;' }" 
                    vertex="1" 
                    parent="1">
                  <mxGeometry x="${ node.x }" y="${ node.y }" width="${ node.width }" height="${ node.height }" as="geometry"/>
                </mxCell>
                `
        }
        
        ${
            node.parent ? `
            <mxCell 
                 id="${ containerID + '-connection-to-parent' }" 
                 style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=0.5;exitY=0;exitDx=0;exitDy=0;entryX=0.5;entryY=1;entryDx=0;entryDy=0;entryPerimeter=0;endArrow=none;endFill=0;startArrow=classic;" 
                 value="${ node.arrowText }"
                 parent="${ containerID }"  
                 target="${ node.parent.renderID }" 
                 source="${ containerID }"
                 edge="1">
              <mxGeometry relative="1" as="geometry"/>
            </mxCell>
            
            ` : ''
        }
        `
    );
};
