import {
    IContextDiagram,
    IContextDiagramData,
    IContextDiagramEntityNode, IContextDiagramInputOutput,
    IContextDiagramSystemNode
} from '../interfaces';
import { v4 as uuidv4 } from 'uuid';
import { inflateRaw } from 'pako';

const NODE_WIDTH = 130;
const NODE_HEIGHT = 50;
const NODE_PADDING_X = 25;
const NODE_PADDING_Y = 70;
const NODE_MARGIN_X = 200;
const SYSTEM_WIDTH = 200;

const prepareData = (data: IContextDiagramData) : IContextDiagram|null => {
    if(!data) return null;

    const result: IContextDiagram = {
        entityNodes: [],
        system: {
            id: uuidv4(),
            text: data.system,
            x: 0,
            y: 0,
            width: SYSTEM_WIDTH,
            height: 0,
        }
    };

    const x = 0;
    let y = NODE_HEIGHT + NODE_PADDING_Y;

    result.system.y = y;
    result.system.x = NODE_PADDING_X + x + NODE_WIDTH + NODE_MARGIN_X;

    const inputs: IContextDiagramInputOutput[] = [];
    const outputs: IContextDiagramInputOutput[] = [];

    for(const entity of data.entities) {
        const node = {
            renderID: entity.id,
            id: entity.id,
            name: entity.name,
            inputs: entity.inputs.map((text, i) => {

                const partHeight = 1 / entity.inputs.length;
                const start = i * partHeight;
                const y1 = start + partHeight/2;

                return {
                    text,
                    x1: 1,
                    y1,
                    x2: 0,
                    y2: 0.5,
                }
            }),

            outputs: entity.outputs.map((text, i) => {

                const partHeight = 1 / entity.outputs.length;
                const start = i * partHeight;
                const y2 = start + partHeight/2;

                return {
                    text,
                    x1: 1,
                    y1: 0.5,
                    x2: 0,
                    y2,
                }
            }),
            x1: NODE_PADDING_X + x,
            x2: NODE_PADDING_X + x + NODE_WIDTH + NODE_MARGIN_X * 2 + SYSTEM_WIDTH,
            y,
        };

        result.entityNodes.push(node);

        inputs.push(...node.inputs);
        outputs.push(...node.outputs);

        y += NODE_HEIGHT + NODE_PADDING_Y;
    }

    // update input endings and output starts ------------
    const partHeightInput = 1 / inputs.length;

    for(let i=0; i < inputs.length; i++) {
        const start = i * partHeightInput;
        inputs[i].y2 = start + partHeightInput / 2;
    }

    const partHeightOutput = 1 / outputs.length;

    for(let i=0; i < outputs.length; i++) {
        const start = i * partHeightOutput;
        outputs[i].y1 = start + partHeightOutput / 2;
    }

    result.system.height = (data.entities.length - 1) * NODE_PADDING_Y + (NODE_HEIGHT  * data.entities.length);

    return result;
};

export const createContextDiagramXml = (data: IContextDiagramData): string => {

    const preparedData = prepareData(data);
    if(!preparedData) return '';

    const initialXml = `
<mxfile host="draw.io">
  <diagram name="דיאגרמת תוכן">
    <mxGraphModel background="#FFFFFF">
      <root>
        <mxCell id="0"/>
        <mxCell id="1" parent="0"/>
        
        <!-- system -->
        <mxCell 
            id="${ preparedData.system.id }" 
            value="${ preparedData.system.text }" 
            style="rounded=1;whiteSpace=wrap;html=1;fillColor=#ffffff;fontStyle=1;fontSize=20;spacingLeft=25;spacingRight=25;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ preparedData.system.x }" 
              y="${ preparedData.system.y }" 
              width="${ preparedData.system.width }" 
              height="${ preparedData.system.height }" 
              as="geometry"
              />
        </mxCell>
        
         <!-- system number (0) -->
         <mxCell 
            id="${ preparedData.system.id + '-number' }" 
            value="0" 
            style="text;fontSize=14;fontStyle=1;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;overflow=hidden;rotatable=0;whiteSpace=wrap;html=1;" 
            parent="${ preparedData.system.id }"  
            vertex="1">
          <mxGeometry 
              width="${ preparedData.system.width }" 
              height="50" 
              as="geometry"
          />
        </mxCell>
        
        <!-- system divider -->
        <mxCell 
            id="${ preparedData.system.id + '-divider' }" 
            value="" 
            style="line;strokeWidth=1;fillColor=none;align=left;verticalAlign=middle;spacingTop=-1;spacingLeft=3;spacingRight=3;rotatable=0;strokeColor=inherit;" 
            parent="${ preparedData.system.id }"
            vertex="1">
          <mxGeometry  
                y="${ 50 }" 
                width="${ preparedData.system.width }"  
                height="8" 
                as="geometry" 
          />
        </mxCell>
        
        ${ preparedData?.entityNodes?.map(node => renderNode(node, preparedData.system)).join('') }
        
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>`;

    return initialXml.trim();
};

const renderNode = (node: IContextDiagramEntityNode, system: IContextDiagramSystemNode): string => {

    const nodeID1 = `${ node.renderID }-1`;
    const nodeID2 = `${ node.renderID }-2`;

    return (
        `
         <!-- left entity -->
         <mxCell 
            data-type="entity"
            id="${ nodeID1 }"
            value="${ node.name }" 
            style="rounded=0;whiteSpace=wrap;html=1;fillColor=#ffffff;fontStyle=1;fontSize=14;" 
            vertex="1" 
            parent="1">
          <mxGeometry x="${ node.x1 }" y="${ node.y }" width="${ NODE_WIDTH }" height="${ NODE_HEIGHT }" as="geometry"/>
        </mxCell>
        
        <!-- right entity -->
        <mxCell 
            id="${ nodeID2 }" 
            value="${ node.name }" 
            style="rounded=0;whiteSpace=wrap;html=1;fillColor=#ffffff;fontStyle=1;fontSize=14;" 
            vertex="1" 
            parent="1">
          <mxGeometry x="${ node.x2 }" y="${ node.y }" width="${ NODE_WIDTH }" height="${ NODE_HEIGHT }" as="geometry"/>
        </mxCell>
        
        ${
            node.inputs?.map((input) => {
              
                return (
                    `
                    <!-- input arrow -->
                    <mxCell 
                         data-type="input"
                         data-entity="${ node.name }"
                         id="${ uuidv4() }" 
                         style="jettySize=auto;html=1;exitX=${ input.x1 };exitY=${ input.y1 };exitDx=0;exitDy=0;entryX=${ input.x2 };entryY=${ input.y2 };entryDx=0;entryDy=0;endArrow=classic;endFill=1;
                         labelPosition=center;verticalLabelPosition=center;"
                         value="${ input.text }"
                         parent="${ nodeID1 }" 
                         target="${ system.id }" 
                         source="${ nodeID1 }"
                         edge="1">
                      <mxGeometry relative="1" as="geometry"/>
                    </mxCell>
                            `
                )
            }).join('')
        }
                
        ${
            node.outputs?.map(output => {
                 
                return (
                    `
                    <!-- output arrow -->
                    <mxCell 
                         id="${ uuidv4() }" 
                         data-type="output"
                         data-entity="${ node.name }"
                         style="jettySize=auto;html=1;exitX=${ output.x1 };exitY=${ output.y1 };exitDx=0;exitDy=0;entryX=${ output.x2 };entryY=${ output.y2 };entryDx=0;entryDy=0.5;endArrow=classic;endFill=1;labelPosition=center;verticalLabelPosition=center;" 
                         value="${ output.text }"
                         parent="${ nodeID2 }"  
                         source="${ system.id }" 
                         target="${ nodeID2 }"
                         edge="1">
                      <mxGeometry relative="1" as="geometry"/>
                    </mxCell>
                            `
                )
            }).join('')
        }
    `
    )
};

export const parseXml = (xml: string) : string => {
    try{

        const parser = new DOMParser();
        const xmlDoc = parser.parseFromString(xml, 'application/xml');

        const diagramElement = xmlDoc.querySelector('diagram');
        if (!diagramElement) return '';

        // Get the Base64-encoded content
        const encodedContent = diagramElement.textContent;
        if (!encodedContent) return '';

        const decodedData = atob(encodedContent);
        const byteArray = new Uint8Array(decodedData.length);
        for (let i = 0; i < decodedData.length; i++) {
            byteArray[i] = decodedData.charCodeAt(i);
        }

        const decompressedData = decodeURIComponent(inflateRaw(byteArray, { to: 'string' }));
        // console.log('decompressedData', decompressedData)
        const mainXmlDoc = parser.parseFromString(decompressedData, 'application/xml');

        const entities = [...mainXmlDoc.querySelectorAll('mxCell[data-type="entity"]')];

        let result = '';

        result += 'ישויות: ';
        result += entities?.map(entity => entity.getAttribute('value')).join(', ');
        result += '\n';

        const inputs = [...mainXmlDoc.querySelectorAll('mxCell[data-type="input"]')];
        const outputs = [...mainXmlDoc.querySelectorAll('mxCell[data-type="output"]')];

        for(const input of inputs) {
            result += 'קלט עבור ישות ';
            result += input.getAttribute('data-entity');
            result += ': ';
            result += input.getAttribute('value');
            result += '\n';
        }

        for(const output of outputs) {
            result += 'פלט עבור ישות ';
            result += output.getAttribute('data-entity');
            result += ': ';
            result += output.getAttribute('value');
            result += '\n';
        }

        return result;
    }
    catch(ex) {
        console.error(ex);
    }

    return '';
};
