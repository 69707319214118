import { ChangeEvent, useEffect, useState } from 'react';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { useFinanceStore } from '../../../data/finance-store';
import { insertGasItem, updateGasItem } from '../../../data/finance-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { Car, IFinanceGasItem } from '../../../finance-interfaces';
import { getDaysOptions, getMonthsOptions, getYearsOptions } from '../../../../../common/domain/date-time-provider';
import CarsDropDown from '../cars-drop-down';
import { GasTab } from '../tabs-navigation';

interface IUpsertGasItem {
    isNew: boolean;
    gas?: IFinanceGasItem;
    close?: () => void;
}

const UpsertGasItem = ({ isNew, gas, close }: IUpsertGasItem) => {

    const setGasData = useFinanceStore(store => store.setGasData);
    const setGasTab = useFinanceStore(store => store.setGasTab);
    const gasYear = useFinanceStore(store => store.gasYear);
    const gasFilterCar = useFinanceStore(store => store.gasFilterCar);

    const [carID, setCarID] = useState<Car>(Car.NotSelected);
    const [carIDTouched, setCarIDTouched] = useState(false);
    const isCarIdValid = !carIDTouched || carID > 0;

    const [day, setDay] = useState(new Date().getDate());
    const [month, setMonth] = useState(new Date().getMonth());

    const [year, setYear] = useState(new Date().getFullYear());
    const [yearTouched, setYearTouched] = useState(false);
    const isYearValid = !yearTouched || year > 0;

    const [amount, setAmount] = useState('');
    const [amountTouched, setAmountTouched] = useState(false);
    const isAmountValid = !amountTouched || !isNaN(Number(amount));

    const [km, setKm] = useState('');
    const [kmTouched, setKmTouched] = useState(false);
    const isKmValid = !kmTouched || !isNaN(Number(km));

    const [desc, setDesc] = useState('');
    const [gasStationName, setGasStationName] = useState('');
    const [isKmCumulative, setIsKmCumulative] = useState(true);

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        if(isNew) return;

        setCarID(gas.GasCarID);
        setCarIDTouched(false);

        setDay(gas.GasDay);
        setMonth(gas.GasMonth);
        setYear(gas.GasYear);
        setYearTouched(false);

        setAmount(gas.GasAmount.toString());
        setAmountTouched(false);

        setKm(gas.GasKM.toString());
        setKmTouched(false);

        setDesc(gas.GasDescription);
        setGasStationName(gas.GasBusinessName);
    }, [gas, isNew]);

    const save = async () => {

        if (carID <= 0) {
            setCarIDTouched(true);
            return;
        }

        if (year <= 0) {
            setYearTouched(true);
            return;
        }

        const _amount = Number(amount);
        if(isNaN(_amount)) {
            setAmountTouched(true);
            return;
        }

        const _km = Number(km) ?? 0;
        if(isNaN(_km)) {
            setKmTouched(true);
            return;
        }

        setLoading(true);

        const response = isNew ?
            await insertGasItem(
                carID,
                gasStationName,
                day,
                month,
                year,
                _amount,
                _km,
                desc,
                gasYear,
                gasFilterCar,
                isKmCumulative,
            )
            :
            await updateGasItem(
                gas.GasID,
                carID,
                gasStationName,
                day,
                month,
                year,
                _amount,
                _km,
                desc,
                gasYear,
                gasFilterCar,
                isKmCumulative,
            );

        setLoading(false);

        if (!response) {
            showToast('Insert gas item error.');
            return;
        }

        setGasData(response);

        if(isNew) {
            setGasTab(GasTab.Default);
        }
        else{
            close();
        }
    };

    const onGasStationChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setGasStationName(evt.target.value);
    };

    const onIsKmCumulativeChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setIsKmCumulative(evt.target.checked);
    };

    const onKmChange= (evt: ChangeEvent<HTMLInputElement>) => {
        setKm(evt.target.value);
        setKmTouched(true);
    };

    const onAmountChange= (evt: ChangeEvent<HTMLInputElement>) => {
        setAmount(evt.target.value);
        setAmountTouched(true);
    };

    const onDescChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setDesc(evt.target.value);
    };

    const onCarChange = (newCar: Car) => {
        setCarID(newCar);
        setCarIDTouched(true);
    };

    const onDayChange= (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) || 0;
        setDay(value);
    };

    /**
     * Months are 0-indexed in JavaScript Date.
     */
    const onMonthChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) || 0;
        setMonth(value);
    };

    const onYearChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) || 0;
        setYear(value);
    };

    return (
        <div>

            <div className="grid md:grid-cols-3 gap-4">
                <label className="flex flex-col mb-4">
                    <span className="font-bold text-slate-400 mb-2">Day</span>
                    <select
                        className="rounded bg-white border px-4 py-2 mr-4 mb-2 lg:mb-0"
                        value={ day }
                        onChange={ onDayChange }>
                        { getDaysOptions() }
                    </select>
                </label>

                <label className="flex flex-col mb-4">
                    <span className="font-bold text-slate-400 mb-2">Month</span>
                    <select
                        className="rounded bg-white border px-4 py-2 mr-4 mb-2 lg:mb-0"
                        value={ month }
                        onChange={ onMonthChange }>
                        { getMonthsOptions() }
                    </select>
                </label>

                <label className="flex flex-col mb-4">
                    <span className="font-bold text-slate-400 mb-2">Year</span>
                    <select
                        className={ `rounded bg-white border px-4 py-2 mr-4 mb-2 lg:mb-0 ${ isYearValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                        value={ year }
                        onChange={ onYearChange }>
                        { getYearsOptions() }
                    </select>

                    { !isYearValid && <div className="text-red-700 text-xs mt-1">The year is required.</div> }
                </label>

                <label className="flex flex-col mb-4">
                    <span className="font-bold text-slate-400 mb-2">Car</span>

                    <CarsDropDown
                        car={ carID }
                        onSelect={ onCarChange }
                        classes={ `${ isCarIdValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    />
                    { !isCarIdValid && <div className="text-red-700 text-xs mt-1">The car is required.</div> }
                </label>

                <label className="flex flex-col mb-4">
                    <span className="font-bold text-slate-400 mb-2">Amount, ₪</span>
                    <input
                        value={ amount }
                        onChange={ onAmountChange }
                        className={ `border rounded px-4 py-1` }
                        type="text"
                    />
                    { !isAmountValid && <div className="text-red-700 text-xs mt-1">The amount should be a number.</div> }
                </label>

                <label className="flex flex-col mb-4">
                    <span className="font-bold text-slate-400 mb-2">Km</span>
                    <input
                        value={ km }
                        onChange={ onKmChange }
                        className={ `border rounded px-4 py-1` }
                        type="text"
                    />
                    { !isKmValid && <div className="text-red-700 text-xs mt-1">The KM  should be a number.</div> }
                </label>
            </div>

            <label className="flex items-center justify-end mb-4">
                <span className="font-bold text-slate-400 mr-4">Is KM cumulative?</span>
                <input
                    checked={ isKmCumulative }
                    onChange={ onIsKmCumulativeChange }
                    type="checkbox"
                />
            </label>

            <label className="flex flex-col mb-4">
                <span className="font-bold text-slate-400 mb-2">Gas Station Name</span>
                <input
                    value={ gasStationName }
                    onChange={ onGasStationChange }
                    className={ `border rounded px-4 py-2` }
                    type="text"
                />
            </label>

            <label className="flex flex-col mb-4">
                <span className="font-bold text-slate-400 mb-2">Description</span>
                <textarea
                    value={ desc }
                    onChange={ onDescChange }
                    className="border rounded px-4 py-2"
                    rows={ 4 }
                />
            </label>

            <div className="flex items-center justify-end text-sm mt-4">
                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' }/>
                }

                {
                    !isLoading &&
                    <button
                        className="bg-slate-500 text-slate-100 rounded px-6 py-2"
                        onClick={ save } type="button">
                        Save
                    </button>
                }
            </div>
        </div>
    );
};

export default UpsertGasItem;