import { ISequenceDiagram } from './interfaces';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DrawIoEmbed, DrawIoEmbedRef, EventExport } from 'react-drawio';
import { createSequenceDiagramXml } from './domain/sequence-diagram-provider';
import { copyImageToClipboard, downloadImage } from '../../../../../common/domain/io-provider';
import { getSequenceDiagramFromStorage, setSequenceDiagramToStorage } from './domain/sequence-diagram-storage';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: ISequenceDiagram = {
        "actor": {
            "id": "actor1",
            "name": "Rental Clerk"
        },
        "classes": [
            {
                "id": "obj1",
                "name": "Reservation Window"
            },
            {
                "id": "obj2",
                "name": "Reservation"
            },
            {
                "id": "obj3",
                "name": "Customer"
            },
            {
                "id": "obj4",
                "name": "Car"
            }
        ],
        "messages": [
            {
                "from": "actor1",
                "to": "obj1",
                "label": "Input Reservation Details"
            },
            {
                "from": "obj1",
                "to": "obj2",
                "label": "Find Reservation Details"
            },
            {
                "from": "obj1",
                "to": "obj3",
                "label": "Find Customer Details"
            },
            {
                "from": "obj1",
                "to": "obj4",
                "label": "Find Car's Details"
            },
            {
                "from": "actor1",
                "to": "obj1",
                "label": "Update Customer's Details"
            },
            {
                "from": "obj1",
                "to": "obj3",
                "label": "Save Customer Details"
            },
            {
                "from": "actor1",
                "to": "obj1",
                "label": "Confirm Reservation"
            },
            {
                "from": "obj1",
                "to": "obj2",
                "label": "Save Reservation"
            },
            {
                "from": "obj1",
                "to": "obj4",
                "label": "Update Car Status"
            }
        ]
    }
;

const SequenceDiagramHome = () => {

    const drawIoRef = useRef<DrawIoEmbedRef>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<ISequenceDiagram>(initialData);

    useEffect(() => {
        if(!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onDiagramSave = async (data: EventExport) => {
        downloadImage(data.data, 'sequence-diagram.png');
        await copyImageToClipboard(data.data);
    };

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    useEffect(() => {
        const storageData = getSequenceDiagramFromStorage();
        if(!storageData) return;

        if(textAreaRef.current) {
            textAreaRef.current.value = storageData;
        }

        let parsed: ISequenceDiagram|null = null;

        try{
            parsed = JSON.parse(storageData) as ISequenceDiagram;
        }
        catch (error) {
            console.log(error);
        }

        if(!parsed) return;

        setData(parsed);
    }, []);

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setSequenceDiagramToStorage(evt.target.value);
    };

    return(
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">דיאגרמת רצף</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    className="border rounded w-full p-2"
                    onChange={ onChange }
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={ {
                         height: UI_HEIGHT,
                     } }>
                    <DrawIoEmbed
                        ref={ drawIoRef }
                        urlParameters={ {
                            // ui: 'kennedy',

                            // Shows a 'Loading…' spinner while waiting
                            // for the diagram data to load in embed mode.
                            spin: true,

                            // Specifies whether libraries should be enabled in embed mode.
                            // The default is disabled.
                            // libraries: true,


                            noExitBtn: true,
                        } }
                        exportFormat={ 'png' }
                        onExport={ onDiagramSave }
                        xml={ createSequenceDiagramXml(data) }
                    />
                </div>
            </div>

        </div>
    )
};

export default SequenceDiagramHome;
