import { TagIcon } from '../../../../common/ui/icons';
import Preloader from '../../../../common/ui/preloader';
import MobileMenuBtn from '../../../../menu/ui/mobile-menu-btn';
import Menu from '../../../../menu/menu';
import ProjectMenu from '../projects/project-menu';
import { MouseEvent, useEffect } from 'react';
import { useAuthStatus } from '../../../auth/hooks/auth-status-hook';
import TagsList from './tags-list';
import { ListType } from '../../planner-interfaces';
import Actions from '../../../../common/ui/actions';
import CloseAllTagsBtn from './actions/close-all-tags-btn';
import HideFutureTasks from './actions/hide-future-tasks';
import { usePlannerStore } from '../../data/planner-store';

const TagsView = () => {

    const { loading } = useAuthStatus();

    const setListType = usePlannerStore(state => state.setListType);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    useEffect(() => {
        setListType(ListType.TagsView);
    }, [setListType]);

    useEffect(() => {
        document.title = 'Tags | Productivity';
    }, []);

    return (
        <div className="text-slate-800">
            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu />
                        <ProjectMenu />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">
                        <div className="overflow-auto px-6 py-10 flex-1">

                            <div className="xl:w-[800px] max-w-full mx-auto">

                                <div className="mb-4 w-full flex flex-col">

                                    <h1 className="text-xl xl:text-3xl border-b border-teal-400 pb-2 flex items-center">
                                        <TagIcon classes="mr-4" size={ 35 } color={ '#2E766E' } />
                                        Tags

                                        <Actions classes="tags-view__actions">
                                            <CloseAllTagsBtn />
                                        </Actions>
                                    </h1>

                                    {
                                        /**
                                         * Optional project description here.
                                         */
                                    }
                                </div>

                                <div className="flex justify-end items-center mb-4">
                                    <HideFutureTasks />
                                </div>

                                <TagsList />
                            </div>

                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default TagsView;