import { ChangeEvent, useState } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import { PreloaderIcon, UploadIcon } from '../../../../common/ui/icons';
import { uploadPlantGalleryImage } from '../../data/flowers-data-service';
import { useFlowersStore } from '../../data/flowers-store';

const UploadImage = () => {

    const [loading, setLoading] = useState(false);

    const setPlantsGalleryData = useFlowersStore(store => store.setPlantsGalleryData);

    const uploadHandler = async (evt: ChangeEvent<HTMLInputElement>) => {
        if(!evt.target.files || evt.target.files.length <= 0){
            showToast('Upload gallery item error.');
            return;
        }

        setLoading(true);

        for(const file of evt.target.files) {
            const formData = new FormData();
            formData.append('file', file);
            const response = await uploadPlantGalleryImage(formData as FormData);

            if(!response) {
                showToast('Upload plant gallery image error.');
                setLoading(false);
                return;
            }

            setPlantsGalleryData(response || []);
        }

        setLoading(false);
        showToast('Upload OK.', false);
    };

    return (
        <div className="flex justify-center shadow rounded mb-6" style={{
            minHeight: '200px',
        }}>
            {
                loading &&
                <div className="m-5 bg-slate-100 hover:bg-slate-200 flex-1 flex items-center justify-center">
                    <PreloaderIcon size={ 24 } color={ '#603c86' } />
                </div>
            }

            {
                !loading &&
                <label className="m-5 bg-slate-100 hover:bg-slate-200 flex-1 flex items-center justify-center cursor-pointer">
                    <input
                        onChange={ uploadHandler }
                        className="hidden"
                        type="file"
                        multiple={ true }
                    />
                    <UploadIcon
                        size={ 30 }
                        color={ '#1E293B' }
                    />
                </label>
            }
        </div>
    )
};

export default UploadImage;