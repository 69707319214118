import { DecisionTreeNode, NODE_HEIGHT, NODE_WIDTH } from './decision-tree-node';

export class DecisionTreeEdge {
    edgeTargetNode: DecisionTreeNode;
    edgeLabel: string;

    constructor(edgeLabel: string, edgeTargetNode: DecisionTreeNode) {
        this.edgeLabel = edgeLabel;
        this.edgeTargetNode = edgeTargetNode;
    }

    public draw(edgeSourceNode: DecisionTreeNode) {
        return (
            <g key={ `line-${ edgeSourceNode.id }-${ this.edgeTargetNode.id }` }>
                <line
                    x1={ edgeSourceNode.x + NODE_WIDTH / 2 }
                    y1={ edgeSourceNode.y + NODE_HEIGHT }
                    x2={ this.edgeTargetNode.x + NODE_WIDTH / 2 }
                    y2={ this.edgeTargetNode.y }
                    stroke="#555"
                />
                <text
                    x={ (edgeSourceNode.x + NODE_WIDTH / 2 + this.edgeTargetNode.x + NODE_WIDTH / 2) / 2 }
                    y={ (edgeSourceNode.y + NODE_HEIGHT + this.edgeTargetNode.y) / 2 - 5 }
                    textAnchor="middle"
                    fontSize={ 12 }
                >
                    { this.edgeLabel }
                </text>
            </g>
        )
    }
}