import { addMonths } from 'date-fns';
import { ArrowLeftIcon } from '../../../../../../common/ui/icons';
import { useFinanceStore } from '../../../../data/finance-store';
import { setTransactionsMonthToStorage, setTransactionsYearToStorage } from '../../../../domain/finance-storage';

const PrevMonthBtn = () => {

    const transactionsSelectedYear = useFinanceStore(store => store.transactionsSelectedYear);
    const transactionsSelectedMonth = useFinanceStore(store => store.transactionsSelectedMonth);

    const setTransactionsSelectedMonth = useFinanceStore(store => store.setTransactionsSelectedMonth);
    const setTransactionsSelectedYear = useFinanceStore(store => store.setTransactionsSelectedYear);

    const jumpToPrevMonth = () => {
        const currentDate = new Date(transactionsSelectedYear, transactionsSelectedMonth, 1);
        const prevMonthDate = addMonths(currentDate, -1);

        const prevMonth = prevMonthDate.getMonth();
        const year = prevMonthDate.getFullYear();

        setTransactionsSelectedMonth(prevMonth);
        setTransactionsMonthToStorage(prevMonth);

        setTransactionsSelectedYear(year);
        setTransactionsYearToStorage(year);
    };

    return (
        <button
            onClick={ jumpToPrevMonth }
            type="button">
            <ArrowLeftIcon />
        </button>
    )
};

export default PrevMonthBtn;