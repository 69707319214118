import { useEffect } from 'react';
import WaterLogsList from './list/water-logs-list';
import DatesPanel from './dates-panel';
import { format } from 'date-fns';
import { useFlowersStore } from '../../data/flowers-store';

const WaterLogsHome = () => {

    const waterLogsData = useFlowersStore(store => store.waterLogsData);
    const waterLogsDate = useFlowersStore(store => store.waterLogsDate);

    useEffect(() => {
        document.title = `Water Logs | Flowers`;
    }, []);

    return (
        <div>
            <div className="flex justify-between items-end md:items-center mb-6">
                <h1 className="flex flex-col md:flex-row md:items-center text-xl xl:text-3xl">
                    <div>Water Logs</div>

                    <div className="text-sm md:mx-6 mt-2 md:mt-0">
                        { format(waterLogsDate, 'dd MMM, yyyy') } ({ format(waterLogsDate, 'EEE') })
                    </div>

                    <div className="text-sm md:mt-0">
                        ({ waterLogsData?.plants?.length ?? 0 } items)
                    </div>
                </h1>

                <DatesPanel />
            </div>

            <WaterLogsList/>
        </div>
    )
};

export default WaterLogsHome;