import { DecisionTreeTableColumn, DecisionTreeTableColumnType } from './decision-tree-table-column';

export class DecisionTreeTable {

    columns: DecisionTreeTableColumn[];

    constructor(csv: string|null) {
        if(csv === null) return;

        const _text = csv.trim().replaceAll('', 'נ');
        const rows = _text.split('\n');

        if(rows.length <= 1) {
            throw new Error('The CSV has no rows.');
        }

        this.columns = this.initColumns(rows);

        if(!this.isValid()) {
            throw new Error('All columns should be the same size.');
        }
    }

    // ------------- PUBLIC ---------------------

    public getColumnByIndex(index: number): DecisionTreeTableColumn|null {
        return this.columns[index] || null;
    }

    public getColumnByHeader(header: string): DecisionTreeTableColumn|null {
        return this.columns.find(column => column.header === header) || null;
    }

    public getLabelColumn() {
        return this.columns.find(column => column.type === DecisionTreeTableColumnType.LABEL) || null;
    }

    public getFeatureColumns() {
        return this.columns.filter(column => column.type === DecisionTreeTableColumnType.DEFAULT) || [];
    }

    public getTableWidth() {
        return this.columns.length;
    }

    public getTableHeightWithHeader() {
        if(this.columns.length <= 1) {
            throw new Error('Columns count = 0.');
        }

        return this.columns[0].getValuesCount() + 1;
    }

    public getTableHeightWithoutHeader() {
        if(this.columns.length <= 1) {
            throw new Error('Columns count = 0.');
        }
        return this.columns[0].getValuesCount();
    }

    public getTableHeader(): string[] {
        return this.columns.map((column: DecisionTreeTableColumn) => column.header) || [];
    }

    public clone(): DecisionTreeTable {
        const cloned = new DecisionTreeTable(null);
        cloned.columns = [];

        for(const column of this.columns) {
            cloned.columns.push(column.clone());
        }

        return cloned;
    }

    public getRowByIndex(index: number): string[] {
        const height = this.getTableHeightWithoutHeader();
        if(index >= height) {
            throw new Error('The index is > than table height.');
        }

        const values: string[] = [];

        for(const column of this.columns) {
            values.push(column.getValueByIndex(index));
        }

        return values;
    }

    public deleteColumnByIndex(index: number) {
        this.columns.splice(index, 1);
    }

    public deleteColumnByHeader(header: string) {
        const index = this.columns.findIndex(column => column.header === header);
        if(index === -1) {
            throw new Error('The index is missing.');
        }

        this.deleteColumnByIndex(index);
    }

    public deleteIndexColumns() {
        for(let c=this.columns.length-1; c>=0; c--) {
            const column = this.columns[c];
            if(column.type === DecisionTreeTableColumnType.INDEX) {
                this.deleteColumnByIndex(c);
            }
        }
    }

    public deleteRowByIndex(index: number) {

        const height = this.getTableHeightWithoutHeader();
        if(index >= height) {
            throw new Error('The index is > than table height.');
        }

        for(const column of this.columns) {
            column.deleteValuesByIndex(index);
        }
    }

    public getColumnWithMinEntropy(): { colWithMinEntropy: DecisionTreeTableColumn, minEntropy: number }|null {
        const columns = this.getFeatureColumns();
        if(columns.length <= 0)  return null;

        let minEntropy = Infinity;
        let minCol: DecisionTreeTableColumn|null = null;

        for(const column of columns) {
            const entropy = column.getEntropy(this.getLabelColumn());
            if(entropy < minEntropy) {
                minEntropy = entropy;
                minCol = column;
            }
        }

        return {
            colWithMinEntropy: minCol,
            minEntropy,
        };
    }

    /**
     * - received a feature column and a value in this column
     * - clones the table
     * - it finds all row indices of the value in the feature column (it might repeat)
     * - in the cloned table it removes all rows (in each columns including label column) with the indices not equal to the previously found indices (i.e. it removes rows that don't contain the provided value)
     * - then it deletes the provided feature column
     * - then it validates the result table (all columns should have the same number of values)
     */
    public getFilteredTableByFeatureValue(featureColumn: DecisionTreeTableColumn, value: string): DecisionTreeTable {

        // Get all row indices where value doesn't match
        const indicesToDelete: number[] = [];
        const values = featureColumn.getValues();

        for (let i = 0; i < values.length; i++) {
            if (values[i] !== value) {
                indicesToDelete.push(i);
            }
        }

        // Clone the table ----------
        const filtered = this.clone();

        for(let i=indicesToDelete.length - 1; i>=0; i--) {
            const index = indicesToDelete[i];
            filtered.deleteRowByIndex(index);
        }

        // Delete the feature column
        filtered.deleteColumnByHeader(featureColumn.header);

        // Validate --------------
        if (!filtered.isValid()) {
            throw new Error('Filtered table is invalid (columns have mismatched value lengths).');
        }

        return filtered;
    }

    /**
     * Check if in the label column, all values are the same.
     */
    public isLabelValuesTheSame() {
        const set = new Set(this.getLabelColumn().getValues());
        return set.size <= 1;
    }

    public areAllRowsIdentical() {
        const defaultColumns = this.getFeatureColumns();
        const numRows = this.getTableHeightWithoutHeader();
        if (numRows === 0 || defaultColumns.length === 0) return true;

        const set = new Set<String>();

        for(let ri=0; ri<numRows; ri++) {
            const values: string[] = [];
            for (const col of defaultColumns) {
                values.push(col.getValueByIndex(ri));
            }
            set.add(values.join('-'));
        }

        return set.size === 1;
    }

    // ------------- DRAW --------------------

    /**
     * Draw the whole table with columns/rows.
     */
    public draw() {
        return (
            <div className="grid gap-1"
                 style={ {
                     gridTemplateRows: `repeat(${ this.getTableHeightWithHeader() + 1 }, 1fr)`,
                     gridAutoFlow: `column`,
                 } }>
                { this.columns.map(column => column.draw()) }
            </div>
        );
    }

    /**
     * Draw the calculation and formulas.
     */
    public drawProbabilities() {
        let colWithMinEntropyHeader = '';
        let colWithMinEntropyValue = Infinity;

        return (
            <div dir="ltr">
                {
                    this.getFeatureColumns().map(column => {

                        const entropy = column.getEntropy(this.getLabelColumn());
                        if(entropy < colWithMinEntropyValue) {
                            colWithMinEntropyValue = entropy;
                            colWithMinEntropyHeader = column.header;
                        }

                        return (
                            <div key={ `col-prob-${ column.id }` } className="mb-4">
                                <div className="text-xl mb-1 underline" dir="rtl">{ column.header }</div>
                                { column.drawProbabilities(this.getLabelColumn()) }

                                <div className="flex flex-col my-2">
                                    <p className="mr-1 underline font-bold">אנטרופיה:</p>
                                    <div className="whitespace-nowrap flex">
                                        { column.drawEntropyCalculations(this.getLabelColumn()) }
                                        <span>=</span>
                                        <span>{ entropy }</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

                <div className="flex font-bold bg-slate-200 p-4">העמודה עם האנטרופיה המינימלית היא: { colWithMinEntropyHeader } = { colWithMinEntropyValue }</div>
            </div>
        )
    }

    // ------------- PRIVATE --------------------

    private isValid(): boolean {
        const size = this.columns[0].getValuesCount();

        for (let c = 1; c < this.columns.length; c++) {
            if (this.columns[c].getValuesCount() !== size) return false;
        }

        return true;
    }

    private initColumns(rows: string[]): DecisionTreeTableColumn[] {

        const table: string[][] = [];

        for (let r = 0; r < rows.length; r++) {
            table.push(rows[r].split(',').map(cell => cell.trim()));
        }

        const columnsNumber = table[0]?.length ?? 0;
        if (columnsNumber <= 0) {
            throw new Error('Columns count = 0.');
        }

        const columns: DecisionTreeTableColumn[] = [];
        for (let i = 0; i < columnsNumber; i++) {
            let type: DecisionTreeTableColumnType = DecisionTreeTableColumnType.DEFAULT;
            if (i === 0) {
                type = DecisionTreeTableColumnType.LABEL;
            } else {
                if (i === columnsNumber - 1) {
                    type = DecisionTreeTableColumnType.INDEX;
                }
            }

            columns.push(new DecisionTreeTableColumn(type));
        }

        for (let r = 0; r < table.length; r++) {
            const row = table[r];
            for (let c = 0; c < row.length; c++) {
                if (!columns[c]) {
                    throw new Error('All columns should be the same size.');
                }
                const value = row[c];

                if (r === 0) {
                    // header ---------------
                    columns[c].header = value;
                } else {
                    // value ----------------
                    columns[c].addValue(value);
                }
            }
        }

        // Handle missing or wrong values,
        // and apply discretization if needed.
        for(const column of columns) {
            if(column.type !== DecisionTreeTableColumnType.DEFAULT) continue;

            const resNumeric = column.checkMissingOrWrongNumericValue();
            if(resNumeric.hasWrongOrMissingValue){
                column.setValueByIndex(resNumeric.index, resNumeric.average.toString(), resNumeric.wrongValue);
            }
            else{
                const wrongValues = column.checkMissingOrWrongTextValues();
                if(wrongValues.length > 0) {
                    const mostFrequentValue = column.getMostFrequentValue();
                    for(const { index, wrongValue } of wrongValues) {
                        column.setValueByIndex(index, mostFrequentValue, wrongValue);
                    }
                }
            }

            if(column.shouldApplyDiscretization()) {
                if(column.areAllValuesNumbers()) {
                    column.applyDiscretization();
                }
            }
        }

        return columns;
    }
}