import { useEffect } from 'react';
import GasTabsNavigation, { GasTab } from './tabs-navigation';
import { getFinanceGasData } from '../../data/finance-data-service';
import { showToast } from '../../../../common/domain/toast-provider';
import { useFinanceStore } from '../../data/finance-store';
import GasList from './list/gas-list';
import UpsertGasItem from './actions/upsert-gas-item';

const GasHome = () => {

    const setGasData = useFinanceStore(store => store.setGasData);
    const gasTab = useFinanceStore(store => store.gasTab);
    const gasYear = useFinanceStore(store => store.gasYear);
    const gasFilterCar = useFinanceStore(store => store.gasFilterCar);

    useEffect(() => {
        document.title = `Бензин | Finance`;
    }, []);

    useEffect(() => {
        (async () => {
            const response = await getFinanceGasData(gasYear, gasFilterCar);

            if(!response) {
                showToast('Get gas data error.');
                return;
            }

            setGasData(response);
        })();
    }, [
        setGasData,
        gasYear,
        gasFilterCar,
    ]);

    return (
        <div>
            <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                Бензин
            </h1>

            <GasTabsNavigation />

            {
                gasTab === GasTab.Default &&
                <GasList />
            }

            {
                gasTab === GasTab.AddNew &&
                <UpsertGasItem
                    isNew={ true }
                />
            }

        </div>
    )
};

export default GasHome;