import { useState, MouseEvent } from 'react'; // import { Suspense, lazy } from 'react';
import { ITodo, ListType, TodoType } from '../../planner-interfaces';
import {
    ArrowDownIcon,
    ArrowRightIcon,
    ClockIcon,
} from '../../../../common/ui/icons';
import { closeTodo, openTodo } from '../../domain/planner-storage';
import TextBox from '../../../../common/ui/forms/text-box';
import { updateTodoTitle } from '../../data/planner-data-service';
import AddNestedTodoButton from './actions/todo-add-nested-btn';
import DeleteTodoButton from './actions/todo-delete-btn';
import CompleteCheckbox from './actions/todo-complete-check';
import DragTodoButton from './drag/drag-todo-btn';
import TodoDate from './todo-date';
import TodoEditButton from './actions/todo-edit-btn';
import { showToast } from '../../../../common/domain/toast-provider';
import Actions from '../../../../common/ui/actions';
import ITodoUploadAttachmentButtonProps from './attachment/todo-attachment-upload';
import TodoMetaData from './todo-metadata';
import { ADD_NEW_TODO_DESCRIPTION_EVENT, } from './description/todo-description';
import TodoTags from './todo-tags';
import { getTodoFromCombinedProject } from '../../domain/planner-provider';
import PredictNextEventBtn from './actions/predict-next-event-btn';
import DiagramBtn from './actions/diagram-btn';
import TodoIdealFinalResult from './todo-ideal-final-result';
import DescartesSquareBtn from './actions/descartes-square-btn';
import TodoBlocks from './blocks/todo-blocks';
import TodoEditBlocksButton from './blocks/todo-edit-blocks-btn';
import TrackYesterdayHabitBtn from '../habit-tracker/actions/track-yesterday-habit-btn';
import DuplicateTodoButton from './actions/todo-duplicate-btn';
import { markdown2Html } from '../../../../common/markdown/markdown-provider';
import PrintTodoBtn from './actions/todo-print-btn';
import AddTodoToGoogleCalendarBtn from './actions/todo-add-to-google-calendar-btn';
import { NavLink } from 'react-router-dom';
import { usePlannerStore } from '../../data/planner-store';
import TodoSplitBtn from './actions/todo-split-btn';
import OpenTodoLink from './actions/open-todo-link';
import OpenTodoTitleChartBtn from './actions/open-todo-title-chart-btn';
import MoveMultipleToTodoBtn from './actions/move-multiple-to-todo-btn';

export const TOGGLE_TODO_EVENT = 'TOGGLE_TODO_EVENT';

enum TodoMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface ITodoTitleProps {
    todo: ITodo;
    startAddingNewTodo: () => void;
    showTodoLink?: boolean;
}

const TodoTitle = (props: ITodoTitleProps) => {

    const { todo, startAddingNewTodo } = props;

    const combinedProject = usePlannerStore(state => state.combinedProject);
    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);
    const listType = usePlannerStore(state => state.listType);
    const multipleSelectTodos = usePlannerStore(state => state.multipleSelectTodos);
    const setMultipleSelectTodos = usePlannerStore(state => state.setMultipleSelectTodos);

    const [mode, setMode] = useState<TodoMode>(TodoMode.DEFAULT);
    const [updateTitleLoading, setUpdateTitleLoading] = useState(false);

    const isSelected = multipleSelectTodos.includes(todo.todo_id);

    const startEdit = (evt: MouseEvent<HTMLButtonElement>) => {
        if(evt.ctrlKey || evt.metaKey) {
            // Handle multiple selected ------------------------------------

            evt.stopPropagation();

            const index = multipleSelectTodos.indexOf(todo.todo_id);
            const copy = [...multipleSelectTodos];
            if(index === -1) {
                copy.push(todo.todo_id);
            }
            else{
                copy.splice(index, 1);
            }
            setMultipleSelectTodos(copy);
            return;
        }

        setMode(TodoMode.EDIT);
    };

    const cancelEdit = () => {
        setMode(TodoMode.DEFAULT);
    };

    const save = async (updatedText: string) => {

        setUpdateTitleLoading(true);
        const response = await updateTodoTitle(todo.todo_id, updatedText.trim(), todo.todo_type);
        setUpdateTitleLoading(false);

        if(!response?.isValid) {
            showToast('Update task title error.');
            return;
        }

        cancelEdit();

        try{
            todo.todo_title = updatedText.trim();
            const copy = JSON.parse(JSON.stringify(combinedProject));
            setCombinedProject(copy);
        }
        catch (ex) {
            console.log(ex);
        }
    };

    const toggleTodo = () => {
        const isOpened = todo.isOpened;

        if(isOpened) {
            closeTodo(todo.todo_id);
        }
        else{
            openTodo(todo.todo_id);
        }

        try{
            const _todo = getTodoFromCombinedProject(todo.todo_id, combinedProject);
            if(!_todo){
                document.dispatchEvent(new CustomEvent(TOGGLE_TODO_EVENT));
                return;
            }

            _todo.isOpened = !isOpened;
            const copy = JSON.parse(JSON.stringify(combinedProject));
            setCombinedProject(copy);
        }
        catch (ex) {
            console.log(ex);
        }
    };

    const startEditDescription = () => {
        document.dispatchEvent(new CustomEvent(ADD_NEW_TODO_DESCRIPTION_EVENT, {
            detail: todo.todo_id,
        }));
    };

    // const SentimentAnalysisBtnLazy = lazy(() => import('./actions/sentiment-analysis-btn'));

    return (
        <div
            className={ `todo__title text-sm flex items-center py-1 pl-2 ${ mode === TodoMode.EDIT ? 'flex-1' : '' } ${ isSelected ? 'bg-sky-100' : '' }` }>
            {
                mode === TodoMode.DEFAULT &&
                <div className="flex flex-col justify-center flex-1">
                    <div className="flex items-center flex-1">
                        {
                            todo.nested?.length > 0 &&
                            <button
                                type="button"
                                onClick={ toggleTodo }
                                className="w-[24px] h-[24px] bg-slate-100 hover:bg-slate-200 transition-colors rounded mr-2">
                                { todo.isOpened && <ArrowDownIcon /> }
                                { !todo.isOpened && <ArrowRightIcon /> }
                            </button>
                        }

                        <CompleteCheckbox
                            todo={ todo }
                        />

                        <div className="flex flex-col md:flex-row md:items-center flex-1">
                            <button
                                id={ `todo-${ todo.todo_id }` }
                                type="button"
                                className={ `markdown flex-1 inline-flex text-left mr-4 whitespace-pre-wrap ${ todo.is_completed === 1 ? 'line-through text-stone-500' : '' }` }
                                onClick={ startEdit }
                                dangerouslySetInnerHTML={{__html: markdown2Html(todo?.todo_title) }}
                            />

                            {
                                todo.parentTodo &&
                                <NavLink
                                    to={ `/planner/${ todo.parentTodo.project_id }#todo-${ todo.parentTodo.todo_id }` }
                                    className="text-xs mr-4 mt-1 md:mt-0 underline"
                                    dangerouslySetInnerHTML={{__html: markdown2Html(todo.parentTodo.todo_title) }}
                                />
                            }

                            <TodoMetaData todo={ todo } />
                        </div>

                        {
                            !todo.todo_date && !todo.todo_desc && todo.nested?.length <= 0 &&
                            <button
                                onClick={ startEditDescription }
                                type="button">
                                <ClockIcon
                                    size={ 16 }
                                />
                            </button>
                        }

                        <Actions classes="todo__actions" gridSize={ todo.todo_type === TodoType.RegularTaskLog ? 2 : 4 }>

                            {
                                todo.todo_type === TodoType.RegularTaskLog &&
                                <>
                                    <DeleteTodoButton todo={ todo } />

                                    <TodoEditButton todo={ todo } />
                                </>
                            }

                            {
                                todo.todo_type !== TodoType.RegularTaskLog &&
                                <>
                                    <AddNestedTodoButton startAddingNewTodo={ startAddingNewTodo }/>

                                    <DeleteTodoButton todo={ todo } />

                                    <TodoEditButton todo={ todo } />

                                    <DragTodoButton todo={ todo } />

                                    <TodoEditBlocksButton todo={ todo } />

                                    {
                                        todo.nested?.length > 0 &&
                                        <PredictNextEventBtn todo={ todo } />
                                    }

                                    <DiagramBtn todo={ todo } />

                                    <DescartesSquareBtn todo={ todo } />

                                    {
                                        todo.todo_date &&
                                        <AddTodoToGoogleCalendarBtn todo={ todo } />
                                    }

                                    <PrintTodoBtn todo={ todo } />

                                    <DuplicateTodoButton todo={ todo } />

                                    <ITodoUploadAttachmentButtonProps todo={ todo } />

                                    {
                                        todo.todo_type === TodoType.RegularTask && todo.habit_active &&
                                        <TrackYesterdayHabitBtn todo={ todo } />
                                    }

                                    {
                                        props.showTodoLink &&
                                        <OpenTodoLink todo={ todo } />
                                    }

                                    {
                                        todo.todo_desc?.length > 0 &&
                                        <TodoSplitBtn todo={ todo } />
                                    }

                                    {
                                        todo.nested?.length > 0 &&
                                        <OpenTodoTitleChartBtn todo={ todo } />
                                    }

                                    {/*<Suspense fallback={<div>Loading sentiment analysis...</div>}>
                                        <SentimentAnalysisBtnLazy todo={ todo } />
                                    </Suspense>*/}

                                    {
                                        multipleSelectTodos.length > 0 &&
                                        <MoveMultipleToTodoBtn todo={ todo } />
                                    }
                                </>
                            }
                        </Actions>
                    </div>

                    {
                        todo.todo_date && <TodoDate todo={ todo } />
                    }

                    <TodoBlocks todo={ todo } />

                    {
                        todo.todo_tags && (listType !== ListType.TagsView) &&
                        <TodoTags todo={ todo } />
                    }

                    { todo.ideal_final_result && <TodoIdealFinalResult todo={ todo } /> }
                </div>
            }

            {
                mode === TodoMode.EDIT &&
                <TextBox
                    isMultiLine={ false }
                    text={ todo.todo_title }
                    cancel={ cancelEdit }
                    save={ save }
                    isLoading={ updateTitleLoading }
                />
            }
        </div>
    )
};

export default TodoTitle;