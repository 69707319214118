import { deleteProject, MENU_PROJECTS_QUERY_KEY } from '../../../data/planner-data-service';
import { DeleteIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';

interface IDeleteProjectProps {
    project_id: number;
    project_title: string;
}

const DeleteProjectButton = ({ project_id, project_title }: IDeleteProjectProps) => {

    const navigate = useNavigate();

    const queryClient = useQueryClient();

    const { isPending, mutate } = useMutation({
        mutationFn: (project_id: number) => {
            return deleteProject(project_id);
        },
        onSuccess: (response) => {
            queryClient.setQueryData([MENU_PROJECTS_QUERY_KEY], response);
            navigate('/planner/today');
        },
        onError: () => {
            showToast('Delete project error.');
        }
    });

    const deleteProjectHandler = async () => {
        if(!confirm(`Are you sure you want to delete the project "${ project_title }"?`)) return;
        mutate(project_id);
    };

    return (
        <>
            {
                isPending &&
                <PreloaderIcon size={ 20 } color={ '#717985' } />
            }

            {
                !isPending &&
                <button
                    title="Delete Project"
                    className="project__delete flex bg-pink-100 rounded justify-center items-center whitespace-nowrap mx-1 action-btn"
                    onClick={ deleteProjectHandler }
                    type="button">
                    <DeleteIcon size={ 20 } />
                </button>
            }


        </>
    )
};

export default DeleteProjectButton;