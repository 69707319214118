import { SunIcon } from '../../../../../common/ui/icons';
import { useFlowersStore } from '../../../data/flowers-store';
import { startOfDay } from 'date-fns';

const TodayBtn = () => {

    const setWaterLogsDate = useFlowersStore(store => store.setWaterLogsDate);

    const jumpToday = () => {
        const currentDate = new Date();
        const nextDay = startOfDay(currentDate);
        setWaterLogsDate(nextDay.getTime());
    };

    return (
        <button
            title="Today"
            onClick={ jumpToday }
            type="button">
            <SunIcon
                color={ '#d99b00' }
                classes="mr-1"
            />
        </button>
    )
};

export default TodayBtn;