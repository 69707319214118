import { ArrowRightIcon } from '../../../../../common/ui/icons';
import { useBlocksYearStore } from '../../../data/blocks-year-store';

const NextYearButton = () => {

    const currentYear = useBlocksYearStore(store => store.currentYear);
    const setCurrentYear = useBlocksYearStore(store => store.setCurrentYear);

    const jumpToNextMonth = () => {
        setCurrentYear(currentYear + 1);
    };

    return (
        <button
            onClick={ jumpToNextMonth }
            type="button">
            <ArrowRightIcon />
        </button>
    )
};

export default NextYearButton;