import { useState, MouseEvent } from 'react';
import { IPlantAttachment } from '../../flowers-interfaces';
import { showToast } from '../../../../common/domain/toast-provider';
import TextBox from '../../../../common/ui/forms/text-box';
import { updatePlantAttachmentDescription } from '../../data/flowers-data-service';

interface IEditAttachment {
    attachment: IPlantAttachment;
    stopEditDescription: () => void;
    onUpdate: () => void;
}

const EditAttachment = ({ attachment, stopEditDescription, onUpdate }: IEditAttachment) => {

    const [loading, setLoading] = useState(false);

    // const setPlantsData = useFlowersStore(state => state.setPlantsData);

    const save = async (updatedText: string) => {
        setLoading(true);
        const response = await updatePlantAttachmentDescription(attachment.id, updatedText.trim());
        setLoading(false);

        if(!response?.isValid) {
            showToast('Update attachment description error.');
            return;
        }

        stopEditDescription();
        onUpdate();

        // setPlantsData(response);
    };

    const stopPropagation = (evt: MouseEvent) => {
        evt.stopPropagation();
    };

    return (
        <div className="mt-4 text-slate-900" onClick={ stopPropagation }>
            <TextBox
                isMultiLine={ false }
                text={ attachment.description }
                cancel={ stopEditDescription }
                save={ save }
                isLoading={ loading }
            />
        </div>
    )
};

export default EditAttachment;