import { IContextDiagramData } from './interfaces';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DrawIoEmbed, DrawIoEmbedRef, EventExport } from 'react-drawio';
import { createContextDiagramXml, parseXml } from './domain/context-diagram-provider';
import { copySvgToClipboard } from '../../../../../common/domain/io-provider';
import { getContextDiagramFromStorage, setContextDiagramToStorage } from './domain/context-diagram-storage';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IContextDiagramData = {
        "system": "מערכת לניהול 'המכללה למדעי המחשב'",
        "entities": [
            {
                "id": "1",
                "name": "תלמיד",
                "inputs": ["נתוני תלמיד", "aaa"],
                "outputs": ["דוחות נתונים על מסלולי הלימוד", "bbb"]
            },
            {
                "id": "2",
                "name": "מרצה",
                "inputs": ["נתוני נוכחות ציונים ומבחנים"],
                "outputs": ["דוחות תלמידים בקבוצות הלימוד"]
            },
            {
                "id": "3",
                "name": "מנהל מחלקה אקדמית",
                "inputs": ["נתוני קורסים ומסלולים"],
                "outputs": ["דוחות מסלולי לימוד וסטטיסטיקות"]
            },
            {
                "id": "4",
                "name": "הנהלה",
                "inputs": ["נתוני תכניות לימוד ואישורים"],
                "outputs": ["דוחות שעות הוראה למרצים", "הוצאות ושכר למרצים"]
            }
        ]
    }
;

const ContextDiagramHome = () => {

    const drawIoRef = useRef<DrawIoEmbedRef>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IContextDiagramData>(initialData);
    // const [description, setDescription] = useState('');

    useEffect(() => {
        if(!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    useEffect(() => {
        const storageData = getContextDiagramFromStorage();
        if(!storageData) return;

        if(textAreaRef.current) {
            textAreaRef.current.value = storageData;
        }

        let parsed: IContextDiagramData|null = null;

        try{
            parsed = JSON.parse(storageData) as IContextDiagramData;
        }
        catch (error) {
            console.log(error);
        }

        if(!parsed) return;

        setData(parsed);
    }, []);

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setContextDiagramToStorage(evt.target.value);
    };

    const onDiagramSave = async (drawIoData: EventExport) => {
        // downloadImage(drawIoData.data, 'context-diagram.png');
        await copySvgToClipboard(drawIoData.data);
        const desc = parseXml(drawIoData.xml);
        console.log(desc)
        // setDescription(desc);
    };

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    return(
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">דיאגרמת תוכן</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>

            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    className="border rounded w-full p-2"
                    onChange={ onChange }
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={ {
                         height: UI_HEIGHT,
                     } }>
                    <DrawIoEmbed
                        ref={ drawIoRef }
                        urlParameters={ {
                            // ui: 'kennedy',

                            // Shows a 'Loading…' spinner while waiting
                            // for the diagram data to load in embed mode.
                            spin: true,

                            // Specifies whether libraries should be enabled in embed mode.
                            // The default is disabled.
                            // libraries: true,

                            noExitBtn: true,
                        } }
                        configuration={ {
                            compressXML: false,
                            defaultCompressed: false,
                        }}
                        onExport={ onDiagramSave }
                        xml={ createContextDiagramXml(data) }
                    />
                </div>
            </div>

            {/*{
                description &&
                <div dir="rtl" className="whitespace-pre border my-6 p-4">
                    { description }
                </div>
            }*/}
        </div>
    )
};

export default ContextDiagramHome;
