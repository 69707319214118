import { NavLink } from 'react-router-dom';
import { closeMobileMenu } from '../../../menu/ui/mobile-menu-provider';

const FinanceMenu = () => {

    return (
        <div className="side-menu flex flex-col xl:w-[250px] bg-slate-800 text-slate-100 leading-7 h-screen color-scheme-dark">
            <div className="overflow-auto p-3">

                <div className="text-sm pb-3 pt-3 text-slate-400">Finance</div>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/finance` }
                    end={ true }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(84, 209, 111)',
                        }}
                    />
                    <p>Transactions</p>
                </NavLink>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/finance/categories` }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(102, 97, 255)',
                        }}
                    />
                    <p>Categories</p>
                </NavLink>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/finance/businesses` }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(84,209,186)',
                        }}
                    />
                    <p>Businesses</p>
                </NavLink>

                <NavLink
                    onClick={ closeMobileMenu }
                    to={ `/finance/gas` }
                    className="border-b border-slate-700 pb-2 mb-2 flex items-center">
                    <div
                        className="w-[10px] h-[10px] rounded-full mr-4"
                        style={{
                            backgroundColor: 'rgb(211, 176, 94)',
                        }}
                    />
                    <p>Бензин</p>
                </NavLink>
            </div>
        </div>
    )
};

export default FinanceMenu;