import { IMonthlyReport } from './interfaces';
import { useEffect, useRef, useState } from 'react';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IMonthlyReport = {
        "title": "דו״ח פעילות חודשית עבור 08.2024",
        "dataDate": "01/08/2024",
        "printDate": "26/02/2025",
        "table": {
            "headers": ["שם כללי", "מספר מורים", "מספר שיעורים", "מספר תלמידים", "מחיר כולל"],
            "rows": [
                ["תרומה כללית", "1", "1", "1", "$90"],
                ["תצוגה", "2", "2", "2", "$165"],
                ["פסנתר", "7", "7", "7", "$650"],
                ["סה״כ", "", "", "", "$905"]
            ]
        }
    }
;

const ReportHome = () => {

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IMonthlyReport>(initialData);

    useEffect(() => {
        if (!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    return (
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">דו״ח</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    className="border rounded w-full p-2"
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={{
                         height: UI_HEIGHT,
                     }}>
                    {
                        data &&
                        <div dir="rtl">
                            <div className="mb-6">
                                <p className="font-bold mb-2">דו&quot;ח אחד עבור דרג ניהולי:</p>
                                <ul className="list-disc px-4 mx-4">
                                    <li><b>דו&quot;ח:</b> דו&quot;ח פעילות</li>
                                    <li><b>צורת הצגה:</b> טבלה</li>
                                    <li><b>יוזמת הפקה:</b> מנכ״ל</li>
                                    <li><b>תדירות הפקה:</b> חודשי</li>
                                </ul>
                            </div>

                            <div className="text-2xl font-bold mb-6">{ data.title }</div>

                            <table className="mb-6">
                                <thead>
                                <tr>
                                    {
                                        data.table.headers.map((header, i) => {
                                            return (
                                                <td key={ `header-${ i }` }
                                                    className="bg-slate-700 text-slate-100 px-6 py-2 border border-slate-500">{ header }</td>
                                            )
                                        })
                                    }
                                </tr>
                                </thead>

                                <tbody>
                                {
                                    data.table.rows.map((row, ri) => {
                                        return (
                                            <tr key={ `row-${ ri }` }>
                                                {
                                                    row.map((cell, i) => {
                                                        return (
                                                            <td key={ `cell-${ i }` }
                                                                className="px-6 py-2 border border-slate-500">
                                                                { cell }
                                                            </td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                                </tbody>
                            </table>

                            <div>
                                <span className="underline">תאריך הפקת הדו״ח:</span>
                                <span> { data.printDate } </span>
                            </div>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
};

export default ReportHome;
