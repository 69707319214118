import {
    IActivityDiagram,
    IActivityDiagramElement, IActivityDiagramEndElement,
    IActivityDiagramLaneElement,
    IActivityElement, ITransitionElement, IVerticalLinesElement,
} from '../interfaces';
import { v4 as uuidv4 } from 'uuid';

const LANE_WIDTH = 100;
const LANE_HEIGHT = 40;
const LANE_MARGIN_X = 80;

const MARGIN_Y = 50;
const START_CIRCLE_SIZE = 20;
const END_CIRCLE_SIZE = 60;

const ACTIVITY_WIDTH = 120;
const ACTIVITY_HEIGHT = 50;

const prepareData = (data: IActivityDiagram) : IActivityDiagramElement|null => {
    if(!data) return null;

    const result: IActivityDiagramElement = {
        lanes: [],
        startElement: {
            id: uuidv4(),
            x: 0,
            y: 0,
            width: START_CIRCLE_SIZE,
            height: START_CIRCLE_SIZE,
        },
        endElements: [],
        activityElements: [],
        transitionElements: [],
        verticalLines: [],
    };

    // Collect data about incoming arrows ----------------
    const incomingArrowsMap = new Map<string, boolean>(); // activity_id, has or not incoming arrows

    for(const group of data.activityGroups) {
        for(const transition of group.transitions) {
            const to = transition.to;
            incomingArrowsMap.set(to, true);
        }
    }

    incomingArrowsMap.set(data.startEvent.transition.to, true);

    // Top lanes (texts) -----------------------------------------
    let x = 0;

    const laneMap = new Map<string, {
        x: number;
        laneElement: IActivityDiagramLaneElement;

    }>(); // lane_id ---> center x position

    for(const lane of data.lanes) {
        const laneElement: IActivityDiagramLaneElement = {
            id: lane.id,
            name: lane.name,
            x,
            y: 0,
            width: LANE_WIDTH,
            height: LANE_HEIGHT,
        };

        laneMap.set(lane.id, {
            x,
            laneElement,
        });

        x += laneElement.width + LANE_MARGIN_X;

        result.lanes.push(laneElement);
    }

    const lanesWidth = data.lanes.length * LANE_WIDTH + (data.lanes.length - 1) * LANE_MARGIN_X;

    // Start circle ----------------------------------------------
    result.startElement.x = (LANE_WIDTH - START_CIRCLE_SIZE) / 2;
    result.startElement.y = LANE_HEIGHT + MARGIN_Y;

    // Activity elements -----------------------------------------
    let activityY = result.startElement.y + result.startElement.height + MARGIN_Y;

    for(let i=0; i<data.activityGroups.length; i++) {
        const activityGroup = data.activityGroups[i];

        for(let j=0; j<activityGroup.activities.length; j++) {

            const activity = activityGroup.activities[j];
            const item = laneMap.get(activity.laneId);
            const x = item?.x ?? 0;
            const activityX = x - (ACTIVITY_WIDTH - LANE_WIDTH)/2;

            const activityElement: IActivityElement = {
                id: activity.id,
                name: activity.name,
                x: activityX,
                y: activityY,
                width: ACTIVITY_WIDTH,
                height: ACTIVITY_HEIGHT,
                type: 'activity',
                laneId: activity.laneId,
                hasIncomingArrows: incomingArrowsMap.get(activity.id) ?? false,
            };

            result.activityElements.push(activityElement);
            activityY += activityElement.height + MARGIN_Y;

            /*if(activityElement.hasIncomingArrows) {

            }*/
        }

        if(i < data.activityGroups.length - 1) {
            const dividerElement: IActivityElement = {
                id: uuidv4(),
                name: '',
                x: 0,
                y: activityY,
                width: lanesWidth,
                height: 1,
                type: 'divider',
                laneId: '',
                hasIncomingArrows: false,
            };

            result.activityElements.push(dividerElement);
            activityY += dividerElement.height + MARGIN_Y;
        }

        for(let j=0; j<activityGroup.transitions.length; j++) {
            const transition = activityGroup.transitions[j];

            const transitionElement: ITransitionElement = {
                id: uuidv4(),
                source: transition.from,
                target: transition.to,
            };

            result.transitionElements.push(transitionElement);
        }
    }

    // Fix first items without incoming arrows in each group -----------
    for(let i=1; i<data.activityGroups.length; i++) {
        const activityGroup = data.activityGroups[i];

        let prev = null;
        for(let j=0; j<activityGroup.activities.length; j++) {
            const activity = activityGroup.activities[j];
            const found = result.activityElements.find(item => item.id === activity.id);
            if(!found) continue;

            if(prev === null) {
                prev = found;
            }
            else{
                if(!prev.hasIncomingArrows && !found.hasIncomingArrows) {
                    found.y = prev.y;
                    prev = found;
                }
            }
        }
    }

    // Arrow from start element to the first activity item.
    result.transitionElements.push({
        id: uuidv4(),
        source: result.startElement.id,
        target: data.startEvent.transition.to,
    });

    let maxY = activityY + ACTIVITY_HEIGHT;

    // End elements with relevant arrows -------------------------
    for(let i=0; i<data.endEvent.transitions.length; i++) {
        const transition = data.endEvent.transitions[i];

        const found = result.activityElements.find(item => item.id === transition.from);
        if(!found?.laneId) continue;

        const item = laneMap.get(found.laneId);
        if(!item) continue;

        const endElementX = item.x + END_CIRCLE_SIZE / 4;
        const endElementY = found.y + found.height + MARGIN_Y;
        const endElementId = uuidv4();

        const endElement: IActivityDiagramEndElement = {
            id: endElementId,
            x: endElementX,
            y: endElementY,
            width: END_CIRCLE_SIZE,
            height: END_CIRCLE_SIZE,
        };

        result.endElements.push(endElement);

        result.transitionElements.push({
            id: uuidv4(),
            source: found.id,
            target: endElementId,
        });

        maxY = Math.max(maxY, endElementY + END_CIRCLE_SIZE);
    }

    // Create vertical swim-lines ------------------
    const verticalLineHeight = maxY;
    for(let i=0; i<result.lanes.length - 1; i++) {
        const lane = result.lanes[i];

        const verticalLineElement: IVerticalLinesElement = {
            id: uuidv4(),
            x: lane.x + lane.width + LANE_MARGIN_X/2,
            y: 0,
            width: 1,
            height: verticalLineHeight,
        };

        result.verticalLines.push(verticalLineElement);
    }

    return result;
};

export const createActivityDiagramXml = (data: IActivityDiagram): string => {

    const preparedData = prepareData(data);
    if(!preparedData) return '';

    const initialXml = `
<mxfile host="draw.io">
  <diagram name="דיאגרמת פעילויות">
    <mxGraphModel background="#FFFFFF">
      <root>
        <mxCell id="0"/>
        <mxCell id="1" parent="0"/>
        
        ${ preparedData.lanes.map(laneElement => renderLane(laneElement)).join('') }
        
        <!-- start circle -->
        <mxCell id="${ preparedData.startElement.id }" value="" style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;fillColor=#000000;" vertex="1" parent="1">
          <mxGeometry x="${ preparedData.startElement.x }" y="${ preparedData.startElement.y }" width="${ preparedData.startElement.width }" height="${ preparedData.startElement.height }" as="geometry" />
        </mxCell>
        
        ${ preparedData.activityElements.map(activityElement => renderActivity(activityElement)).join('') }
        
        ${ preparedData.endElements.map(endElement => renderEndElement(endElement)).join('') }
        
        ${ preparedData.transitionElements.map(transitionElement => renderArrow(transitionElement)).join('') }
        
        ${ preparedData.verticalLines.map(verticalElement => renderVerticalElement(verticalElement)).join('') }
        
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>`;

    return initialXml.trim();
};

const renderLane = (laneElement: IActivityDiagramLaneElement) => {
    return `
    <mxCell id="${ laneElement.id }" value="${ laneElement.name }" style="text;html=1;align=center;verticalAlign=middle;whiteSpace=wrap;rounded=0;fontStyle=1" vertex="1" parent="1">
      <mxGeometry x="${ laneElement.x }" y="${ laneElement.y }" width="${ laneElement.width }" height="${ laneElement.height }" as="geometry" />
    </mxCell>
    `;
};

const renderActivity = (activityElement: IActivityElement) => {
    return activityElement.type === 'activity' ? `

    <!-- activity -->
    <mxCell id="${ activityElement.id }" value="${ activityElement.name }" style="ellipse;whiteSpace=wrap;html=1;fillColor=#ffe6cc;strokeColor=#d79b00;" vertex="1" parent="1">
      <mxGeometry x="${ activityElement.x }" y="${ activityElement.y }" width="${ activityElement.width }" height="${ activityElement.height }" as="geometry" />
    </mxCell>
    
        ${
            activityElement.hasIncomingArrows ? `` : `
                <!-- arrow from divider to activity -->
                <mxCell id="${ uuidv4() }" style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;entryX=0.5;entryY=0;entryDx=0;entryDy=0;" edge="1" parent="1" target="${ activityElement.id }">
                  <mxGeometry relative="1" as="geometry">
                    <mxPoint x="${ activityElement.x + activityElement.width / 2 }" y="${ activityElement.y }" as="targetPoint" />
                    <mxPoint x="${ activityElement.x + activityElement.width / 2 }" y="${ activityElement.y - MARGIN_Y }" as="sourcePoint" />
                  </mxGeometry>
                </mxCell>
            `
        }
    `
        :
    `
    <!-- divider -->
    <mxCell id="${ activityElement.id }" value="" style="endArrow=none;html=1;rounded=0;strokeWidth=2;" edge="1" parent="1">
      <mxGeometry width="50" height="50" relative="1" as="geometry">
        <mxPoint x="${ activityElement.x }" y="${ activityElement.y }" as="sourcePoint" />
        <mxPoint x="${ activityElement.x + activityElement.width }" y="${ activityElement.y }" as="targetPoint" />
      </mxGeometry>
    </mxCell>
        `;
};

const renderArrow = (transitionElement: ITransitionElement) => {
    return `
    <mxCell id="${ transitionElement.id }" style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=0.5;exitY=1;exitDx=0;exitDy=0;entryX=0.5;entryY=0;entryDx=0;entryDy=0;" edge="1" parent="1" source="${ transitionElement.source }" target="${ transitionElement.target }">
      <mxGeometry relative="1" as="geometry" />
    </mxCell>
    `;
};

const renderEndElement = (endElement: IActivityDiagramEndElement) => {
    return `
    <!-- end state: double circle -->
        <mxCell 
             id="${ endElement.id }"  
             value=""
             style="group" 
             vertex="1" 
             connectable="0" 
             parent="1">
          <mxGeometry x="${ endElement.x }" y="${ endElement.y }" width="${ endElement.width }" height="${ endElement.height }" as="geometry"/>
        </mxCell>
        
        <mxCell 
            id="${ uuidv4() }" 
            value="" 
            style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;" 
            vertex="1" 
            parent="${ endElement.id }">
          <mxGeometry width="${ END_CIRCLE_SIZE }" height="${ END_CIRCLE_SIZE }" as="geometry" />
        </mxCell>
        
        <mxCell 
            id="${ uuidv4() }" 
            value="" 
            style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;fillColor=#000000;" 
            vertex="1" 
            parent="${ endElement.id }">
          <mxGeometry x="${ END_CIRCLE_SIZE/4 }" y="${ END_CIRCLE_SIZE/4 }" width="${ END_CIRCLE_SIZE/2 }" height="${ END_CIRCLE_SIZE/2 }" as="geometry" />
        </mxCell>
    `;
};

const renderVerticalElement = (verticalElement: IVerticalLinesElement) => {
    return `
    <mxCell id="${ verticalElement.id }" value="" style="endArrow=none;html=1;rounded=0;" edge="1" parent="1">
      <mxGeometry width="50" height="50" relative="1" as="geometry">
        <mxPoint x="${ verticalElement.x }" y="${ verticalElement.y }" as="sourcePoint" />
        <mxPoint x="${ verticalElement.x }" y="${ verticalElement.height }" as="targetPoint" />
      </mxGeometry>
    </mxCell>
    `;
};
