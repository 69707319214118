import { useEffect } from 'react';
import ImagesList from './images-list';

const PlantGalleryHome = () => {

    useEffect(() => {
        document.title = `Gallery | Flowers`;
    }, []);

    return (
        <div className="flex flex-col">
            <h1 className="flex flex-col md:flex-row md:items-center text-xl xl:text-3xl mb-6">
                Flowers Gallery
            </h1>

            <ImagesList />

        </div>
    )
};

export default PlantGalleryHome;