import { useCallback, useEffect } from 'react';
import { showToast } from '../../../../common/domain/toast-provider';
import { getPlantsData } from '../../data/flowers-data-service';
import { useFlowersStore } from '../../data/flowers-store';
import PlantsList from './list/plants-list';
import PlantTypeTabsNavigation, { PlantsTab } from './tabs-navigation';
import AddNewPlant from './actions/add-new-plant';
import { getFilteredPlants } from '../../domain/flowers-provider';

const PlantsHome = () => {

    const plantsTab = useFlowersStore(store => store.plantsTab);
    const showNonAlive = useFlowersStore(store => store.showNonAlive);
    const plantsData = useFlowersStore(store => store.plantsData);
    const setPlantsData = useFlowersStore(store => store.setPlantsData);

    const plants = getFilteredPlants(plantsData?.plants, showNonAlive);

    useEffect(() => {
        document.title = `Plants | Flowers`;
    }, []);

    const onUpdate = useCallback(async () => {
        const response = await getPlantsData();

        if(!response) {
            showToast('Get plants error.');
            return;
        }

        setPlantsData(response);
    }, [setPlantsData]);

    useEffect(() => {
        (async () => {
           await onUpdate();
        })();
    }, [onUpdate]);

    return (
        <div>
            <h1 className="flex items-center text-xl xl:text-3xl mb-6">
                Plants <span className="text-base ml-4">({ plants.length })</span>
            </h1>

            <PlantTypeTabsNavigation />

            {
                plantsTab === PlantsTab.Default &&
                <PlantsList onUpdate={ onUpdate } />
            }

            {
                plantsTab === PlantsTab.AddNew &&
                <AddNewPlant />
            }

        </div>
    )
};

export default PlantsHome;