import { useBlocksYearStore } from '../../data/blocks-year-store';
import BlocksTag from './tag';

const BlocksTags = () => {

    const data = useBlocksYearStore(store => store.data);

    return (
        <>
            {
                data?.tags?.length > 0 &&
                <div className="flex items-center flex-wrap text-xs">

                    <BlocksTag tag={ null } title="No Tags" />

                    {
                        data?.tags?.map((tag) => (
                            <BlocksTag
                                key={ tag.tag_id }
                                tag={ tag }
                                title={ tag.title }
                            />
                        ))
                    }
                </div>
            }
        </>
    )
};

export default BlocksTags;