export class BinningRule {
    min: number;
    max: number;
    name: string;

    constructor(min: number, max: number, name: string) {
        this.min = min;
        this.max = max;
        this.name = name;
    }

    public draw() {
        return (
            <div className="px-4"><b>{ this.name }:</b> { this.min } - { this.max }</div>
        )
    }
}