import { DFDNode, DFDEdge, IPoint } from './interfaces';

export const UI_HEIGHT = '700px';

const repulsiveForce = (
    nodeA: DFDNode,
    nodeB: DFDNode,
    k: number,
    nodeSpacing: number
): IPoint => {
    const dx = (nodeB.x + nodeB.width / 2) - (nodeA.x + nodeA.width / 2);
    const dy = (nodeB.y + nodeB.height / 2) - (nodeA.y + nodeA.height / 2);
    const distance = Math.sqrt(dx * dx + dy * dy) || 0.01; // Prevent division by zero

    const minDistance = (nodeA.width + nodeB.width) / 2 + nodeSpacing; // Prevent overlap
    const force = (k * k) / Math.max(distance, minDistance); // Repel more if too close

    return {
        x: force * (dx / distance),
        y: force * (dy / distance),
    };
};

const attractiveForce = (
    nodes: DFDNode[],
    edge: DFDEdge,
    k: number,
    nodeSpacing: number
): IPoint | null => {
    const nodeA = nodes.find(n => n.id === edge.source);
    const nodeB = nodes.find(n => n.id === edge.target);
    if (!nodeA || !nodeB) return null;

    const dx = (nodeB.x + nodeB.width / 2) - (nodeA.x + nodeA.width / 2);
    const dy = (nodeB.y + nodeB.height / 2) - (nodeA.y + nodeA.height / 2);
    const distance = Math.sqrt(dx * dx + dy * dy) || 0.01;

    const targetDistance = (nodeA.width + nodeB.width) / 2 + nodeSpacing; // Keep connected nodes apart
    const force = (Math.max(distance, targetDistance) * distance) / k; // Hooke’s Law

    return {
        x: force * (dx / distance),
        y: force * (dy / distance),
    };
};

export const applyForceDirectedLayout = (
    nodes: DFDNode[],
    edges: DFDEdge[],
    width: number,
    height: number,
    iterations = 500,
    damping = 0.85,
    nodeSpacing = 100
) : DFDNode[] => {

    const area = width * height;
    const k = Math.sqrt(area / nodes.length); // Ideal node spacing

    nodes.forEach(node => {
        node.vx = 0;
        node.vy = 0;
    });

    for (let iter = 0; iter < iterations; iter++) {
        const forces = new Map<string, { x: number; y: number }>();

        // Calculate repulsive forces
        nodes.forEach(nodeA => {
            const force = { x: 0, y: 0 };
            nodes.forEach(nodeB => {
                if (nodeA !== nodeB) {
                    const repulse = repulsiveForce(nodeA, nodeB, k, nodeSpacing);
                    force.x -= repulse.x;
                    force.y -= repulse.y;
                }
            });
            forces.set(nodeA.id, force);
        });

        // Calculate attractive forces
        edges.forEach(edge => {
            const attract = attractiveForce(nodes, edge, k, nodeSpacing);
            if (!attract) return;

            const nodeA = nodes.find(n => n.id === edge.source);
            const nodeB = nodes.find(n => n.id === edge.target);
            if (!nodeA || !nodeB) return;

            forces.get(nodeA.id)!.x += attract.x;
            forces.get(nodeA.id)!.y += attract.y;
            forces.get(nodeB.id)!.x -= attract.x;
            forces.get(nodeB.id)!.y -= attract.y;
        });

        // Update node positions
        nodes.forEach(node => {
            const force = forces.get(node.id)!;
            node.vx = (node.vx + force.x) * damping;
            node.vy = (node.vy + force.y) * damping;

            node.x += node.vx;
            node.y += node.vy;

            // Keep nodes within bounds
            node.x = Math.max(0, Math.min(width - node.width, node.x));
            node.y = Math.max(0, Math.min(height - node.height, node.y));
        });
    }

    return nodes;
};

// -------

export const kMeansClustering = (
    nodes: DFDNode[],
    edges: DFDEdge[],
    width: number,
    height: number,
    clusters = 5,
    iterations = 10,
    spacing = 550,
    minMovementThreshold = 1,
): DFDNode[] => {

    // Step 1: Randomly initialize cluster centers
    let centroids: { x: number; y: number }[] = Array.from({ length: clusters }, () => ({
        x: Math.random() * width,
        y: Math.random() * height,
    }));

    // Step 2: Compute node connectivity
    const adjacencyMap: Map<string, Set<string>> = new Map();
    edges.forEach(edge => {
        if (!adjacencyMap.has(edge.source)) adjacencyMap.set(edge.source, new Set());
        if (!adjacencyMap.has(edge.target)) adjacencyMap.set(edge.target, new Set());
        adjacencyMap.get(edge.source)!.add(edge.target);
        adjacencyMap.get(edge.target)!.add(edge.source);
    });

    // Step 3: Assign nodes to clusters based on connectivity + distance
    const nodeClusterMap = new Map<string, number>();

    function assignClusters() {
        nodeClusterMap.clear();
        nodes.forEach(node => {
            let bestCluster = 0;
            let maxConnections = -Infinity;
            let minDistance = Infinity;

            centroids.forEach((centroid, index) => {
                const dx = node.x - centroid.x;
                const dy = node.y - centroid.y;
                const distance = Math.sqrt(dx * dx + dy * dy);

                // Get number of direct connections in this cluster
                const connectedNodes = adjacencyMap.get(node.id) || new Set();
                const connectionsInCluster = nodes.filter(n => connectedNodes.has(n.id) && nodeClusterMap.get(n.id) === index).length;

                // Prefer clusters with more connections, but also consider distance
                if (connectionsInCluster > maxConnections || (connectionsInCluster === maxConnections && distance < minDistance)) {
                    bestCluster = index;
                    maxConnections = connectionsInCluster;
                    minDistance = distance;
                }
            });

            nodeClusterMap.set(node.id, bestCluster);
        });
    }

    for (let iter = 0; iter < iterations; iter++) {
        assignClusters();

        // Step 4: Recalculate centroids as the average position of nodes in each cluster
        const newCentroids = centroids.map((_, index) => {
            const clusterNodes = nodes.filter(node => nodeClusterMap.get(node.id) === index);

            if (clusterNodes.length === 0) {
                // Prevent empty cluster - reassign a node
                const randomNode = nodes[Math.floor(Math.random() * nodes.length)];
                return { x: randomNode.x, y: randomNode.y };
            }

            const avgX = clusterNodes.reduce((sum, n) => sum + n.x, 0) / clusterNodes.length;
            const avgY = clusterNodes.reduce((sum, n) => sum + n.y, 0) / clusterNodes.length;
            return { x: avgX, y: avgY };
        });

        // Step 5: Stop if centroids move less than the threshold
        const maxMovement = Math.max(...centroids.map((c, i) => {
            const dx = newCentroids[i].x - c.x;
            const dy = newCentroids[i].y - c.y;
            return Math.sqrt(dx * dx + dy * dy);
        }));

        centroids = newCentroids;

        if (maxMovement < minMovementThreshold) {
            console.log(`Stopped early at iteration ${iter} due to minimal movement.`);
            break;
        }
    }

    // Step 6: Assign final positions to nodes within their clusters
    centroids.forEach((centroid, i) => {
        const clusterNodes = nodes.filter(node => nodeClusterMap.get(node.id) === i);
        clusterNodes.forEach(node => {
            node.x = centroid.x + (Math.random() - 0.5) * spacing;
            node.y = centroid.y + (Math.random() - 0.5) * spacing;
        });
    });

    return nodes;
}
