import { IFinanceGasResponse } from '../finance-interfaces';

export const FINANCE_INCOME_CATEGORY_ID = 10;

export const formatGasData = (response: IFinanceGasResponse|null) : IFinanceGasResponse|null => {
    const items = response?.gasItems || [];

    for(const item of items) {
        try{
            item.date = new Date(item.GasYear, item.GasMonth, item.GasDay);
        }
        catch(ex){
            console.error(ex);
        }
    }

    return response;
};