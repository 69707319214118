import { ChangeEvent, useState } from 'react';
import { DecisionTreeTable } from './domain/decision-tree-table';
import EntropyFormula from './ui/entropy-formula';
import { DecisionTree } from './domain/decision-tree';

const UI_HEIGHT = '500px';

const DecisionTreeHome = () => {

    const [csv, setCsv] = useState(`
המתנת לקוח  ,זמן המתנה משוער  ,סוג מסעדה  ,הזמנה מראש  ,חמסין  ,רמת מחירים  ,תפוסת לקוחות במסעדה  ,לקוח רעב  ,סוף שבוע  , בר  ,מסעדה חלופית  ,מס' לקוח  
כ  ,  0-10, צרפתי  ,כ  ,ל  ,גבוהה  ,בינונית ,כ  ,ל  ,ל  ,כ  ,1
ל  , 30-60,אסייתי  ,ל  ,ל  ,נמוכה  ,  ?,כ  ,ל  ,ל  ,כ  ,2
כ  ,  0-10,מזרחי  ,ל  ,ל  ,נמוכה  ,בינונית ,ל  ,ל  ,כ  ,ל  ,3
כ  ,  ?,אסייתי  ,ל  ,ל  ,נמוכה  ,מלאה  ,כ  ,כ  ,ל  ,כ  ,4
ל  ,  > 60, צרפתי  ,כ  ,ל  ,גבוהה  ,מלאה  ,ל  ,  ?,ל  ,כ  ,5
כ  ,  0-10,איטלקי ,כ  ,כ  ,בינונית ,בינונית ,כ  ,ל  ,כ  ,ל  ,6
ל  ,  0-10,  ?,ל  ,כ  ,נמוכה  ,ריק  ,ל  ,ל  ,כ  ,ל  ,7
כ  ,  0-10,אסייתי  ,כ  ,כ  ,בינונית ,בינונית ,כ  ,ל  ,ל  ,ל  ,8
ל  ,  > 60,מזרחי  ,ל  ,כ  ,נמוכה  ,מלאה  ,ל  ,כ  ,ל  ,ל  ,9
ל  , 10-30,איטלקי ,כ  ,ל  ,גבוהה  ,מלאה  ,כ  ,כ  ,כ  ,כ  ,10
ל  ,  0-10,אסייתי  ,ל  ,ל  ,נמוכה  ,ריק  ,ל  ,ל  ,כ  ,ל  ,11
כ  , 30-60,מזרחי  ,ל  ,ל  ,נמוכה  ,מלאה  ,כ  ,כ  ,כ  ,כ  ,12
    `);
    const [decisionTreeTable, setDecisionTreeTable] = useState<DecisionTreeTable|null>(new DecisionTreeTable(csv) || null);
    const [decisionTree, setDecisionTree] = useState<DecisionTree|null>(new DecisionTree(csv));

    const onClick = () => {
        setDecisionTreeTable(new DecisionTreeTable(csv));
        setDecisionTree(new DecisionTree(csv));
        setCsv(csv.trim().replaceAll('', 'נ'));
    };

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setCsv(evt.target.value);
    };

    return(
        <div className="w-full py-2 overflow-auto" dir="rtl" style={{
            maxWidth: 'calc(100vw - 200px)',
        }}>

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl">Decision Tree</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded mx-6">
                    Update
                </button>

                <p className="text-xs text-sky-700 mx-4" dir="ltr">
                    Нужно открыть PDF в WORD, а затем скопировать таблицу в EXCEL. Его можно сохранить как CSV, который
                    можно скопировать сюда.<br/>.
                    Не нужно строить всю таблицу: нужно шореш и еще пару рамот. А ниже просто ставим троеточия.<br/>.
                    label - (עמודה לסיווג) עמודה לחיזוי.<br/>
                    אפשר להסיר עמודות (תכונות) חד-חד-ערכיות כי אנחנו לא משתמשים בהם במהלך הסיווג.<br/>
                    עמודות מראה = עמודות עם ערכים הפוכים.<br/>
                    הגעתי לסיווג מושלם<br/>.
                    Слева label, в конце index.<br/>.
                    קיבלנו סיווג מושלם<br/>.
                    מכיוון ויתא לי עץ עם סיווג מושלם בעל 2 רמות וביקשו 3 רמות אזי אני מוסיפה רמה אחת נוספת
                </p>
            </div>

            <div className="flex gap-6">
                <div className="flex flex-col">

                    <label htmlFor="data" className="text-slate-400 font-bold mb-2 text-sm">
                    data
                    </label>
                    <textarea
                        id="data"
                        onChange={ onChange }
                        className="border rounded w-full p-2"
                        style={ {
                            width: '300px',
                            height: UI_HEIGHT,
                        } }
                        value={ csv }
                    />
                </div>

                { decisionTreeTable?.draw() }
            </div>

            <div className="my-4 text-sm max-w-full mr-auto overflow-auto" dir="ltr">
                <EntropyFormula/>

                {/*{ decisionTreeTable?.drawProbabilities() }*/}

                <div>{ decisionTree?.draw() }</div>
            </div>
        </div>
    )
};

export default DecisionTreeHome;
