import { IDecisionTable } from './interfaces';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { getDecisionDiagramFromStorage, setDecisionDiagramToStorage } from './domain/decision-diagram-storage';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IDecisionTable = {
    conditions: [
        { id: "order_cost", name: "עלות הזמנה מעל 100 ש\"ח" },
        { id: "subscription", name: "מנוי" },
        { id: "distance", name: "מרחק" }
    ],
    rules: [
        { id: "R1", conditionValues: ["כן", "-", "-"] },
        { id: "R2", conditionValues: ["לא", "כן", "ק"] },
        { id: "R3", conditionValues: ["לא", "כן", "ג"] },
        { id: "R4", conditionValues: ["לא", "כן", "ב"] },
        { id: "R5", conditionValues: ["לא", "לא", "ק"] },
        { id: "R6", conditionValues: ["לא", "לא", "ג"] },
        { id: "R7", conditionValues: ["לא", "לא", "ב"] }
    ],
    decisions: [
        "דמי משלוח = 0",
        "דמי משלוח = 10",
        "דמי משלוח = 15",
        "דמי משלוח = 20",
        "דמי משלוח = 30",
        "דמי משלוח = 40"
    ],
    mapping: [
        ["✔", "", "", "", "", "", ""],
        ["", "✔", "", "", "", "", ""],
        ["", "", "✔", "", "", "", ""],
        ["", "", "", "✔", "", "", ""],
        ["", "", "", "", "✔", "", ""],
        ["", "", "", "", "", "✔", ""]
    ]
};

const DecisionTableHome = () => {

    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IDecisionTable>(initialData);

    useEffect(() => {
        if (!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    useEffect(() => {
        const storageData = getDecisionDiagramFromStorage();
        if(!storageData) return;

        if(textAreaRef.current) {
            textAreaRef.current.value = storageData;
        }

        let parsed: IDecisionTable|null = null;

        try{
            parsed = JSON.parse(storageData) as IDecisionTable;
        }
        catch (error) {
            console.log(error);
        }

        if(!parsed) return;

        setData(parsed);
    }, []);

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setDecisionDiagramToStorage(evt.target.value);
    };

    return (
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">טבלת החלטה</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    className="border rounded w-full p-2"
                    onChange={ onChange }
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={ {
                         height: UI_HEIGHT,
                     } }>
                    {
                        data &&
                        <div dir="rtl" className="overflow-auto">
                            <table className="border-collapse w-full text-center" style={ {
                                width: '650px',
                            } }>

                                {/* Table Header */ }
                                <thead>
                                    <tr>
                                        <th className="border border-slate-300 px-3 py-2 bg-slate-200">גורמי החלטה</th>
                                        {
                                            data.rules.map((rule) => (
                                                <th key={ rule.id } className="border border-slate-300 px-3 py-2 bg-slate-200">
                                                    &nbsp;
                                                </th>
                                            ))
                                        }
                                    </tr>
                                </thead>

                                {/* Table Body */ }
                                <tbody>

                                {/* Conditions */ }
                                {
                                    data.conditions.map((condition, conditionIdx) => (
                                        <tr key={ condition.id }>
                                            <td className="border border-slate-300 px-3 py-2 bg-slate-50">{ condition.name }</td>
                                            {
                                                data.rules.map((rule) => (
                                                    <td key={ rule.id } className="border px-3 py-2">
                                                        { rule.conditionValues[conditionIdx] }
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))
                                }

                                {/* Decision Section */ }
                                <tr>
                                    <td className="border border-slate-300 px-3 py-2 bg-slate-200">החלטה</td>
                                    {
                                        data.rules.map((_, index) => (
                                            <td key={ index } className="border border-slate-300 px-3 py-2 bg-slate-200">
                                                &nbsp;
                                            </td>
                                        ))
                                    }
                                </tr>

                                {/* Mapping Section */ }
                                { data.decisions.map((decision, rowIndex) => (
                                    <tr key={ rowIndex }>
                                        <td className="border px-3 py-2">{ decision }</td>
                                        { data.mapping[rowIndex].map((mark, colIndex) => (
                                            <td key={ colIndex } className="border px-3 py-2">
                                                { mark && <span className="text-blue-500 font-bold">+</span> }
                                            </td>
                                        )) }
                                    </tr>
                                )) }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
};

export default DecisionTableHome;
