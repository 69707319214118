import { ITodo } from '../../../planner-interfaces';
import { useEffect, useState } from 'react';
import TodoBlocksPreview from './todo-blocks-preview';
import CommonTodoBlocksEdit from '../../../../../common/ui/todo/todo-blocks/common-todo-blocks-edit';
import { deleteBlock, insertBlock, updateBlock } from '../../../data/planner-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';
import { usePlannerStore } from '../../../data/planner-store';

export const ADD_NEW_TODO_BLOCKS_EVENT = 'add-new-todo-blocks';

export enum TodoBlocksMode {
    DEFAULT = 0,
    EDIT = 1
}

interface ITodoBlocks {
    todo: ITodo;
}

const TodoBlocks = ({ todo }: ITodoBlocks) => {

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);

    const [mode, setMode] = useState<TodoBlocksMode>(TodoBlocksMode.DEFAULT);

    const hasNestedTodos = todo?.nested?.length > 0;

    useEffect(() => {

        const handler = (evt: CustomEvent) => {
            const todo_id = evt?.detail ?? 0;
            if(todo_id !== todo.todo_id) return;

            setMode(TodoBlocksMode.EDIT);
        };

        document.addEventListener(ADD_NEW_TODO_BLOCKS_EVENT, handler);

        return () => {
            document.removeEventListener(ADD_NEW_TODO_BLOCKS_EVENT, handler);
        };
    }, [todo.todo_id]);

    const setDefaultMode = () => {
        setMode(TodoBlocksMode.DEFAULT);
    };

    const onInsert = async (
        todo_id: string|number,
        startDate: number,
        endDate: number
    ) => {
        const response = await insertBlock(
            todo_id,
            startDate,
            endDate
        );

        if(!response) {
            showToast('Insert block error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    const onUpdate = async (
        block_id: number,
        startDate: number,
        endDate: number
    ) => {
        const response = await updateBlock(
            block_id,
            startDate,
            endDate
        );

        if(!response) {
            showToast('Update block error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    const onDelete = async (block_id: number) => {
        const response = await deleteBlock(block_id);

        if(!response) {
            showToast('Delete block error.');
            return;
        }

        setCombinedProject(combineProjectData(response));
    };

    return (
        <>
            {
                (mode === TodoBlocksMode.DEFAULT) &&
                <TodoBlocksPreview
                    todo={ todo }
                />
            }

            {
                (mode === TodoBlocksMode.EDIT) &&
                <div className={ `todo-blocks-edit flex mr-4 ${ hasNestedTodos ? 'ml-8 xl:ml-14' : 'ml-6' }` }>
                    <CommonTodoBlocksEdit
                        todo={ todo }
                        close={ setDefaultMode }
                        onInsert={ onInsert }
                        onUpdate={ onUpdate }
                        onDelete={ onDelete }
                    />
                </div>
            }
        </>
    )
};

export default TodoBlocks;