import { IStateDiagram } from './interfaces';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DrawIoEmbed, DrawIoEmbedRef, EventExport } from 'react-drawio';
import { createStateDiagramXml } from './domain/state-diagram-provider';
import { copyImageToClipboard, downloadImage } from '../../../../../common/domain/io-provider';
import { getStateDiagramFromStorage, setStateDiagramToStorage } from './domain/state-diagram-storage';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IStateDiagram = {
        "initialState": "start",
        "finalStates": ["reservation_closed", "reservation_cancelled"],
        "states": [
            { "id": "start" },
            { "id": "check_inventory", "name": "Check if car is in inventory" },
            { "id": "confirm", "name": "Confirm Reservation" },
            { "id": "print_contract", "name": "Print Contract" },
            { "id": "debit_customer", "name": "Debit Customer" },
            { "id": "close_reservation", "name": "Close Reservation and print invoice" },
            { "id": "cancel_reservation", "name": "Cancel Reservation" },
            { "id": "reservation_closed" },
            { "id": "reservation_cancelled" }
        ],
        "transitions": [
            { "from": "start", "to": "check_inventory", "event": "Car reservation made" },
            { "from": "check_inventory", "to": "confirm", "event": "Car is in Inventory" },
            { "from": "check_inventory", "to": "cancel_reservation", "event": "Car is not in Inventory" },
            { "from": "confirm", "to": "print_contract", "event": "Pick up a car" },
            { "from": "print_contract", "to": "debit_customer", "event": "Return a car" },
            { "from": "debit_customer", "to": "close_reservation", "event": "Get payment" },
            { "from": "close_reservation", "to": "reservation_closed", "event": "Reservation completed" },
            { "from": "cancel_reservation", "to": "reservation_cancelled", "event": "Reservation cancelled" }
        ]
    }
;

const StateDiagramHome = () => {

    const drawIoRef = useRef<DrawIoEmbedRef>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IStateDiagram>(initialData);

    useEffect(() => {
        if(!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onDiagramSave = async (data: EventExport) => {
        downloadImage(data.data, 'state-diagram.png');
        await copyImageToClipboard(data.data);
    };

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    useEffect(() => {
        const storageData = getStateDiagramFromStorage();
        if(!storageData) return;

        if(textAreaRef.current) {
            textAreaRef.current.value = storageData;
        }

        let parsed: IStateDiagram|null = null;

        try{
            parsed = JSON.parse(storageData) as IStateDiagram;
        }
        catch (error) {
            console.log(error);
        }

        if(!parsed) return;

        setData(parsed);
    }, []);

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setStateDiagramToStorage(evt.target.value);
    };

    return(
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">דיאגרמת מצבים</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    className="border rounded w-full p-2"
                    onChange={ onChange }
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={ {
                         height: UI_HEIGHT,
                     } }>
                    <DrawIoEmbed
                        ref={ drawIoRef }
                        urlParameters={ {
                            // ui: 'kennedy',

                            // Shows a 'Loading…' spinner while waiting
                            // for the diagram data to load in embed mode.
                            spin: true,

                            // Specifies whether libraries should be enabled in embed mode.
                            // The default is disabled.
                            // libraries: true,


                            noExitBtn: true,
                        } }
                        exportFormat={ 'png' }
                        onExport={ onDiagramSave }
                        xml={ createStateDiagramXml(data) }
                    />
                </div>
            </div>

        </div>
    )
};

export default StateDiagramHome;
