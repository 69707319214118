import { IPlant } from '../../../flowers-interfaces';
import { calculateNextWateringDate } from '../../../domain/flowers-provider';
import { getWeather } from '../../../domain/weather-provider';
import { startOfDay } from 'date-fns';
import { getLastWaterHistoryItem, updatePlantNextWateringDate } from '../../../data/flowers-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useFlowersStore } from '../../../data/flowers-store';

interface IGetNextWateringBtn {
    plant: IPlant;
}

const GetNextWateringBtn = ({ plant }: IGetNextWateringBtn) => {

    const setPlantsData = useFlowersStore(store => store.setPlantsData);

    const onClick = async () => {

        const waterLog = await getLastWaterHistoryItem(plant.plant_id);

        const next_watering = waterLog?.date_time ? calculateNextWateringDate(
            waterLog.date_time,
            plant.plantType,
            plant.location,
            getWeather(),
        ) : startOfDay(new Date()).getTime();

        const response = await updatePlantNextWateringDate(plant.plant_id, next_watering);

        if(!response) {
            showToast('Update plant next watering date error.');
            return;
        }

        setPlantsData(response);
    };

    return (
        <button
            type="button"
            title="Get Next Watering Date"
            onClick={ onClick }>
            💦
        </button>
    )
};

export default GetNextWateringBtn;