import { useFinanceStore } from '../../../data/finance-store';
import { ChangeEvent } from 'react';
import CarsDropDown from '../cars-drop-down';
import { Car } from '../../../finance-interfaces';

const getFilerYears = (years: number[]) => {
    const set = new Set(years || []);
    set.add((new Date()).getFullYear());
    return Array.from(set).sort((year1, year2) => year2 - year1);
};

const GasListFilters = () => {

    const gasData = useFinanceStore(store => store.gasData);
    const years = getFilerYears(gasData?.years);
    const sum = Math.round(gasData?.gasItems?.reduce((sum, gas) => sum + gas.GasAmount, 0) ?? 0);

    const gasYear = useFinanceStore(store => store.gasYear);
    const setGasYear = useFinanceStore(store => store.setGasYear);

    const gasFilterCar = useFinanceStore(store => store.gasFilterCar);
    const setGasFilterCar = useFinanceStore(store => store.setGasFilterCar);

    const onYearChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) ||  (new Date()).getFullYear();
        setGasYear(value);
    };

    const onFilterCarSelect = (car: Car) => {
        setGasFilterCar(car);
    };

    return (
        <div className="mb-4 flex items-center">
            <select
                className={ `rounded bg-white border px-4 py-2 mr-4 mb-2 lg:mb-0` }
                value={ gasYear }
                onChange={ onYearChange }>
                {
                    years.map(year => {
                        return (
                            <option
                                key={ year }>
                                { year }
                            </option>
                        )
                    })
                }
            </select>

            <CarsDropDown
                car={ gasFilterCar }
                onSelect={ onFilterCarSelect }
                hideOptional={ true }
            />

            <div className="ml-auto">{ sum } ₪</div>
        </div>
    )
};

export default GasListFilters;