import { useFlowersStore } from '../../../data/flowers-store';
import WaterLog from './water-log';
import { useCallback, useEffect, useState } from 'react';
import { getWaterLogsData } from '../../../data/flowers-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import Preloader from '../../../../../common/ui/preloader';
import NoLogsScreen from '../no-logs-screen';
import { endOfDay } from 'date-fns';

const WaterLogsList = () => {

    const [loading, setLoading] = useState(true);

    const waterLogsData = useFlowersStore(store => store.waterLogsData);
    const setWaterLogsData = useFlowersStore(store => store.setWaterLogsData);
    const waterLogsDate = useFlowersStore(store => store.waterLogsDate);
    const isTodayChosen = endOfDay(new Date(waterLogsDate)).getTime() === endOfDay(new Date()).getTime();

    const onUpdate = useCallback(async () => {
        setLoading(true);

        const response = await getWaterLogsData(waterLogsDate);

        setLoading(false);

        if(!response) {
            showToast('Get water logs error.');
            return;
        }

        setWaterLogsData(response);
    }, [waterLogsDate, setWaterLogsData]);

    useEffect(() => {
        (async () => {
            await onUpdate();
        })();
    }, [onUpdate]);

    const logsCount = waterLogsData?.plants?.length || 0;

    return (
        <div>
            { loading && <Preloader size={ 25 } /> }

            <>
                {
                    !loading &&
                    <>
                        {
                            logsCount > 0 &&
                            waterLogsData.plants.map(plant => {
                                return (
                                    <WaterLog
                                        key={ plant.plant_id }
                                        plant={ plant }
                                        onUpdate={ onUpdate }
                                    />
                                )
                            })
                        }

                        {
                            logsCount <= 0 && isTodayChosen && <NoLogsScreen />
                        }

                        {
                            logsCount <= 0 && !isTodayChosen &&
                            <div className="flex justify-center items-start px-4 text-slate-500 border border-slate-300 rounded bg-slate-50">
                                <p className="my-10 text-center">Нечего поливать 🌹</p>
                            </div>
                        }
                    </>
                }
            </>
        </div>
    )
};

export default WaterLogsList;