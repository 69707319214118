const RootScreen = () => {
    return (
        <div className="grid md:grid-cols-3 items-center justify-center gap-4 my-10">
            <img
                className="rounded-xl max-w-full"
                width="500px"
                src="/img/openu/openu-1.webp"
                alt=""
            />

            <img
                className="rounded-xl max-w-full"
                width="500px"
                src="/img/openu/openu-2.webp"
                alt=""
            />

            <img
                className="rounded-xl max-w-full"
                width="500px"
                src="/img/openu/openu-3.webp"
                alt=""
            />

            <img
                className="rounded-xl max-w-full"
                width="500px"
                src="/img/openu/openu-4.webp"
                alt=""
            />

            <img
                className="rounded-xl max-w-full"
                width="500px"
                src="/img/openu/openu-5.webp"
                alt=""
            />

            <img
                className="rounded-xl max-w-full"
                width="500px"
                src="/img/openu/openu-6.webp"
                alt=""
            />

            <img
                className="rounded-xl max-w-full"
                width="500px"
                src="/img/openu/openu-7.webp"
                alt=""
            />

            <img
                className="rounded-xl max-w-full"
                width="500px"
                src="/img/openu/openu-8.webp"
                alt=""
            />

            <img
                className="rounded-xl max-w-full"
                width="500px"
                src="/img/openu/openu-9.webp"
                alt=""
            />
        </div>
    )
};

export default RootScreen;