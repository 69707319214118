import { ChangeEvent } from 'react';
import { Car } from '../../finance-interfaces';

interface ICarsDropDown {
    car: Car;
    onSelect: (car: Car) => void;
    classes?: string;
    hideOptional?: boolean;
}

const CarsDropDown = ({ car, onSelect, classes, hideOptional }: ICarsDropDown) => {

    const onChange = (evt: ChangeEvent<HTMLSelectElement>) => {
        const value = Number(evt.target.value) || 0;
        onSelect(value);
    };

    return (
        <select
            className={ `border rounded px-4 py-2 bg-white ${ classes }` }
            value={ car }
            onChange={ onChange }>
            {
                !hideOptional &&
                <option value={ Car.NotSelected }>Select Car</option>
            }
            <option value={ Car.Hyundai }>Hyundai</option>
            <option value={ Car.Jaecoo }>Jaecoo</option>
        </select>
    )
};

export default CarsDropDown;