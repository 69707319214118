import { IDialogueDiagramDataItem } from './interfaces';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DrawIoEmbed, DrawIoEmbedRef, EventExport } from 'react-drawio';
import { createDialogueDiagramXml } from './domain/dialogue-diagram-provider';
import { copyImageToClipboard, downloadImage } from '../../../../../common/domain/io-provider';
import { getDialogueDiagramFromStorage, setDialogueDiagramToStorage } from './domain/dialogue-diagram-storage';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IDialogueDiagramDataItem[] = [
    {
        "id": "1",
        "label": "מסך ראשי",
        "children": ["2", "3", "4"]
    },
    {
        "id": "2",
        "label": "תפריט תלמידים",
        "children": ["2.1", "2.2"],
        "links": ["3"]
    },
    {
        "id": "2.1",
        "label": "מערכת שעות",
        "children": []
    },
    {
        "id": "2.2",
        "label": "ציונים",
        "children": []
    },
    {
        "id": "3",
        "label": "תפריט מורים",
        "children": ["3.1"],
        "links": ["2"]
    },
    {
        "id": "3.1",
        "label": "ניהול כיתות",
        "children": []
    },
    {
        "id": "4",
        "label": "הגדרות",
        "children": [],
        "links": ["1"]
    }
];

const DialogueDiagramHome = () => {

    const drawIoRef = useRef<DrawIoEmbedRef>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IDialogueDiagramDataItem[]>(initialData);

    useEffect(() => {
        if(!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onDiagramSave = async (data: EventExport) => {
        downloadImage(data.data, 'dialogue-diagram.png');
        await copyImageToClipboard(data.data);
    };

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    useEffect(() => {
        const storageData = getDialogueDiagramFromStorage();
        if(!storageData) return;

        if(textAreaRef.current) {
            textAreaRef.current.value = storageData;
        }

        let parsed: IDialogueDiagramDataItem[]|null = null;

        try{
            parsed = JSON.parse(storageData) as IDialogueDiagramDataItem[];
        }
        catch (error) {
            console.log(error);
        }

        if(!parsed) return;

        setData(parsed);
    }, []);

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setDialogueDiagramToStorage(evt.target.value);
    };

    return(
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">עץ התפריטים (מסכים)</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>

                <p className="mx-4 text-sky-700 text-sm">Мы берем из DFD все афикей келет и пелет и меаргеним отам бе тох масахим.</p>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    className="border rounded w-full p-2"
                    onChange={ onChange }
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={ {
                         height: UI_HEIGHT,
                     } }>
                    <DrawIoEmbed
                        ref={ drawIoRef }
                        urlParameters={ {
                            // ui: 'kennedy',

                            // Shows a 'Loading…' spinner while waiting
                            // for the diagram data to load in embed mode.
                            spin: true,

                            // Specifies whether libraries should be enabled in embed mode.
                            // The default is disabled.
                            // libraries: true,


                            noExitBtn: true,
                        } }
                        exportFormat={ 'png' }
                        onExport={ onDiagramSave }
                        xml={ createDialogueDiagramXml(data) }
                    />
                </div>
            </div>

        </div>
    )
};

export default DialogueDiagramHome;
