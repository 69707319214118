import Actions from '../../../../../common/ui/actions';
import { useState } from 'react';
import { EditIcon } from '../../../../../common/ui/icons';
import { IFoodGoal } from '../../../food-interfaces';
import DeleteFoodGoal from '../actions/delete-food-goal';
import UpsertFoodGoal from '../actions/upsert-food-goal';
import { format } from 'date-fns';

interface IFoodGoalProps {
    foodGoal: IFoodGoal;
    index: number;
}

enum FoodGoalMode {
    Default = 0,
    Edit = 1,
}

const FoodGoal = ({ foodGoal, index }: IFoodGoalProps) => {

    const [mode, setMode] = useState<FoodGoalMode>(FoodGoalMode.Default);

    const startEdit = () => {
        setMode(FoodGoalMode.Edit);
    };

    const stopEdit = () => {
        setMode(FoodGoalMode.Default);
    };

    return (
        <div className="border-b pb-2 mb-2">
            {
                mode === FoodGoalMode.Default &&
                (
                    <div className="flex items-start">
                        <div className="mr-4">{ index + 1 }.</div>

                        <div className="flex flex-col w-full">
                            <div className="flex space-between">

                                <button
                                    className="w-full text-left"
                                    onClick={ startEdit }
                                    type="button">{ format(foodGoal.start_date, 'dd MMM, yyyy') } - { foodGoal.weight } kg</button>

                                <Actions classes="finance-business__actions" mr="mr-0" gridSize={ 2 }>
                                    <DeleteFoodGoal foodGoal={ foodGoal } />

                                    <button
                                        title="Edit Food Goal"
                                        className="food__edit-goal flex bg-slate-200 rounded mx-1 text-left justify-center items-center whitespace-nowrap action-btn"
                                        onClick={ startEdit }
                                        type="button">
                                        <EditIcon color={ '#6e7781' } size={ 20 } />
                                    </button>
                                </Actions>
                            </div>

                            {
                                foodGoal.description &&
                                <div className="text-gray-500 text-xs">{ foodGoal.description }</div>
                            }
                        </div>
                    </div>
                )
            }

            {
                mode === FoodGoalMode.Edit &&
                <UpsertFoodGoal
                    type="edit"
                    foodGoal={ foodGoal }
                    close={ stopEdit }
                />
            }
        </div>
    )
};

export default FoodGoal;