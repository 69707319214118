import TopPanel from './ui/top-panel/top-panel';
import { useEffect } from 'react';
import { useBlocksYearStore } from './data/blocks-year-store';
import { createYearBlocksCalendar } from './domain/blocks-year-provider';
import YearView from './ui/year-view/year-view';
import { getBlocksYearData } from './data/blocks-year-data-service';
import { endOfYear, startOfYear } from 'date-fns';
import CommonBlocksYearTodoEditForm from '../../common/ui/blocks-year/common-blocks-todo-edit';

const YearBlocksHome = () => {

    const currentYear = useBlocksYearStore(store => store.currentYear);
    const setData = useBlocksYearStore(store => store.setData);
    const openedEditTodo = useBlocksYearStore(store => store.openedEditTodo);
    const setOpenedEditTodo = useBlocksYearStore(store => store.setOpenedEditTodo);
    const selectedTag= useBlocksYearStore(store => store.selectedTag);
    const data = useBlocksYearStore(store => store.data);

    useEffect(() => {

        (async () => {
            const date = new Date(currentYear, 0, 1); // January is 0

            const fromDate = startOfYear(date).getTime();
            const toDate = endOfYear(date).getTime();
            const response = await getBlocksYearData(fromDate, toDate, selectedTag?.tag_id ?? 0);

            const result = createYearBlocksCalendar(date.getFullYear(), response);
            setData(result);
        })();

    }, [currentYear, setData, selectedTag]);

    const blocksEditTodoClose = () => {
        setOpenedEditTodo(null);
    };

    return (
        <>
            <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">
                <div className="overflow-auto px-6 py-4 flex-1 flex flex-col w-full">
                    <TopPanel/>
                    <YearView/>
                </div>
            </div>

            {
                openedEditTodo &&
                <CommonBlocksYearTodoEditForm
                    onClose={ blocksEditTodoClose }
                    currentYear={ currentYear }
                    tags={ data?.tags ?? [] }
                    setData={ setData }
                    tag_id={ selectedTag?.tag_id ?? 0 }
                    openedEditTodo={ openedEditTodo }
                />
            }
        </>
    )

};

export default YearBlocksHome;