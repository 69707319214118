import { IModuleStructure } from './interfaces';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DrawIoEmbed, DrawIoEmbedRef, EventExport } from 'react-drawio';
import { createStructureChartXml } from './domain/structure-chart-provider';
import { copyImageToClipboard, downloadImage } from '../../../../../common/domain/io-provider';
import { getStructureDiagramFromStorage, setStructureDiagramToStorage } from './domain/structure-diagram-storage';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IModuleStructure = {
        "systemName": "Sample System",
        "rootModule": {
            "id": "M1",
            "name": "Main System",
            "children": [
                {
                    "id": "M2",
                    "name": "User Management",
                    "children": [
                        {
                            "id": "M3",
                            "name": "Get Valid User Credentials",
                            "children": [],
                            "dataFlowIn": [
                                {
                                    "from": "M2",
                                    "to": "M3",
                                    "label": "User Credentials Input"
                                }
                            ],
                            "dataFlowOut": []
                        },
                        {
                            "id": "M4",
                            "name": "Make Authentication Result",
                            "children": [],
                            "dataFlowOut": [
                                {
                                    "from": "M4",
                                    "to": "M2",
                                    "label": "Authentication Status"
                                }
                            ]
                        },
                        {
                            "id": "M5",
                            "name": "Put Login Session",
                            "children": [],
                            "controlFlow": [
                                {
                                    "from": "M5",
                                    "to": "M2",
                                    "condition": "Success",
                                    "label": "Session Created"
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": "M6",
                    "name": "User Registration",
                    "children": [
                        {
                            "id": "M7",
                            "name": "Get Valid New User Data",
                            "children": [],
                            "dataFlowIn": [
                                {
                                    "from": "M6",
                                    "to": "M7",
                                    "label": "New User Information"
                                }
                            ]
                        },
                        {
                            "id": "M8",
                            "name": "Make User Registration",
                            "children": [],
                            "dataFlowOut": [
                                {
                                    "from": "M8",
                                    "to": "M6",
                                    "label": "User Registered"
                                }
                            ]
                        },
                        {
                            "id": "M9",
                            "name": "Put User Record",
                            "children": [],
                            "controlFlow": [
                                {
                                    "from": "M9",
                                    "to": "M6",
                                    "label": "User Data Stored"
                                }
                            ]
                        }
                    ]
                },
                {
                    "id": "M10",
                    "name": "Payment Processing",
                    "children": [
                        {
                            "id": "M11",
                            "name": "Get Valid Payment Request",
                            "children": [],
                            "dataFlowIn": [
                                {
                                    "from": "M10",
                                    "to": "M11",
                                    "label": "Payment Details"
                                }
                            ]
                        },
                        {
                            "id": "M12",
                            "name": "Make Payment Transaction",
                            "children": [],
                            "dataFlowOut": [
                                {
                                    "from": "M12",
                                    "to": "M10",
                                    "label": "Transaction Approved/Declined"
                                }
                            ]
                        },
                        {
                            "id": "M13",
                            "name": "Put Payment Record",
                            "children": [],
                            "controlFlow": [
                                {
                                    "from": "M13",
                                    "to": "M10",
                                    "label": "Transaction Saved"
                                }
                            ]
                        }
                    ],
                    "loops": true
                }
            ]
        }
    }
;

const StructureChartHome = () => {

    const drawIoRef = useRef<DrawIoEmbedRef>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IModuleStructure>(initialData);

    useEffect(() => {
        if(!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onDiagramSave = async (data: EventExport) => {
        downloadImage(data.data, 'structure-chart.png');
        await copyImageToClipboard(data.data);
    };

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    useEffect(() => {
        const storageData = getStructureDiagramFromStorage();
        if(!storageData) return;

        if(textAreaRef.current) {
            textAreaRef.current.value = storageData;
        }

        let parsed: IModuleStructure|null = null;

        try{
            parsed = JSON.parse(storageData) as IModuleStructure;
        }
        catch (error) {
            console.log(error);
        }

        if(!parsed) return;

        setData(parsed);
    }, []);

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setStructureDiagramToStorage(evt.target.value);
    };

    return(
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">עץ מודולים</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>

                <p className="mx-4 text-sky-700 text-sm">Каждый процесс из DFD идет в первый уровень. Затем каждый митпарек ле модуль келет (get - стрелочка вниз), ибуд (make - стрелочка вверх) ве пелет (put - стрелочка вверх). И бдикот ле ец модулим.</p>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    className="border rounded w-full p-2"
                    onChange={ onChange }
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={ {
                         height: UI_HEIGHT,
                     } }>
                    <DrawIoEmbed
                        ref={ drawIoRef }
                        urlParameters={ {
                            // ui: 'kennedy',

                            // Shows a 'Loading…' spinner while waiting
                            // for the diagram data to load in embed mode.
                            spin: true,

                            // Specifies whether libraries should be enabled in embed mode.
                            // The default is disabled.
                            // libraries: true,


                            noExitBtn: true,
                        } }
                        exportFormat={ 'png' }
                        onExport={ onDiagramSave }
                        xml={ createStructureChartXml(data) }
                    />
                </div>
            </div>

        </div>
    )
};

export default StructureChartHome;
