import { IListBoxWithSearchGroup } from '../interfaces';
import ListBoxWithSearchGroupItem from './item';

interface IListBoxWithSearchGroupProps {
    selectedItemId: string|number;
    group: IListBoxWithSearchGroup;
    onSelect: (groupId: number|string, itemId: number|string) => void;
}

const ListBoxWithSearchGroup = ({ selectedItemId, group, onSelect }: IListBoxWithSearchGroupProps) => {
    return (
        <div>
            <div className="font-bold">{ group.title }</div>

            <div className="flex flex-col mx-4">
                {
                    group.items.map(item => {
                        return (
                            <ListBoxWithSearchGroupItem
                                key={ `${ group.id }-${ item.id }` }
                                selectedItemId={ selectedItemId }
                                item={ item }
                                groupId={ group.id }
                                onSelect={ onSelect }
                            />
                        )
                    })
                }
            </div>
        </div>
    )
};

export default ListBoxWithSearchGroup;