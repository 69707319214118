import { SunIcon } from '../../../../../common/ui/icons';
import { useBlocksYearStore } from '../../../data/blocks-year-store';

const TodayButton = () => {

    const setCurrentYear = useBlocksYearStore(store => store.setCurrentYear);

    const jumpToday = () => {
        setCurrentYear((new Date()).getFullYear());
    };

    return (
        <button
            onClick={ jumpToday }
            className="flex items-center"
            type="button">
            <SunIcon
                color={ '#d99b00' }
                classes="mr-1"
            />
            Today
        </button>
    )
};

export default TodayButton;