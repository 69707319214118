interface ICalculateFrom100g {
    calories: number;
    protein: number;
    fat: number;
    carbohydrates: number;
    weight_gram: number;
    callback: (
        caloriesUpdated: number,
        proteinUpdated: number,
        fatUpdated: number,
        carbohydratesUpdated: number,
    ) => void;
}

const CalculateFrom100g = ({
                               callback,
                               calories,
                               protein,
                               fat,
                               carbohydrates,
                               weight_gram,
                           } : ICalculateFrom100g) => {
    const onClick = () => {

        const caloriesUpdated = weight_gram * calories / 100;
        const proteinUpdated = weight_gram * protein / 100;
        const fatUpdated = weight_gram * fat / 100;
        const carbohydratesUpdated = weight_gram * carbohydrates / 100;

        callback(caloriesUpdated, proteinUpdated, fatUpdated, carbohydratesUpdated);
    };

    return (

        <button
            type="button"
            className="text-left text-sm underline text-sky-800 mt-2"
            onClick={ onClick }
        >Calculate from 100g</button>
    )
};

export default CalculateFrom100g;