import { MoveHereIcon, PreloaderIcon } from '../../../../../common/ui/icons';
import { ITodo } from '../../../planner-interfaces';
import { sendCloseActionsEvent } from '../../../../../common/ui/actions';
import { useState } from 'react';
import { usePlannerStore } from '../../../data/planner-store';
import { moveSelected } from '../../../data/planner-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { combineProjectData } from '../../../domain/planner-provider';

interface IMoveMultipleToTodoBtn {
    todo: ITodo;
}

const MoveMultipleToTodoBtn = ({ todo }: IMoveMultipleToTodoBtn) => {

    const [loading, setLoading] = useState(false);

    const setCombinedProject = usePlannerStore(state => state.setCombinedProject);
    const multipleSelectTodos = usePlannerStore(state => state.multipleSelectTodos);

    const onClick = async () => {

        setLoading(false);

        const response = await moveSelected(todo.todo_id, multipleSelectTodos);

        if(!response) {
            showToast('Move selected error.');
            return;
        }

        setCombinedProject(combineProjectData(response));

        sendCloseActionsEvent();
    };

    return (
        <>
            {
                loading &&
                <div className="todo__move-multiple-to-todo-btn flex bg-slate-200 rounded mx-1 justify-center items-center action-btn">
                    <PreloaderIcon size={ 20 } color={ '#586180' } />
                </div>
            }

            {
                !loading &&
                <button
                    type="button"
                    className="todo__move-multiple-to-todo-btn flex bg-slate-200 rounded mx-1 justify-center items-center action-btn"
                    rel="noreferrer"
                    onClick={ onClick }
                    title="Move Selected Todos Here">
                    <MoveHereIcon color={ '#586180' } />
                </button>
            }
        </>
    )
};

export default MoveMultipleToTodoBtn;