import { CalendarIcon } from '../../../../common/ui/icons';
import { IFoodCalendarYear } from '../../food-interfaces';
import PrevYearButton from './actions/prev-year-button';
import NextYearButton from './actions/next-year-button';
import TodayButton from './actions/today-button';
import { useFoodStore } from '../../data/food-store';

interface IFoodCalendarTitle {
    calendarData: IFoodCalendarYear|null;
}

const FoodCalendarTitle = ({ calendarData }: IFoodCalendarTitle) => {

    const currentCalendarYear = useFoodStore(store => store.currentCalendarYear);

    return (
        <>
            {
                calendarData &&
                <div className="flex flex-col md:flex-row items-center justify-between">
                    <div className="text-xl xl:text-3xl flex items-center text-slate-700">
                        <CalendarIcon classes="mr-2"/>
                        Food Calendar: { currentCalendarYear }
                    </div>

                    <div className="calendar__actions flex items-center mx-6">
                        <TodayButton />

                        <div className="flex items-center mx-6">
                            <PrevYearButton />

                            <NextYearButton />
                        </div>
                    </div>

                    <div className="flex items-center">
                        <span className="ml-4">
                            <strong>Норма: </strong>
                            { calendarData.dailyCalories.toLocaleString() } ккал
                        </span>

                        <span className="ml-4">
                            <strong>Avg: </strong>
                            { Math.round(calendarData.avgCalories) } ккал
                        </span>
                    </div>
                </div>
            }
        </>
    )
};

export default FoodCalendarTitle;