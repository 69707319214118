const NoLogsScreen = () => {
    return (
        <div>
            <div className="grid md:grid-cols-3 items-center justify-center gap-4 my-10">
                <img
                    className="rounded-xl max-w-full"
                    width="500px"
                    src="/img/garden/garden-1.webp"
                    alt=""
                />

                <img
                    className="rounded-xl max-w-full"
                    width="500px"
                    src="/img/garden/garden-2.webp"
                    alt=""
                />

                <img
                    className="rounded-xl max-w-full"
                    width="500px"
                    src="/img/garden/garden-3.webp"
                    alt=""
                />
            </div>

            <p className="mt-4 text-center">Все полито!</p>
        </div>
    )
};

export default NoLogsScreen;
/*

<div
    className="flex flex-col items-center justify-center text-center rounded text-slate-400 bg-slate-50 border border-slate-300 rounded px-4 py-6">

    {/!*<svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 512.001 512.001" width="300px">
                        <path fill="#60dd4d" d="m324.336 186.207-25.103-25.101-25.483 25.482v-65.525h-35.501v65.525l-25.482-25.482-25.103 25.101 50.585 50.587v28.902h35.501v-28.902z"/>
                        <path fill={ "#00c47a" }
                              d="m299.233 161.106-25.483 25.482v-65.525h-17.751v144.633h17.751v-28.902l50.586-50.587z"/>
                        <path fill={ "#c20cff" }
                              d="M192.231 22.778v52.265c0 35.162 28.606 63.77 63.77 63.77s63.77-28.606 63.77-63.77V22.778h-127.54z"/>
                        <path fill={ "#8e3da4" }
                              d="M255.999 22.778v116.035c35.162 0 63.77-28.606 63.77-63.77V22.778h-63.77z"/>
                        <path fill={ "#40c8b5" }
                              d="m131.494 210.509-25.483 25.482v-62.334H70.51v62.334l-25.482-25.482-25.103 25.102 68.336 68.337 68.335-68.337z"/>
                        <path fill={ "#ff318f" }
                              d="M24.491 63.867v52.265c0 35.162 28.606 63.77 63.77 63.77s63.77-28.606 63.77-63.77V63.867H24.491z"/>
                        <path fill={ "#bf256b" }
                              d="M87.963 63.867v116.027c.099 0 .198.007.297.007 35.162 0 63.77-28.606 63.77-63.77V63.867H87.963z"/>
                        <path fill={ "#40c8b5" }
                              d="m466.972 210.509-25.482 25.482v-62.334h-35.501v62.334l-25.483-25.482-25.102 25.102 68.335 68.337 68.336-68.337z"/>
                        <path fill="#ff318f"
                              d="M359.969 63.867v52.265c0 35.162 28.607 63.77 63.77 63.77s63.771-28.606 63.771-63.77V63.867H359.969z"/>
                        <path fill={ "#bf256b" }
                              d="M423.441 63.867v116.027c.099 0 .198.007.297.007 35.162 0 63.771-28.606 63.771-63.77V63.867h-64.068z"/>
                        <path fill={ "#867e0c" }
                              d="M54.172 357.74h35.501v131.483H54.172zm368.157 0h35.501v131.483h-35.501z"/>
                        <path fill={ "#ffdb2d" }
                              d="m0 261.094 11.983 140.544c2.312 29.578 27.345 52.738 57.022 52.738h373.991c29.676 0 54.709-23.16 57.022-52.738l11.983-140.544H0z"/>
                        <path fill={ "#ffae33" }
                              d="M255.999 261.094v193.281h186.995c29.676 0 54.709-23.159 57.022-52.738L512 261.094H255.999z"/>
                        <circle cx="380.248" cy="357.74" r="17.75" fill={ "#bf7300" }/>
                        <circle cx="131.743" cy="357.74" r="17.75" fill={ "#bf7300" }/>
                        <circle cx="255.996" cy="357.74" r="17.75" fill={ "#bf7300" }/>
                    </svg>*!/ }

    <svg xmlns="http://www.w3.org/2000/svg" xmlSpace="preserve" viewBox="0 0 512 512" width="300px">
        <path fill="#6fd6a4" d="M239.873 162.573h32.254v150.521h-32.254z"/>
        <path fill="#3acc8f" d="M256 162.573h16.127v150.521H256z"/>
        <path fill="#ffb64c" d="m255.997 0-70.319 70.319 70.319 59.648 70.318-59.648z"/>
        <path fill="#ff9800" d="M255.997 0v129.967l70.318-59.648z"/>
        <path fill="#ff3d63"
              d="M255.997 89.801 153.858 25.964v50.601c0 56.32 45.82 102.139 102.139 102.139s102.139-45.82 102.139-102.139V25.964L255.997 89.801z"/>
        <path fill="#9f7953" d="M148.309 372.231 190.24 512h131.513l41.931-139.769z"/>
        <path fill="#896746" d="M255.997 372.231V512h65.756l41.931-139.769z"/>
        <path fill="#ff0536"
              d="M255.997 89.801v88.903c56.32 0 102.139-45.82 102.139-102.139V25.964L255.997 89.801z"/>
        <path fill="#81e26f"
              d="M247.962 245.873c-25.339-25.34-66.332-23.386-68.064-23.29l-14.419.796-.796 14.419c-.096 1.732-2.05 42.725 23.29 68.064 21.554 21.553 54.43 23.361 64.841 23.361 1.829 0 2.965-.056 3.224-.07l14.419-.796.796-14.419c.095-1.734 2.048-42.726-23.291-68.065z"/>
        <path fill="#60dc4d"
              d="m179.897 222.582-14.352.791L270.46 328.288l.791-14.352c.096-1.732 2.05-42.725-23.29-68.064-25.338-25.34-66.331-23.386-68.064-23.29z"/>
        <path fill="#3acc8f"
              d="m373.84 209.671-.796-14.419-14.419-.796c-2.261-.126-55.826-2.684-88.666 30.157-32.838 32.838-30.283 86.402-30.157 88.666l.796 14.419 14.419.796c.341.019 1.842.092 4.263.092 13.659 0 56.509-2.356 84.402-30.25 32.839-32.838 30.282-86.4 30.158-88.665z"/>
        <path fill="#00c37a"
              d="m373.84 208.805-.796-14.419-.391-.021-132.078 132.076.022.391 14.419.796c.341.019 1.842.092 4.263.092 13.659 0 56.509-2.356 84.402-30.25 32.84-32.838 30.283-86.401 30.159-88.665z"/>
        <path fill="#c8ac90" d="M121.606 296.967h268.787v107.515H121.606z"/>
        <path fill="#bc9a7b" d="M256 296.967h134.394v107.515H256z"/>
    </svg>

    <p className="mt-4">Все полито!</p>
</div>*/
