import {
    EntityElement,
    ProcessElement,
    DataStoreElement,
    IDataFlowDiagramData,
    DfdDiagramElement,
    IDataFlowDiagramDataFrom,
    IDataFlowDiagramDataTo,
} from '../interfaces';
import { v4 as uuidv4 } from 'uuid';

const ELEMENTS_MARGIN_Y = 200;
const ELEMENTS_MARGIN_X = 300;

const ENTITY_WIDTH = 150;
const ENTITY_HEIGHT = 80;

const PROCESS_WIDTH = 150;
const PROCESS_HEIGHT = 80;
const PROCESS_TOP_NUMBER_HEIGHT = 20;

const DATA_STORE_WIDTH = 180;
const DATA_STORE_HEIGHT = 40;
const DATA_NUMBER_WIDTH = 40;

const prepareData = (data: IDataFlowDiagramData) : DfdDiagramElement|null => {
    if(!data) return null;

    const dataProcesses = data.processes ?? [];
    const dataEntities = data.entities ?? [] ?? [];
    const dataDataStores = (data.dataStores ?? []);

    // Lets processes will be our cluster centers.
    // node_id ---> index of cluster the node belongs to (relevant process id)
    const nodeClusterMap = new Map<string, string>();
    const processesIds = new Set<string>();
    for(let i=0; i<dataProcesses.length; i++) {
        const processedData = dataProcesses[i];
        nodeClusterMap.set(processedData.id, processedData.id);
        processesIds.add(processedData.id);
    }

    // Create a map of node IDs that contains a set of connected nodeIDs.
    // node_id ---> set of connected node IDs.
    const adjacencyMap: Map<string, Set<string>> = new Map();
    for(let i=0; i<dataProcesses.length; i++) {
        const processedData = dataProcesses[i];
        for(const item of processedData.from) {
            const source = item.from;
            const target = processedData.id;

            if(!adjacencyMap.has(source)) {
                adjacencyMap.set(source, new Set());
            }

            if(!adjacencyMap.has(target)) {
                adjacencyMap.set(target, new Set());
            }

            adjacencyMap.get(source)?.add(target);
            adjacencyMap.get(target)?.add(source);

            if(!(processesIds.has(source) && processesIds.has(target))) {
                if(processesIds.has(source)) {
                    nodeClusterMap.set(target, source);
                }
                if(processesIds.has(target)) {
                    nodeClusterMap.set(source, target);
                }
            }
        }

        for(const item of processedData.to) {
            const target = item.to;
            const source = processedData.id;

            if(!adjacencyMap.has(source)) {
                adjacencyMap.set(source, new Set());
            }

            if(!adjacencyMap.has(target)) {
                adjacencyMap.set(target, new Set());
            }

            adjacencyMap.get(source)?.add(target);
            adjacencyMap.get(target)?.add(source);

            if(!(processesIds.has(source) && processesIds.has(target))) {
                if(processesIds.has(source)) {
                    nodeClusterMap.set(target, source);
                }
                if(processesIds.has(target)) {
                    nodeClusterMap.set(source, target);
                }
            }
        }
    }

    dataEntities.sort((entity1, entity2) => {
        const clusterId1 = nodeClusterMap.get(entity1.id); // it belongs to the process 1.0
        const clusterId2 = nodeClusterMap.get(entity2.id); // it belongs to the process 2.0

        if(!clusterId1) return 1;
        if(!clusterId2) return -1;

        const index1 = dataProcesses.findIndex(item => item.id === clusterId1);
        const index2 = dataProcesses.findIndex(item => item.id === clusterId2);

        if(index1 === -1) return 1;
        if(index2 === -1) return -1;

        return index1 - index2;
    });

    dataDataStores.sort((dataStore1, dataStore2) => {
        const clusterId1 = nodeClusterMap.get(dataStore1.id); // it belongs to the process 1.0
        const clusterId2 = nodeClusterMap.get(dataStore2.id); // it belongs to the process 2.0

        if(!clusterId1) return 1;
        if(!clusterId2) return -1;

        const index1 = dataProcesses.findIndex(item => item.id === clusterId1);
        const index2 = dataProcesses.findIndex(item => item.id === clusterId2);

        if(index1 === -1) return 1;
        if(index2 === -1) return -1;

        return index1 - index2;
    });

    // -----

    const entities: EntityElement[] = dataEntities.map(entity => {
        return {
            id: entity.id,
            name: entity.name,
            x: 0,
            y: 0,
            width: ENTITY_WIDTH,
            height: ENTITY_HEIGHT,
            data: entity,
        }
    });

    const processes: ProcessElement[] = dataProcesses.map((process, i) => {
        return {
            id: process.id,
            index: i,
            number: `${ i + 1 }.0`,
            name: process.name,
            x: 0,
            y: ELEMENTS_MARGIN_Y,
            width: PROCESS_WIDTH,
            height: PROCESS_HEIGHT,

            data: process,

            inputs: [],
            outputs: [],

            totalInputs: process.from.length,
            totalOutputs: process.to.length,
        }
    });

    const dataStores: DataStoreElement[] = dataDataStores.map((dataStore, i) => {
        return {
            id: dataStore.id,
            index: `D${ i + 1 }`,
            name: dataStore.name,
            x: 0,
            y: ELEMENTS_MARGIN_Y * 2,
            width: DATA_STORE_WIDTH,
            height: DATA_STORE_HEIGHT,
            data: dataStore,
        }
    });

    entities.forEach((entity, i) => {
        entity.x = i * ELEMENTS_MARGIN_X - ((entities.length - 1) * ELEMENTS_MARGIN_X) / 2;
    });

    processes.forEach((process, i) => {
        process.x = i * ELEMENTS_MARGIN_X - ((processes.length - 1) * ELEMENTS_MARGIN_X) / 2;
    });

    dataStores.forEach((dataStore, i) => {
        dataStore.x = i * ELEMENTS_MARGIN_X - ((dataStores.length - 1) * ELEMENTS_MARGIN_X) / 2;
    });

    // const edges: IDataFlowDiagramInputOutput[] = [];

    // Handle process inputs/outputs.
    for(const process of processes) {

        // Inputs from the process to entity or data store.
        const inputs: IDataFlowDiagramDataFrom[] = process.data.from || [];

        for(const input of inputs) {
            // From process to entity --------
            const entity = entities.find(item => item.id === input.from);
            if(entity) {
                const edge = {
                    text: input.label,
                    x1: 0.5,
                    y1: 0,
                    x2: 0.5,
                    y2: 1,
                    source: process.id,
                    target: entity.id,
                    isLong: false,
                };
                process.inputs.push(edge);
                // edges.push(edge);
                continue;
            }

            // From process to data store --------
            const dataStore = dataStores.find(item => item.id === input.from);
            if(dataStore) {
                const edge = {
                    text: input.label,
                    x1: 0.5,
                    y1: 1,
                    x2: 0.5,
                    y2: 0,
                    source: process.id,
                    target: dataStore.id,
                    isLong: false,
                };
                process.inputs.push(edge);
                // edges.push(edge);
                continue;
            }

            // From process to another process --------
            const _process = processes.find(item => item.id === input.from);
            if(_process) {
                const edge = {
                    text: input.label,
                    x1: process.index < _process.index ? 1 : 0,
                    y1: 0.5,
                    x2: process.index < _process.index ? 0 : 1,
                    y2: 0,
                    source: process.id,
                    target: _process.id,
                    isLong: Math.abs(process.index - _process.index) > 2,
                };
                process.inputs.push(edge);
                // edges.push(edge);
            }
        }

        // Outputs from the process to entity or data store.
        const outputs: IDataFlowDiagramDataTo[] = process.data.to || [];

        for(const output of outputs) {

            // From entity to process--------
            const entity = entities.find(item => item.id === output.to);
            if(entity) {
               /* const partWidth = 1 / process.totalOutputs;
                const i = process.inputs.length;
                const start = i * partWidth;
                const x1 = start + partWidth/2;*/

                const edge = {
                    text: output.label,
                    x1: 0.5,
                    y1: 1,
                    x2: 0.5,
                    y2: 0,
                    source: entity.id,
                    target: process.id,
                    isLong: false,
                };
                process.outputs.push(edge);
                // edges.push(edge);
                continue;
            }

            // From data store to process--------
            const dataStore = dataStores.find(item => item.id === output.to);
            if(dataStore) {
                const edge = {
                    text: output.label,
                    x1: 0.5,
                    y1: 0,
                    x2: 0.5,
                    y2: 1,
                    source: dataStore.id,
                    target: process.id,
                    isLong: false,
                };
                process.outputs.push(edge);
                // edges.push(edge);
                continue;
            }

            // From another process to our process--------
            const _process = processes.find(item => item.id === output.to);
            if(_process) {
                const edge = {
                    text: output.label,
                    x1: process.index < _process.index ? 0 : 1,
                    y1: 0.5,
                    x2: process.index < _process.index ? 1 : 0,
                    y2: 0.5,
                    source: _process.id,
                    target: process.id,
                    isLong: Math.abs(process.index - _process.index) > 2,
                };
                process.outputs.push(edge);
                // edges.push(edge);
            }
        }
    }


    return {
        id: uuidv4(),
        entities,
        processes,
        dataStores,
    };
};

export const createDfdDiagramXml = (data: IDataFlowDiagramData): string => {

    const preparedData = prepareData(data);
    if(!preparedData) return '';

    const initialXml = `
<mxfile host="draw.io">
  <diagram name="דיאגרמת בועות">
    <mxGraphModel background="#FFFFFF">
      <root>
        <mxCell id="0"/>
        <mxCell id="1" parent="0"/>
        
        ${ preparedData.entities.map(entity => renderEntity(entity)).join('') }
        ${ preparedData.processes.map(process => renderProcess(process)).join('') }
        ${ preparedData.dataStores.map(dataStore => renderDataStore(dataStore)).join('') }
        
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>`;

    return initialXml.trim();
};

const renderEntity = (entity: EntityElement) => {
    return `
        <mxCell 
            id="${ entity.id }" 
            value="${ entity.name }" 
            style="rounded=0;whiteSpace=wrap;html=1;fillColor=#ffffff;fontStyle=1;fontSize=12;spacingLeft=5;spacingRight=5;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ entity.x }" 
              y="${ entity.y }" 
              width="${ ENTITY_WIDTH }" 
              height="${ ENTITY_HEIGHT }" 
              as="geometry"
              />
        </mxCell>
    `;
};

const renderProcess = (process: ProcessElement) => {

    const arrows = [
        ...process.inputs,
        ...process.outputs,
    ];

    return `
        <!-- the box -->
        <mxCell 
            id="${ process.id }" 
            value="${ process.name }" 
            style="rounded=1;whiteSpace=wrap;html=1;fillColor=#ffffff;fontSize=12;spacingLeft=15;spacingRight=15;spacingTop=${ PROCESS_TOP_NUMBER_HEIGHT }" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ process.x }" 
              y="${ process.y }" 
              width="${ PROCESS_WIDTH }" 
              height="${ PROCESS_HEIGHT }" 
              as="geometry"
              />
        </mxCell>
        
        <!-- horizontal divider -->
        <mxCell 
            id="${ process.id + '-divider' }" 
            value="" 
            style="line;strokeWidth=1;fillColor=none;align=left;verticalAlign=middle;spacingTop=-1;spacingLeft=3;spacingRight=3;rotatable=0;strokeColor=inherit;" 
            parent="${ process.id }"
            vertex="1">
          <mxGeometry y="${ PROCESS_TOP_NUMBER_HEIGHT }" width="${ PROCESS_WIDTH }" height="8" as="geometry" />
        </mxCell>
        
        <mxCell 
            id="${ process.id + '-number' }" 
            value="${ process.number }" 
            style="text;fontStyle=1;fontSize=14;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;whiteSpace=wrap;html=1;" 
            parent="${ process.id }"  
            vertex="1">
          <mxGeometry y="${ 4 }" width="${ PROCESS_WIDTH }" height="${ PROCESS_TOP_NUMBER_HEIGHT }" as="geometry"/>
        </mxCell>
        
        ${
            arrows.map((arrow) => {
                // edgeStyle=elbowEdgeStyle;elbow=vertical;
                // edgeStyle=orthogonalEdgeStyle;
                return (
                    `<mxCell 
                             id="${ uuidv4() }" 
                             style="${ arrow.isLong ? '' : '' }jettySize=auto;html=1;exitX=${ arrow.x1 };exitY=${ arrow.y1 };exitDx=0;exitDy=0;entryX=${ arrow.x2 };entryY=${ arrow.y2 };entryDx=0;entryDy=0;endArrow=classic;endFill=1;
                             labelPosition=center;verticalLabelPosition=center;"
                             value="&lt;span style=&quot;font-size: 12px;&quot;&gt;${ arrow.text }&lt;/span&gt;"
                             parent="${ process.id }" 
                             target="${ arrow.target }" 
                             source="${ arrow.source }"
                             edge="1">
                          <mxGeometry relative="1" as="geometry"/>
                        </mxCell>
                                `
                )
            }).join('')
        }
        
        `;
};

const renderDataStore = (dataStore: DataStoreElement) => {
    return `
        <mxCell 
            id="${ dataStore.id }" 
            value="${ dataStore.name }" 
            style="rounded=0;whiteSpace=nowrap;html=1;fillColor=#ffffff;fontSize=12;spacingLeft=${ DATA_NUMBER_WIDTH + 5 };spacingRight=5;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ dataStore.x }" 
              y="${ dataStore.y }" 
              width="${ DATA_STORE_WIDTH }" 
              height="${ DATA_STORE_HEIGHT }" 
              as="geometry"
              />
        </mxCell>
        
        <!-- number like D1, D2, ... -->
        <mxCell 
            id="${ dataStore.id + '-number' }" 
            value="${ dataStore.index }" 
            style="text;fontStyle=1;fontSize=12;strokeColor=none;fillColor=none;align=center;verticalAlign=middle;spacingLeft=4;spacingRight=4;overflow=hidden;rotatable=0;whiteSpace=nowrap;html=1;" 
            parent="${ dataStore.id }"  
            vertex="1">
          <mxGeometry 
              width="${ DATA_NUMBER_WIDTH }" 
              height="${ DATA_STORE_HEIGHT }" 
              as="geometry"
          />
        </mxCell>
        
        <!-- vertical divider -->
        <mxCell 
            id="${ dataStore.id + '-divider' }" 
            value="" 
            style="line;strokeWidth=1;fillColor=none;align=left;verticalAlign=middle;spacingTop=-1;spacingLeft=3;spacingRight=3;rotatable=0;strokeColor=inherit;shape=line;direction=north;" 
            parent="${ dataStore.id }"
            vertex="1">
          <mxGeometry 
              x="${ DATA_NUMBER_WIDTH }" 
              y="0"
              width="2" 
              height="${ DATA_STORE_HEIGHT }" 
              as="geometry" 
          />
        </mxCell>
    `;
};
