import {
    ISequenceDiagram,
    ISequenceDiagramClassElement,
    ISequenceDiagramElement,
    ITreeNode,
    ISequenceDiagramMessage,
    ITree, IChildMapItem,
} from '../interfaces';
import { v4 as uuidv4 } from 'uuid';

const ACTOR_WIDTH = 30;
const ACTOR_HEIGHT = 60;

const CLASS_WIDTH = 150;
const CLASS_HEIGHT = 80;
const CLASS_MARGIN_X = 35;
const MARGIN_Y = 50;

const TIME_RECT_WIDTH = 30;
const TIME_RECT_HEIGHT = 50;

export const buildTree = (
    treeMap: Map<string, ITreeNode>,
    childMap: Map<string, IChildMapItem[]>,
    node: string,
    parent: ITreeNode | null = null
): ITreeNode => {

    if (treeMap.has(node)) {
        return treeMap.get(node)!;
    }

    const treeNode: ITreeNode = {
        id: uuidv4(),
        name: node,

        parent,
        children: [],
        childIndex: 0,

        x: 0,
        y: 0,
        width: TIME_RECT_WIDTH,
        height: TIME_RECT_HEIGHT,

        upConnectionLabel: '',
    };
    treeMap.set(node, treeNode);
    // nodes.push(treeNode);

    const children = childMap.get(node) || [];

    treeNode.children = children.map(({ to, label }, i) => {
        const childNode = buildTree(treeMap, childMap, to, treeNode);

        return {
            id: childNode.id,
            name: to,
            label,

            children: childNode.children,
            parent: treeNode,
            childIndex: i,

            width: TIME_RECT_WIDTH,
            height: TIME_RECT_HEIGHT,
            x: 0,
            y: 0,

            upConnectionLabel: label,
        };
    });

    /*// If no children, it's a leaf
    if (treeNode.children.length === 0) {
        // leaves.push(treeNode);
    }*/

    return treeNode;
};

export const buildSequenceTree = (messages: ISequenceDiagramMessage[]): ITree | null => {

    const treeMap: Map<string, ITreeNode> = new Map();
    const childMap: Map<string, IChildMapItem[]> = new Map();
    const allNodes = new Set<string>();

    // Collect all nodes and their children
    for(let i=0; i<messages.length; i++) {
        const { from, to, label } = messages[i];
        allNodes.add(from);
        allNodes.add(to);

        if (!childMap.has(from)) {
            childMap.set(from, []);
        }
        childMap.get(from)?.push({ to, label });
    }

    // Find root nodes: nodes that are never a 'to' destination.
    const rootNodesIds: string[] = [];
    for(const node of allNodes) {
        const found = messages.some(msg => msg.to === node);
        if(found) continue;
        rootNodesIds.push(node);
    }

    // Build trees for each root node
    if (rootNodesIds.length === 0) return null;

    const root = buildTree(treeMap, childMap, rootNodesIds[0]);

    return { root };
};

const setNodesDimensions = (
    classIdXMap: Map<string, number>,
    actorId: string,
    node: ITreeNode,
    topY: number
): number => {
    if (!node) return 0;

    node.x = classIdXMap.get(node.name) + (node.name === actorId ? 0 : CLASS_WIDTH / 2 - TIME_RECT_WIDTH / 2);
    node.y = topY;

    // It a leave.
    if (node.children.length === 0) {
        node.height = TIME_RECT_HEIGHT;
        return node.height;
    }

    let maxChildHeight = 0;
    let totalLeafCount = 0;

    let y = topY;
    for (const child of node.children) {

        const childHeight = setNodesDimensions(classIdXMap, actorId, child, y);

        maxChildHeight = Math.max(maxChildHeight, childHeight);

        totalLeafCount += (child.children.length === 0) ? 1 : 0;

        y += childHeight;
    }

    node.height = maxChildHeight * Math.max(1, totalLeafCount) + MARGIN_Y * (node.children.length - 1);

    return node.height;
};

const prepareData = (data: ISequenceDiagram) : ISequenceDiagramElement|null => {
    if(!data?.actor) return null;

    const result: ISequenceDiagramElement = {
        actor: {
            id: data.actor.id,
            name: data.actor.name,
            x: 0,
            y: 0,
            width: ACTOR_WIDTH,
            height: ACTOR_HEIGHT,
        },
        classes: [],
        trees: [],
        treesHeight: 0,
    };

    let classX = CLASS_WIDTH;

    const classIdXMap = new Map<string, number>(); // class_id ---> x
    classIdXMap.set(data.actor.id, result.actor.x);

    // Create horizontals classes (screens) -----------
    for(const dataClass of data.classes) {
        result.classes.push({
            id: dataClass.id,
            name: dataClass.name,
            x: classX,
            y: 0,
            width: CLASS_WIDTH,
            height: CLASS_HEIGHT,
        });

        classIdXMap.set(dataClass.id, classX);

        classX += CLASS_WIDTH + CLASS_MARGIN_X;
    }

    // Create time block sections ----------------
    const blocks: ISequenceDiagramMessage[][] = [];

    let block: ISequenceDiagramMessage[] = [];
    for(let i=0; i<data.messages.length; i++) {
        const message = data.messages[i];
        if(message.from === data.actor.id) {
            block = [];
            blocks.push(block);
        }

        block.push(message);
    }

    // Build tree for each section --------
    const trees: ITree[] = [];
    for(const block of blocks) {
        const tree = buildSequenceTree(block);
        trees.push(tree);
    }

    let y = CLASS_HEIGHT + MARGIN_Y;
    for(let i=0; i<trees.length; i++) {
        const tree = trees[i];
        setNodesDimensions(classIdXMap, result.actor.id,tree.root,  y);
        y += tree.root.height + MARGIN_Y;
    }

    result.trees = trees;
    result.treesHeight = y;

    return result;
};

export const createSequenceDiagramXml = (data: ISequenceDiagram): string => {

    const preparedData = prepareData(data);
    if(!preparedData) return '';

    const initialXml = `
<mxfile host="draw.io">
  <diagram name="דיאגרמת רצף">
    <mxGraphModel background="#FFFFFF">
      <root>
        <mxCell id="0"/>
        <mxCell id="1" parent="0"/>
        
        <!-- actor dashed swimline -->
        <mxCell 
             id="${ uuidv4() }" 
             value="" 
             style="endArrow=none;dashed=1;html=1;strokeWidth=2;rounded=0;" 
             edge="1" 
             parent="1">
          <mxGeometry width="50" height="${ preparedData.treesHeight - preparedData.actor.height }" relative="1" as="geometry">
             
             <mxPoint 
                x="${ preparedData.actor.x + preparedData.actor.width / 2 }" 
                y="${ preparedData.actor.y + preparedData.actor.height }"  
                as="sourcePoint" 
             />
             
            <mxPoint 
                x="${ preparedData.actor.x + preparedData.actor.width / 2 }" 
                y="${ preparedData.actor.y + preparedData.treesHeight }"  
                as="targetPoint" 
            />
          </mxGeometry>
        </mxCell>
         
        <!-- actor -->
        <mxCell 
            id="${ preparedData.actor.id }" 
            value="${ preparedData.actor.name }" 
            style="shape=umlActor;verticalLabelPosition=bottom;verticalAlign=top;html=1;outlineConnect=0;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ preparedData.actor.x }" 
              y="${ preparedData.actor.y }" 
              width="${ preparedData.actor.width }" 
              height="${ preparedData.actor.height }" 
              as="geometry" 
          />
        </mxCell>

        ${ preparedData.classes.map(classElement => renderClass(classElement, preparedData.treesHeight)).join('') }
        ${ preparedData.trees.map(tree => renderTree(tree)).join('') }
        
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>`;

    return initialXml.trim();
};

const renderClass = (classElement: ISequenceDiagramClassElement, swimlineHeight: number): string => {

    return (
        `
          <!-- dashed swimline -->
          <mxCell 
             id="${ uuidv4() }" 
             value="" 
             style="endArrow=none;dashed=1;html=1;strokeWidth=2;rounded=0;" 
             edge="1" 
             parent="1">
          <mxGeometry width="50" height="${ swimlineHeight }" relative="1" as="geometry">
             
             <mxPoint 
                x="${ classElement.x + classElement.width / 2 }" 
                y="${ classElement.y }"  
                as="sourcePoint" 
             />
             
            <mxPoint 
                x="${ classElement.x + classElement.width / 2 }" 
                y="${ classElement.y + swimlineHeight }"  
                as="targetPoint" 
            />
          </mxGeometry>
         </mxCell>
         
         <!-- class rectangle -->
         <mxCell 
               id="${ classElement.id }" 
               value="${ classElement.name }" 
               style="rounded=0;whiteSpace=wrap;html=1;fillColor=#ffe6cc;strokeColor=#d79b00;" 
               vertex="1" 
               parent="1">
          <mxGeometry 
              x="${ classElement.x }" 
              y="${ classElement.y }" 
              width="${ classElement.width }" 
              height="${ classElement.height }" 
              as="geometry" 
          />
        </mxCell>
        `
    );
};

const renderNode = (node: ITreeNode, parentId: string) : string => {
    let yPos = 0.5;

    if(node.parent) {
        const total = node.parent.children.length;

        if(total > 0) {
            const partHeight = 1 / total;
            const start = node.childIndex * partHeight;
            yPos = start; // + partHeight/2;
        }
    }

    const id = uuidv4();

    return (
        `
          <mxCell 
               id="${ id }" 
               style="rounded=0;whiteSpace=wrap;html=1;fillColor=#ffe6cc;strokeColor=#d79b00;" 
               vertex="1" 
               parent="1">
              <mxGeometry 
                  x="${ node.x }" 
                  y="${ node.y }" 
                  width="${ node.width }" 
                  height="${ node.height }" 
                  as="geometry" 
              />
         </mxCell>
         
         <!-- connection line -->
         ${
            node.parent ? `
            
            <mxCell 
                id="${ uuidv4() }" 
                style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=0;exitY=0.5;exitDx=0;exitDy=0;entryX=1;entryY=${ yPos };entryDx=0;entryDy=0;endArrow=none;startFill=1;startArrow=classic;fontSize=12;" 
                edge="1" 
                parent="1" 
                value="${ node.upConnectionLabel }"
                source="${ id }" 
                target="${ parentId }">
              <mxGeometry relative="1" as="geometry" />
            </mxCell>
            ` : ``
          }
        
         ${ node.children.map(child => renderNode(child, id)).join('') }
        `
    );
};

const renderTree = (tree: ITree): string => {
    return renderNode(tree.root, null);
};

