import { usePlannerStore } from '../../../data/planner-store';
import TagFilter from './tag-filter';
import {
    getTagCode,
    getTagsFromCombinedProject,
    TAG_FILTER_HAS_TIME_TITLE,
    TAG_FILTER_NO_TODOS_TITLE
} from '../../../domain/tags-provider';
import { useEffect } from 'react';
import { getSelectedTodayTagsFromStorage } from '../../../domain/planner-storage';

const TagFilters = () => {

    const selectedFilterTags = usePlannerStore(state => state.selectedFilterTags);
    const setSelectedFilterTags = usePlannerStore(state => state.setSelectedFilterTags);
    const combinedProject = usePlannerStore(state => state.combinedProject);

    const [tags, tagsTodoMap, todosWithoutTag, todosWithTime] = getTagsFromCombinedProject(combinedProject);

    const withTimeCount = todosWithTime.length;
    const noTagsCount = todosWithoutTag.length;

    useEffect(() => {

        const [_tags, _tagsTodoMap, _todosWithoutTag, _todosWithTime] = getTagsFromCombinedProject(combinedProject);

        const storageHasActiveTags = () : boolean => {
            const storageTags = getSelectedTodayTagsFromStorage();
            if(storageTags?.size <= 0) return false;

            for(const tag of storageTags) {
                if(_tagsTodoMap.has(tag)) return true;
            }

            return false;
        };

        if(storageHasActiveTags()){
            setSelectedFilterTags(getSelectedTodayTagsFromStorage());
            return;
        }
        const copy = new Set(selectedFilterTags);

        const tagFilterWithTimeCode = getTagCode(TAG_FILTER_HAS_TIME_TITLE);
        const tagFilterNoTodosCode = getTagCode(TAG_FILTER_NO_TODOS_TITLE);

        for(const tag of selectedFilterTags) {
            const code = getTagCode(tag);

            if(code === tagFilterWithTimeCode) {
                if(_todosWithTime.length === 0) {
                    copy.delete(tagFilterWithTimeCode);
                }
                continue;
            }

            if(code === tagFilterNoTodosCode) {
                if(_todosWithoutTag.length === 0) {
                    copy.delete(tagFilterNoTodosCode);
                }
                continue;
            }

            if(!_tagsTodoMap.get(code)?.length) {
                copy.delete(code);
            }
        }

        setSelectedFilterTags(copy);
    },
        // eslint-disable-next-line
        [combinedProject]
    );

    return (
        <>
            {
                !!tags.length &&
                <div className="text-sm flex flex-wrap items-center mt-4">
                    {
                        withTimeCount > 0 &&
                        <TagFilter
                            key={ TAG_FILTER_HAS_TIME_TITLE }
                            title={ TAG_FILTER_HAS_TIME_TITLE }
                            todosCount={ withTimeCount }
                        />
                    }

                    {
                        noTagsCount > 0 &&
                        <TagFilter
                            key={ TAG_FILTER_NO_TODOS_TITLE }
                            title={ TAG_FILTER_NO_TODOS_TITLE }
                            todosCount={ noTagsCount }
                        />
                    }

                    {
                        tags.map(tag => {
                            return (
                                <TagFilter
                                    key={ tag.title }
                                    title={ tag.title }
                                    todosCount={ tagsTodoMap.get(tag.code)?.length || 0 }
                                />
                            )
                        })
                    }
                </div>
            }
        </>
    )
};

export default TagFilters;