import { IDataFlowDiagramData } from './interfaces';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DrawIoEmbed, DrawIoEmbedRef, EventExport } from 'react-drawio';
import { createDfdDiagramXml } from './domain/dfd-diagram-provider';
import { copyImageToClipboard, downloadImage } from '../../../../../common/domain/io-provider';
import { getDfdDiagramFromStorage, setDfdDiagramToStorage } from './domain/decision-diagram-storage';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IDataFlowDiagramData = {
        "entities": [
            { "id": "E1", "name": "Student" },
            { "id": "E2", "name": "Lecturer" },
            { "id": "E3", "name": "Administrator" }
        ],
        "processes": [
            {
                "id": "1.0",
                "name": "Manage Learning Groups",
                "from": [
                    { "from": "E1", "label": "Student Registration" },
                    { "from": "E3", "label": "Approval" }
                ],
                "to": [
                    { "to": "D1", "label": "Store Group Data" },
                    { "to": "2.0", "label": "Send Notification" }
                ]
            },
            {
                "id": "2.0",
                "name": "Send Notifications",
                "from": [
                    { "from": "1.0", "label": "Notification Trigger" }
                ],
                "to": [
                    { "to": "E1", "label": "Student Alert" },
                    { "to": "E2", "label": "Lecturer Alert" }
                ]
            },
            {
                "id": "3.0",
                "name": "Assign Lecturer to Course",
                "from": [
                    { "from": "E2", "label": "Lecturer Selection" },
                    { "from": "D2", "label": "Retrieve Course Data" }
                ],
                "to": [
                    { "to": "D3", "label": "Store Assignment" },
                    { "to": "4.0", "label": "Pass Assignment" }
                ]
            },
            {
                "id": "4.0",
                "name": "Grade Submission",
                "from": [
                    { "from": "E2", "label": "Submit Grades" },
                    { "from": "D3", "label": "Retrieve Assignments" },
                    { "from": "3.0", "label": "Pass Assignment" }
                ],
                "to": [
                    { "to": "D4", "label": "Store Grades" },
                    { "to": "5.0", "label": "Send Grade Report" }
                ]
            },
            {
                "id": "5.0",
                "name": "Send Grade Reports",
                "from": [
                    { "from": "4.0", "label": "Grades Ready" }
                ],
                "to": [
                    { "to": "E1", "label": "Student Grade Report" }
                ]
            }
        ],
        "dataStores": [
            {
                "id": "D1",
                "name": "Groups Database"
            },
            {
                "id": "D2",
                "name": "Courses Database"
            },
            {
                "id": "D3",
                "name": "Assignments Database"
            },
            {
                "id": "D4",
                "name": "Grades Database"
            }
        ]
    }
;

const DfdDiagramHome = () => {

    const drawIoRef = useRef<DrawIoEmbedRef>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IDataFlowDiagramData>(initialData);

    useEffect(() => {
        if(!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onDiagramSave = async (data: EventExport) => {
        downloadImage(data.data, 'dfd-diagram.png');
        await copyImageToClipboard(data.data);
    };

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    useEffect(() => {
        const storageData = getDfdDiagramFromStorage();
        if(!storageData) return;

        if(textAreaRef.current) {
            textAreaRef.current.value = storageData;
        }

        let parsed: IDataFlowDiagramData|null = null;

        try{
            parsed = JSON.parse(storageData) as IDataFlowDiagramData;
        }
        catch (error) {
            console.log(error);
        }

        if(!parsed) return;

        setData(parsed);
    }, []);

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setDfdDiagramToStorage(evt.target.value);
    };

    return(
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">דיאגרמת בועות (DFD)</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    className="border rounded w-full p-2"
                    onChange={ onChange }
                    style={ {
                        width: '300px',
                        height: UI_HEIGHT,
                    } }
                />

                <div className="flex-1 draw-io-box"
                     style={ {
                         height: UI_HEIGHT,
                     } }>
                    <DrawIoEmbed
                        ref={ drawIoRef }
                        urlParameters={ {
                            // ui: 'kennedy',

                            // Shows a 'Loading…' spinner while waiting
                            // for the diagram data to load in embed mode.
                            spin: true,

                            // Specifies whether libraries should be enabled in embed mode.
                            // The default is disabled.
                            // libraries: true,


                            noExitBtn: true,
                        } }
                        exportFormat={ 'png' }
                        onExport={ onDiagramSave }
                        xml={ createDfdDiagramXml(data) }
                    />
                </div>
            </div>

        </div>
    )
};

export default DfdDiagramHome;
