import { IFoodGoal } from '../../../food-interfaces';
import { MDXEditor, MDXEditorMethods } from '@mdxeditor/editor';
import { getCompactMarkdownEditorPlugins } from '../../../../../common/markdown/markdown-editor-provider';
import { PreloaderIcon } from '../../../../../common/ui/icons';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { insertFoodGoal, updateFoodGoal } from '../../../data/food-data-service';
import { showToast } from '../../../../../common/domain/toast-provider';
import { useFoodStore } from '../../../data/food-store';
import { FoodGoalsTab } from '../tabs-navigation';
import { format } from 'date-fns';

interface IUpsertFoodGoal {
    type: 'insert' | 'edit',
    foodGoal?: IFoodGoal
    close?: () => void;
}

const UpsertFoodGoal = ({ type, foodGoal, close }: IUpsertFoodGoal) => {

    const setFoodGoalsData = useFoodStore(store => store.setFoodGoalsData);
    const setFoodGoalsTab = useFoodStore(store => store.setFoodGoalsTab);

    const weightRef = useRef<HTMLInputElement>(null);
    const editorRef = useRef<MDXEditorMethods>(null);

    const [weight, setWeight] = useState('');
    const [weightTouched, setWeightTouched] = useState(false);
    const isWeightValid = !weightTouched || Number(weight.trim()) > 0;

    const [startDate, setStartDate] = useState<number|null>(Date.now());
    const [startDateTouched, setStartDateTouched] = useState(false);
    const isStartDateValid = !startDateTouched || startDate > 0;

    const [isLoading, setLoading] = useState(false);

    useEffect(() => {
        weightRef?.current?.focus();
    }, []);

    useEffect(() => {
        if(!foodGoal || type !== 'edit') return;

        setWeight((foodGoal.weight).toString());
        setWeightTouched(false);

        setStartDate(foodGoal.start_date);
        setStartDateTouched(false);

        editorRef.current?.setMarkdown(foodGoal.description || '');
    }, [foodGoal, type]);

    const save = useCallback(async () => {

        const _weight = Number(weight.trim()) || 0;
        if(!_weight) {
            setWeightTouched(true);
            return;
        }

        if(startDate <= 0) {
            setStartDateTouched(true);
            return;
        }

        setLoading(true);

        const description = editorRef.current?.getMarkdown() || '';

        if(type === 'insert') {
            const response = await insertFoodGoal(
                _weight,
                description,
                startDate,
            );

            setLoading(false);

            if(!response) {
                showToast('Insert food goal error.');
                return;
            }

            setFoodGoalsData(response);
            setFoodGoalsTab(FoodGoalsTab.Default);
        }
        else{
            const response = await updateFoodGoal(
                foodGoal.id,
                _weight,
                description,
                startDate,
            );

            setLoading(false);

            if(!response) {
                showToast('Update food goal error.');
                return;
            }

            setFoodGoalsData(response);
        }
        if(close) {
            close();
        }
    }, [
        close,
        foodGoal,
        setFoodGoalsData,
        setFoodGoalsTab,
        startDate,
        type,
        weight,
    ]);

    const onWeightChange = (evt: ChangeEvent<HTMLInputElement>) => {
        setWeight((evt.target as HTMLInputElement).value);
        setWeightTouched(true);
    };

    const onStartDateChange = (evt: ChangeEvent<HTMLInputElement>) => {
        if(!evt.target.value) {
            setStartDate(null);
            return;
        }

        const _date = new Date(evt.target.value);
        setStartDate(_date.getTime());
    };

    useEffect(() => {

        const onKeyDown = async (evt: KeyboardEvent) => {

            if(evt.code === 'KeyS' && (evt.ctrlKey || evt.metaKey)) {
                const $box = (evt.target as HTMLElement).closest('[data-type="edit-food-goal"]');
                if(!$box) return;

                evt.preventDefault();
                await save();
                return;
            }
        };

        document.addEventListener('keydown', onKeyDown, true);

        return () => {
            document.removeEventListener('keydown', onKeyDown, true);
        };

    }, [save]);

    return (
        <div data-type="upsert-food-goal">
            <label className="flex flex-col mb-4">
                <span className="font-bold text-slate-400 mb-2">Food Goal Weight</span>
                <input
                    ref={ weightRef }
                    value={ weight }
                    onInput={ onWeightChange }
                    className={ `border rounded px-4 py-2 ${ isWeightValid ? 'outline-stone-200' : 'outline-red-200 border-red-200' }` }
                    type="text"
                />

                {
                    !isWeightValid && <div className="text-red-700 text-xs mt-1">The weight is required.</div>
                }
            </label>

            <div className="mb-4">
                <label
                    className="font-bold text-slate-400 block mb-2"
                    htmlFor="food-goal-start-date">Date</label>

                <div>
                    <input
                        id="food-goal-start-date"
                        type="date"
                        className="w-full text-sm md:text-base border rounded text-slate-800 outline-stone-200 px-2 md:px-4 py-2"
                        onChange={ onStartDateChange }
                        value={ startDate ? format(startDate, `yyyy-MM-dd`) : '' }
                    />

                    {
                        !isStartDateValid && <div className="text-red-700 text-xs mt-1">The goal start date is required.</div>
                    }
                </div>
            </div>

            <label className="font-bold flex flex-col text-slate-400 mb-2">Description</label>
            <div className="markdown-editor border rounded-lg mb-4">
                <MDXEditor
                    ref={ editorRef }
                    markdown={ '' }
                    plugins={ getCompactMarkdownEditorPlugins() }
                />
            </div>

            <div className="flex items-center justify-end text-sm mt-4">

                {
                    isLoading &&
                    <PreloaderIcon size={ 24 } color={ '#717985' }/>
                }

                {
                    !isLoading &&
                    <>
                        <button
                            className="bg-slate-500 text-slate-100 rounded px-6 py-2 mr-4"
                            onClick={ close }
                            type="button">Close
                        </button>

                        <button
                            className="bg-teal-500 text-slate-100 rounded px-6 py-2"
                            onClick={ save }
                            type="button">Save
                        </button>
                    </>
                }
            </div>

        </div>
    )
};

export default UpsertFoodGoal;