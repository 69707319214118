import { IUseCaseDiagram } from './interfaces';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { DrawIoEmbed, DrawIoEmbedRef, EventExport } from 'react-drawio';
import { createUseCaseDiagramXml } from './domain/use-case-diagram-provider';
import { copyImageToClipboard, downloadImage } from '../../../../../common/domain/io-provider';
import { getUseCaseDiagramFromStorage, setUseCaseDiagramToStorage } from './domain/use-case-diagram-storage';
import { UI_HEIGHT } from '../common/common-access-provider';

const initialData: IUseCaseDiagram = {
        "actors": [
            { "id": "a1", "name": "Customer" },
            { "id": "a2", "name": "Delivery Driver" },
            { "id": "a3", "name": "Warehouse Manager" },
            { "id": "a4", "name": "Admin System" }
        ],
        "useCases": [
            { "id": "u1", "name": "Place Order" },
            { "id": "u2", "name": "Track Package" },
            { "id": "u3", "name": "Update Delivery Status" },
            { "id": "u4", "name": "Generate Invoice" },
            { "id": "u5", "name": "Manage Inventory" },
            { "id": "u6", "name": "Schedule Delivery" },
            { "id": "u7", "name": "Verify Payment" },
            { "id": "u8", "name": "Confirm Package Receipt" }
        ],
        "associations": [
            { "actorId": "a1", "useCaseId": "u1" },
            { "actorId": "a1", "useCaseId": "u2" },
            { "actorId": "a2", "useCaseId": "u3" },
            { "actorId": "a3", "useCaseId": "u5" },
            { "actorId": "a3", "useCaseId": "u6" },
            { "actorId": "a1", "useCaseId": "u8" },
            { "actorId": "a4", "useCaseId": "u7" },
            { "actorId": "a4", "useCaseId": "u4" }
        ],
        "extends": [
            { "baseUseCaseId": "u1", "extendedUseCaseId": "u7" },
            { "baseUseCaseId": "u3", "extendedUseCaseId": "u8" }
        ],
        "includes": [
            { "baseUseCaseId": "u1", "includedUseCaseId": "u6" },
            { "baseUseCaseId": "u4", "includedUseCaseId": "u7" }
        ]
    }
;

const UseCaseDiagramHome = () => {

    const drawIoRef = useRef<DrawIoEmbedRef>(null);
    const textAreaRef = useRef<HTMLTextAreaElement>(null);

    const [data, setData] = useState<IUseCaseDiagram>(initialData);

    useEffect(() => {
        if(!textAreaRef?.current) return;
        textAreaRef.current.value = JSON.stringify(initialData, null, 4);
    }, []);

    const onDiagramSave = async (data: EventExport) => {
        downloadImage(data.data, 'use-case-diagram.png');
        await copyImageToClipboard(data.data);
    };

    const onClick = () => {
        setData(JSON.parse(textAreaRef.current.value));
    };

    useEffect(() => {
        const storageData = getUseCaseDiagramFromStorage();
        if(!storageData) return;

        if(textAreaRef.current) {
            textAreaRef.current.value = storageData;
        }

        let parsed: IUseCaseDiagram|null = null;

        try{
            parsed = JSON.parse(storageData) as IUseCaseDiagram;
        }
        catch (error) {
            console.log(error);
        }

        if(!parsed) return;

        setData(parsed);
    }, []);

    const onChange = (evt: ChangeEvent<HTMLTextAreaElement>) => {
        setUseCaseDiagramToStorage(evt.target.value);
    };

    return(
        <div className="w-full py-2">

            <div className="flex items-center w-full mb-4">
                <h1 className="text-2xl mr-6">דיאגרמת תרחישים</h1>

                <button
                    type="button"
                    onClick={ onClick }
                    className="px-6 bg-blue-100 rounded">
                    Update
                </button>

                <p className="text-sm text-sky-700 mx-4">Шула сказала, что все, что мы здесь нарисуем по правилам они примут, и не надо заморачиваться.</p>
            </div>

            <div className="flex gap-4">
                <textarea
                    ref={ textAreaRef }
                    className="border rounded w-full p-2"
                    onChange={ onChange }
                    style={{
                        width: '300px',
                        height: UI_HEIGHT,
                    }}
                />

                <div className="flex-1 draw-io-box"
                     style={{
                         height: UI_HEIGHT,
                     }}>
                    <DrawIoEmbed
                        ref={ drawIoRef }
                        urlParameters={ {
                            // ui: 'kennedy',

                            // Shows a 'Loading…' spinner while waiting
                            // for the diagram data to load in embed mode.
                            spin: true,

                            // Specifies whether libraries should be enabled in embed mode.
                            // The default is disabled.
                            // libraries: true,


                            noExitBtn: true,
                        } }
                        exportFormat={ 'png' }
                        onExport={ onDiagramSave }
                        xml={ createUseCaseDiagramXml(data) }
                    />
                </div>
            </div>

        </div>
    )
};

export default UseCaseDiagramHome;
