import { IListBoxWithSearchItem } from '../interfaces';

interface IListBoxWithSearchGroupItemProps {
    selectedItemId: string|number;
    groupId: string|number;
    item: IListBoxWithSearchItem;
    onSelect: (groupId: number|string, itemId: number|string) => void;
}

const ListBoxWithSearchGroupItem = ({ selectedItemId, groupId, item, onSelect }: IListBoxWithSearchGroupItemProps) => {

    const onClick = () => {
        onSelect(groupId, item.id);
    };

    return (
        <button
            type="button"
            className={ `text-left ${ item.id === selectedItemId ? 'text-orange-800' : '' }` }
            onClick={ onClick }>
            { item.text }
        </button>
    )
};

export default ListBoxWithSearchGroupItem;