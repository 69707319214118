import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { IListBoxWithSearchGroup, IListBoxWithSearchItem } from './interfaces';
import ListBoxWithSearchGroup from './controls/group';
import NotFound from './controls/not-found';
import { CloseIcon } from '../icons';

interface IListBoxWithSearch {
    selectedItemId: string|number;
    groups: IListBoxWithSearchGroup[];
    placeholder: string;
    onSelect: (groupId: number|string, itemId: number|string) => void;
    maxHeight?: string;
}

const ListBoxWithSearch = ({ selectedItemId, groups, onSelect, placeholder, maxHeight }: IListBoxWithSearch) => {

    const [allItems, setAllItems] = useState<IListBoxWithSearchItem[]>([]);
    const [filtered, setFiltered] = useState<IListBoxWithSearchGroup[]>([]);
    const [text, setText] = useState('');
    const [selected, setSelected] = useState<number|string>(selectedItemId);

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const _allItems: IListBoxWithSearchItem[] = [];

        for(const group of groups) {
            for(const item of group.items) {
                _allItems.push(item);
            }
        }

        setAllItems(_allItems);
    }, [groups]);

    useEffect(() => {
            if(text.trim() === '') {
                setFiltered(groups);
                return;
            }

            const _text = text.trim().toLowerCase();
            const _filtered: IListBoxWithSearchGroup[] = [];

            for(const group of groups) {

                const items: IListBoxWithSearchItem[] = [];

                for(const item of group.items) {
                    if(!item.text.trim().toLowerCase().includes(_text)) continue;
                    items.push(item);
                }

                if(items.length > 0) {
                    _filtered.push({
                        ...group,
                        items,
                    });
                }
            }

            setFiltered(_filtered);
        },
        [text, groups]
    );

    useEffect(() => {
        const found = allItems.find(item => item.id === selectedItemId);
        if(!found) return;

        setText(found.text);
        setSelected(selectedItemId);
    }, [selectedItemId, allItems]);

    useEffect(() => {
        window.setTimeout(() => {
            inputRef.current?.focus();
            inputRef.current?.select();
        }, 100);
    }, []);

    const handleSelect = (groupId: number|string, itemId: number|string) => {
        const found = allItems.find(item => item.id === itemId);
        if(!found) return;

        setText(found.text);
        setSelected(itemId);
        onSelect(groupId, itemId);
    };

    const onInput = (evt: ChangeEvent<HTMLInputElement>) => {
        setText(evt.target.value);
    };

    const clear = () => {
        setText('');
        setSelected('');
    };

    return (
        <div className="border border-slate-200 bg-slate-100 rounded overflow-hidden">
            <div className="flex w-full">
                <input
                    ref={ inputRef }
                    className="border-b border-slate-200 px-4 py-2 bg-white flex-1 outline-none"
                    type="text"
                    onInput={ onInput }
                    value={ text }
                    placeholder={ placeholder }
                />

                <button
                    type="button"
                    onClick={ clear }
                    className="bg-white border-b border-slate-200 px-4">
                    <CloseIcon
                        size={ 15 }
                    />
                </button>
            </div>

            {
                filtered.length <= 0 && text.trim().length > 0 &&
                <NotFound />
            }

            {
                filtered.length > 0 &&
                <div className="flex flex-col overflow-auto py-2 px-4 max-h-64" style={{
                    maxHeight,
                }}>
                    {
                        filtered.map(group => {
                            return (
                                <ListBoxWithSearchGroup
                                    selectedItemId={ selected }
                                    key={ group.id }
                                    group={ group }
                                    onSelect={ handleSelect }
                                />
                            )
                        })
                    }
                </div>
            }
        </div>
    )
};

export default ListBoxWithSearch;