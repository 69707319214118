import { v4 as uuidv4 } from 'uuid';
import {
    IDataFlowElement,
    IModule,
    IModuleNode,
    IModuleStructure,
    IStructureChartElement, IStructureChartSectionElement,
    IStructureChartTopSectionElement
} from '../interfaces';

const NODE_WIDTH = 150;
const NODE_HEIGHT = 80;
const NODE_MARGIN_X = 50;
const NODE_MARGIN_Y = 70;
const RHOMBUS_SIZE = 50;
const CIRCLE_SIZE = 40;
const SYMBOLS_MARGIN_Y = 35;
const SECTION_MARGIN_Y = 80;

const DATA_FLOW_LEFT_OFFSET = 20;
const DATA_FLOW_TOP_OFFSET = 20;
const DATA_FLOW_LENGTH = 40;

const prepareData = (data: IModuleStructure): IStructureChartElement => {

    // Build the modules tree
    const modulesMap = new Map<string, IModuleNode>(); // module id ---> module

    const traverse = (module: IModule, parent: IModuleNode|null) => {

        let moduleItem: IModuleNode = modulesMap.get(module.id);

        if(!moduleItem) {
            moduleItem = {
                id: module.id,
                name: module.name,
                index: 0,

                children: [],
                parent: null,

                x: 0,
                y: 0,
                width: NODE_WIDTH,
                height: NODE_HEIGHT,

                hasLoop: module.loops ?? false,
                isCircle: false,
                dataFlowElements: [],
            };

            if(module.dataFlowIn) {
                for(const input of module.dataFlowIn) {
                    moduleItem.dataFlowElements.push({
                        type: 'input',
                        label: input.label,
                        id: uuidv4(),
                    });
                }
            }

            if(module.dataFlowOut) {
                for(const output of module.dataFlowOut) {
                    moduleItem.dataFlowElements.push({
                        type: 'output',
                        label: output.label,
                        id: uuidv4(),
                    });
                }
            }

            if(module.controlFlow) {
                for(const ctrl of module.controlFlow) {
                    moduleItem.dataFlowElements.push({
                        type: 'control',
                        label: ctrl.label,
                        id: uuidv4(),
                    });
                }
            }

            modulesMap.set(module.id, moduleItem);
        }

        if(parent) {
            moduleItem.parent = parent;
            parent.children.push(moduleItem);
        }

        for(const child of module.children) {
            traverse(child, moduleItem);
        }
    };

    traverse(data.rootModule, null);

    const allNodes: IModuleNode[] = [...modulesMap.values()];
    const systemNode: IModuleNode = modulesMap.get(data.rootModule.id);

    // Top Section -------------------------------------------
    const topSection: IStructureChartTopSectionElement = {
        root: systemNode,
        modules: [],
        width: 0,
        height: 0,

        rhombusId: uuidv4(),
        rhombusX: 0,
        rhombusY: 0,
    };

    topSection.rhombusY = topSection.root.height + SYMBOLS_MARGIN_Y;

    let x = 0;
    let y =  topSection.rhombusY + RHOMBUS_SIZE + SYMBOLS_MARGIN_Y*2;

    for(let i=0; i<allNodes.length; i++) {

        const node = allNodes[i];
        if(node.parent !== systemNode) continue;

        node.index = topSection.modules.length + 1;
        node.x = x;
        node.y = y;

        topSection.modules.push(node);

        x += node.width + NODE_MARGIN_X;
    }

    topSection.width = x - NODE_MARGIN_X;
    topSection.height = y + SYMBOLS_MARGIN_Y + CIRCLE_SIZE;

    topSection.root.x = (topSection.width - NODE_WIDTH) / 2;
    topSection.rhombusX = topSection.root.x + (topSection.root.width - RHOMBUS_SIZE) / 2;

    // Other sections -------------------------------------
    y = topSection.height + SECTION_MARGIN_Y * 2;

    const sections: IStructureChartSectionElement[] = [];

    for(let i=0; i<topSection.modules.length; i++) {
        let x = 0;

        const sectionRoot = {...topSection.modules[i]};
        sectionRoot.id = uuidv4();
        sectionRoot.parent = null;
        sectionRoot.x = 0;
        sectionRoot.y = y;
        sectionRoot.isCircle = true;
        sectionRoot.name = sectionRoot.index.toString();

        y += sectionRoot.height + NODE_MARGIN_Y;

        for(const child of sectionRoot.children) {
            child.parent = sectionRoot;
        }

        // Use BFS to create node rows
        const result: IModuleNode[] = [];
        const queue: IModuleNode[] = [sectionRoot];

        // let level = 0;

        while (queue.length > 0) {

            let levelSize = queue.length;

            while (levelSize > 0) {
                const node = queue.shift() as IModuleNode;
                result.push(node);

                for(const child of node.children) {
                    child.x = x;
                    child.y = y;
                    x += NODE_WIDTH + NODE_MARGIN_X;
                    queue.push(child);
                }

                levelSize--;
            }

            // console.log(level, temp);
            // level++;

            x = 0;
            y += NODE_HEIGHT + NODE_MARGIN_Y;
        }

        const section: IStructureChartSectionElement = {
            sectionIndex: sectionRoot.index,
            nodes: result,
        };

        sections.push(section);

        // y += SECTION_MARGIN_Y;
    }

    const result: IStructureChartElement = {
        topSection,
        sections,
    };

    return result;
};

export const createStructureChartXml = (data: IModuleStructure): string => {

    const preparedData = prepareData(data);
    if(!preparedData) return '';

    const initialXml = `
<mxfile host="draw.io">
  <diagram name="עץ מודולים">
    <mxGraphModel background="#FFFFFF">
      <root>
        <mxCell id="0"/>
        <mxCell id="1" parent="0"/>
        
        ${ renderTopSection(preparedData.topSection) }
        ${ preparedData.sections.map(section => renderSection(section)).join('') }
        
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>`;

    return initialXml.trim();
};

const renderTopSection = (topSection: IStructureChartTopSectionElement) => {
    return `
        <!-- root -->
        <mxCell 
            id="${ topSection.root.id }" 
            value="${ topSection.root.name }" 
            style="rounded=0;whiteSpace=wrap;html=1;fillColor=#ffffff;fontStyle=1;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ topSection.root.x }" 
              y="${ topSection.root.y }" 
              width="${ topSection.root.width }" 
              height="${ topSection.root.height }" 
              as="geometry"
              />
        </mxCell>
        
        <!-- rhombus -->
        <mxCell id="${ topSection.rhombusId }" value="" style="rhombus;whiteSpace=wrap;html=1;" vertex="1" parent="1">
          <mxGeometry 
              x="${ topSection.rhombusX }" 
              y="${ topSection.rhombusY }" 
              width="${ RHOMBUS_SIZE }" 
              height="${ RHOMBUS_SIZE }" 
              as="geometry" 
          />
        </mxCell>
        
        <!-- arrow from root to rhombus -->
        <mxCell 
            id="${ uuidv4() }" 
            style="edgeStyle=orthogonalEdgeStyle;rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=0.5;exitY=1;exitDx=0;exitDy=0;entryX=0.5;entryY=0;entryDx=0;entryDy=0;endArrow=none;" 
            edge="1" 
            parent="1" 
            source="${ topSection.root.id }" 
            target="${ topSection.rhombusId }">
          <mxGeometry relative="1" as="geometry" />
        </mxCell>
        
        ${ topSection.modules.map(module => {
            
            const circleId = uuidv4();
            
            return `
                 <!-- module -->
                 <mxCell 
                    id="${ module.id }" 
                    value="${ module.name }" 
                    style="rounded=0;whiteSpace=wrap;html=1;fillColor=#f5f5f5;fontColor=#333333;strokeColor=#666666;" 
                    vertex="1" 
                    parent="1">
                      <mxGeometry 
                          x="${ module.x }" 
                          y="${ module.y }" 
                          width="${ module.width }" 
                          height="${ module.height }" 
                          as="geometry"
                      />
                </mxCell>
                
                ${ module.hasLoop ? `
                    <mxCell 
                        id="${ uuidv4() }" 
                        style="edgeStyle=orthogonalEdgeStyle;rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=0.25;exitY=1;exitDx=0;exitDy=0;entryX=0.75;entryY=1;entryDx=0;entryDy=0;curved=1;" 
                        edge="1" 
                        parent="1" 
                        source="${ module.id }" 
                        target="${ module.id }">
                      <mxGeometry relative="1" as="geometry" />
                    </mxCell>
                    ` : `` }
                
                <!-- arrow from rhombus to module -->
                <mxCell 
                    id="${ uuidv4() }" 
                    style="edgeStyle=orthogonalEdgeStyle;rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=0.5;exitY=1;exitDx=0;exitDy=0;entryX=0.5;entryY=0;entryDx=0;entryDy=0;" 
                    edge="1" 
                    parent="1" 
                    source="${ topSection.rhombusId }" 
                    target="${ module.id }">
                  <mxGeometry relative="1" as="geometry" />
                </mxCell>
                
                <!-- circle -->
                <mxCell 
                    id="${ circleId }" 
                    value="${ module.index }" 
                    style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;" 
                    vertex="1" 
                    parent="1">
                  <mxGeometry 
                      x="${ module.x + (module.width - CIRCLE_SIZE)/2 }" 
                      y="${ module.y + module.height + SYMBOLS_MARGIN_Y }" 
                      width="${ CIRCLE_SIZE }" 
                      height="${ CIRCLE_SIZE }" 
                      as="geometry" 
                  />
                </mxCell>
                
                <!-- arrow from module to circle -->
                <mxCell 
                    id="${ uuidv4() }" 
                    style="edgeStyle=orthogonalEdgeStyle;rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=0.5;exitY=1;exitDx=0;exitDy=0;entryX=0.5;entryY=0;entryDx=0;entryDy=0;" 
                    edge="1" 
                    parent="1" 
                    source="${ module.id }" 
                    target="${ circleId }">
                  <mxGeometry relative="1" as="geometry" />
                </mxCell>
            `;    
        }).join('') }
    `;
};

const renderSection = (section: IStructureChartSectionElement) => {
    return `
        ${ section.nodes.map(node => renderSectionNode(node)).join('') }
    `;
};

const renderSectionNode = (node: IModuleNode): string => {

    const nodeId = node.id;

    return (
        `
        ${
            node.isCircle ? 
                `
                <!-- circle -->
                <mxCell 
                    id="${ nodeId }" 
                    value="${ node.name }" 
                    style="ellipse;whiteSpace=wrap;html=1;aspect=fixed;" 
                    vertex="1" 
                    parent="1">
                  <mxGeometry 
                      x="${ node.x + (node.width - CIRCLE_SIZE)/2 }" 
                      y="${ node.y }" 
                      width="${ CIRCLE_SIZE }" 
                      height="${ CIRCLE_SIZE }" 
                      as="geometry" 
                  />
                </mxCell>`:
                `
                <!-- node -->
                <mxCell 
                    id="${ nodeId }" 
                    value="${ node.name }" 
                    style="rounded=0;whiteSpace=wrap;html=1;fillColor=#ffffff;fontStyle=1;" 
                    vertex="1" 
                    parent="1">
                  <mxGeometry 
                      x="${ node.x }" 
                      y="${ node.y }" 
                      width="${ node.width }" 
                      height="${ node.height }" 
                      as="geometry"
                      />
                </mxCell>`
        }
        
        
        ${
            node.parent ? `
            <mxCell 
                 id="${ uuidv4() }" 
                 style="edgeStyle=orthogonalEdgeStyle;rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=0.5;exitY=1;exitDx=0;exitDy=0;entryX=0.5;entryY=0;entryDx=0;entryDy=0" 
                 parent="${ nodeId }"  
                 target="${ nodeId }" 
                 source="${ node.parent.id }"
                 edge="1">
              <mxGeometry relative="1" as="geometry"/>
            </mxCell>
            ` : ''
        }
        
        ${
            node.dataFlowElements.map((dataFlow, i) => renderDataFlows(dataFlow, node, i)).join('') 
        }
        `
    );
};

const renderDataFlows = (dataFlow: IDataFlowElement, node: IModuleNode, index: number) => {

    let style = '';

    if(dataFlow.type === 'input') {
        style = 'endArrow=classic;html=1;rounded=0;startArrow=oval;startFill=0;';
    }
    else if(dataFlow.type === 'output') {
        style = 'endArrow=oval;html=1;rounded=0;startArrow=classic;endFill=0;';
    }
    else {
        style = 'endArrow=oval;html=1;rounded=0;startArrow=classic;';
    }

    const y = node.y + index * (DATA_FLOW_LENGTH + DATA_FLOW_TOP_OFFSET);

    return `
        <mxCell id="${ dataFlow.id }" value="" style="${ style }" edge="1" parent="1">
          <mxGeometry width="50" height="50" relative="1" as="geometry">
            <mxPoint x="${ node.x - DATA_FLOW_LEFT_OFFSET }" y="${ y }" as="sourcePoint" />
            <mxPoint x="${ node.x - DATA_FLOW_LEFT_OFFSET }" y="${ y - DATA_FLOW_LENGTH }" as="targetPoint" />
          </mxGeometry>
        </mxCell>
        
        <mxCell id="${ uuidv4() }" value="${ dataFlow.label }" style="edgeLabel;html=1;align=center;verticalAlign=middle;resizable=0;points=[];" vertex="1" connectable="0" parent="${ dataFlow.id }">
          <mxGeometry x="-0.2" y="2" relative="1" as="geometry">
            <mxPoint x="-9" y="-2" as="offset" />
          </mxGeometry>
        </mxCell>`;
};
