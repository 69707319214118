import { create } from 'zustand';
import {
    IFlowersResponse,
    IPlantGalleryImage,
    IPlantLocation,
    IPlantType,
    IPlantWaterCalendarYear
} from '../flowers-interfaces';
import { PlantTypeTab } from '../ui/plant-types/tabs-navigation';
import { PlantLocationsTab } from '../ui/plant-locations/tabs-navigation';
import { PlantsTab } from '../ui/plants/tabs-navigation';

interface FlowersState {

    // -------- Plant Types -----------------------

    plantTypesData: IPlantType[];
    setPlantTypesData: (_plantTypesData: IPlantType[]) => void;

    plantTypeTab: PlantTypeTab;
    setPlantTypeTab: (_plantTypeTab: PlantTypeTab) => void;

    // -------- Plant Locations -----------------------

    plantLocationsData: IPlantLocation[];
    setPlantLocationsData: (_plantLocationsData: IPlantLocation[]) => void;

    plantLocationsTab: PlantLocationsTab;
    setPlantLocationsTab: (_plantTypeTab: PlantLocationsTab) => void;

    // -------- Plants -----------------------

    plantsData: IFlowersResponse;
    setPlantsData: (_plantsData: IFlowersResponse) => void;

    plantsTab: PlantsTab;
    setPlantsTab: (_plantsTab: PlantsTab) => void;

    showNonAlive: boolean;
    setShowNonAlive: (_showNonAlive: boolean) => void;

    // -------- Water Logs -----------------------

    waterLogsData: IFlowersResponse;
    setWaterLogsData: (_waterLogsData: IFlowersResponse) => void;

    waterLogsDate: number;
    setWaterLogsDate: (_waterLogsDate: number) => void;

    // -------- Water Calendar -----------------------
    year: number;
    setYear: (_year: number) => void;

    calendarData: IPlantWaterCalendarYear|null;
    setCalendarData: (_calendarData: IPlantWaterCalendarYear|null) => void;

    // -------- Gallery -----------------------
    plantsGalleryData: IPlantGalleryImage[];
    setPlantsGalleryData: (_plantsGalleryData: IPlantGalleryImage[]) => void;
}

export const useFlowersStore = create<FlowersState>()((set) => ({

    // -------- Plant Types -----------------------

    plantTypesData: [],
    setPlantTypesData: (_plantTypesData: IPlantType[]) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantTypesData: _plantTypesData,
            };
        });
    },

    plantTypeTab: PlantTypeTab.Default,
    setPlantTypeTab: (_plantTypeTab: PlantTypeTab) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantTypeTab: _plantTypeTab,
            };
        });
    },

    // -------- Plant Locations -----------------------

    plantLocationsData: [],
    setPlantLocationsData: (_plantLocationsData: IPlantLocation[]) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantLocationsData: _plantLocationsData,
            };
        });
    },

    plantLocationsTab: PlantLocationsTab.Default,
    setPlantLocationsTab: (_plantLocationsTab: PlantLocationsTab) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantLocationsTab: _plantLocationsTab,
            };
        });
    },

    // -------- Plants -----------------------

    plantsData: null,
    setPlantsData: (_plantsData: IFlowersResponse) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantsData: _plantsData,
            };
        });
    },

    plantsTab: PlantsTab.Default,
    setPlantsTab: (_plantsTab: PlantsTab) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantsTab: _plantsTab,
            };
        });
    },

    showNonAlive: false,
    setShowNonAlive: (_showNonAlive: boolean) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                showNonAlive: _showNonAlive,
            };
        });
    },

    // -------- Water Logs -----------------------

    waterLogsData: null,
    setWaterLogsData: (_waterLogsData: IFlowersResponse) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                waterLogsData: _waterLogsData,
            };
        });
    },

    waterLogsDate: (new Date()).getTime(),
    setWaterLogsDate: (_waterLogsDate: number) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                waterLogsDate: _waterLogsDate,
            };
        });
    },

    // -------- Water Calendar -----------------------
    year: (new Date()).getFullYear(),
    setYear: (_year: number) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                year: _year,
            };
        });
    },

    calendarData: null,
    setCalendarData: (_calendarData: IPlantWaterCalendarYear|null) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                calendarData: _calendarData,
            };
        });
    },

    // -------- Gallery -----------------------
    plantsGalleryData: [],
    setPlantsGalleryData: (_plantsGalleryData: IPlantGalleryImage[]) => {
        return set((state: FlowersState) => {
            return {
                ...state,
                plantsGalleryData: _plantsGalleryData,
            };
        });
    },

}))