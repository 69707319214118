import {
    IERDData,
    ErdDiagramElement,
    ErdDiagramEntityElement,
    ErdDiagramEntityPropertyElement,
    ErdDiagramRelationshipElement,
    ITableModel,
    ITable,
    IERDEntity,
    ITableProperty,
} from '../interfaces';
import { v4 as uuidv4 } from 'uuid';

const ENTITY_WIDTH = 150;
const ENTITY_HEIGHT = 80;
const ENTITY_MARGIN_Y = 50;

const PROPERTY_WIDTH = 150;
const PROPERTY_HEIGHT = 50;
const PROPERTY_MARGIN_Y = 20;
const PROPERTY_ENTITY_MARGIN_X = 150;

const RELATIONSHIP_WIDTH = 150;
const RELATIONSHIP_HEIGHT = 80;
// const RELATIONSHIP_MARGIN_Y = 100;
const RELATIONSHIP_ENTITY_MARGIN_X = 150;

const prepareData = (data: IERDData) : ErdDiagramElement|null => {
    if(!data) return null;

    const entities: ErdDiagramEntityElement[] = [];
    const properties: ErdDiagramEntityPropertyElement[] = [];
    const relationships: ErdDiagramRelationshipElement[] = [];

    const dataEntities = data.entities ?? [];
    const dataRelationships = data.relationships ?? [];

    let y = 0;

    for(let i=0; i<dataEntities.length; i++) {
        const dataEntity = dataEntities[i];

        const entity: ErdDiagramEntityElement = {
            id: dataEntity.id,
            name: dataEntity.name,
            x: PROPERTY_WIDTH + PROPERTY_ENTITY_MARGIN_X,
            y,
            width: ENTITY_WIDTH,
            height: ENTITY_HEIGHT,
            properties: [],
        };

        for(let p=0; p<dataEntity.properties.length; p++) {
            const dataProperty = dataEntity.properties[p];
            const property: ErdDiagramEntityPropertyElement = {
                id: uuidv4(),
                name: dataProperty.name,
                x: 0,
                y,
                isPrimaryKey: dataProperty.isPrimaryKey ?? false,
                entityId: entity.id,
            };

            entity.properties.push(property);
            properties.push(property);

            y += PROPERTY_HEIGHT + PROPERTY_MARGIN_Y;
        }

        entity.y += (entity.properties.length - 1) * PROPERTY_HEIGHT / 2;

        entities.push(entity);
        y += ENTITY_MARGIN_Y;
    }

    if(dataRelationships.length > 0) {
        const entitiesHeight = y - ENTITY_MARGIN_Y;
        const partHeight = entitiesHeight / dataRelationships.length;

        // let relY = 0;

        for(let i=0; i<dataRelationships.length; i++) {
            const start = i * partHeight;
            const y = start + partHeight/2 - RELATIONSHIP_HEIGHT/2;

            const dataRelationship = dataRelationships[i];

            const relationship: ErdDiagramRelationshipElement = {
                id: dataRelationship.id,
                name: dataRelationship.name,
                x: PROPERTY_WIDTH + PROPERTY_ENTITY_MARGIN_X + ENTITY_WIDTH + RELATIONSHIP_ENTITY_MARGIN_X,
                y,
                connections: [],
            };

            relationships.push(relationship);
            // relY += RELATIONSHIP_HEIGHT + RELATIONSHIP_MARGIN_Y;

            for(let c=0; c<dataRelationship.entities.length; c++) {
                const relEntity = dataRelationship.entities[c];

                relationship.connections.push({
                    id: uuidv4(),
                    type: relEntity.cardinality,
                    relId: relationship.id,
                    entityId: relEntity.entityId,
                });
            }
        }
    }

    return {
        id: uuidv4(),
        entities,
        properties,
        relationships,
    };
};

export const createDfdDiagramXml = (data: IERDData): string => {

    const preparedData = prepareData(data);
    if(!preparedData) return '';

    const initialXml = `
<mxfile host="draw.io">
  <diagram name="דיאגרמת ישיויות קשרים">
    <mxGraphModel background="#FFFFFF">
      <root>
        <mxCell id="0"/>
        <mxCell id="1" parent="0"/>
        
        ${ preparedData.entities.map(entity => renderEntity(entity)).join('') }
        ${ preparedData.properties.map(property => renderProperty(property)).join('') }
        ${ preparedData.relationships.map(relationship => renderRelationship(relationship)).join('') }
        
      </root>
    </mxGraphModel>
  </diagram>
</mxfile>`;

    return initialXml.trim();
};

const renderEntity = (entity: ErdDiagramEntityElement) => {
    return `
        <mxCell 
            id="${ entity.id }" 
            value="${ entity.name }" 
            style="rounded=0;whiteSpace=wrap;html=1;fillColor=#ffffff;fontStyle=1;fontSize=12;spacingLeft=5;spacingRight=5;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ entity.x }" 
              y="${ entity.y }" 
              width="${ entity.width }" 
              height="${ entity.height }" 
              as="geometry"
              />
        </mxCell>
    `;
};

const renderProperty = (property: ErdDiagramEntityPropertyElement) => {
    return `
        <mxCell 
            id="${ property.id }" 
            value="${ property.isPrimaryKey ? `&lt;u&gt;${ property.name }&lt;/u&gt;` : property.name }" 
            style="ellipse;whiteSpace=wrap;html=1;fillColor=#ffffff;fontSize=12;spacingLeft=5;spacingRight=5;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ property.x }" 
              y="${ property.y }" 
              width="${ PROPERTY_WIDTH }" 
              height="${ PROPERTY_HEIGHT }" 
              as="geometry"
              />
        </mxCell>
        
        <!-- line to the entity -->
        <mxCell 
            id="${ property.id }-line-to-entity" 
            style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=1;exitY=0.5;exitDx=0;exitDy=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;endArrow=none;startFill=0;" 
            edge="1" 
            parent="1" 
            source="${ property.id }" 
            target="${ property.entityId }">
          <mxGeometry relative="1" as="geometry" />
        </mxCell>
    `;
};

const getArrowByCardinalityType = (type: string): string => {
    if (type === 'one') return 'ERone';
    if (type === 'one_or_one') return 'ERmandOne';
    if (type === 'zero_or_one') return 'ERzeroToOne';
    if (type === 'zero_or_many') return 'ERzeroToMany';
    if (type === 'one_or_many') return 'ERoneToMany';
    if (type === 'many') return 'ERmany';

    return 'none';
};

const renderRelationship = (relationship: ErdDiagramRelationshipElement) => {
    return `
        <mxCell 
            id="${ relationship.id }" 
            value="${ relationship.name }" 
            style="rhombus;whiteSpace=wrap;html=1;fillColor=#ffffff;fontStyle=1;fontSize=12;spacingLeft=5;spacingRight=5;" 
            vertex="1" 
            parent="1">
          <mxGeometry 
              x="${ relationship.x }" 
              y="${ relationship.y }" 
              width="${ RELATIONSHIP_WIDTH }" 
              height="${ RELATIONSHIP_HEIGHT }" 
              as="geometry"
              />
        </mxCell>
        
        ${
            relationship.connections.map(connection => {
                return `
                            <mxCell 
                                id="${ connection.id }" 
                                style="rounded=0;orthogonalLoop=1;jettySize=auto;html=1;exitX=1;exitY=0.5;exitDx=0;exitDy=0;entryX=0;entryY=0.5;entryDx=0;entryDy=0;startArrow=${ getArrowByCardinalityType(connection.type) };endArrow=none;startFill=0;endFill=0;" 
                                edge="1" 
                                parent="1" 
                                source="${ connection.entityId }" 
                                target="${ connection.relId }">
                              <mxGeometry relative="1" as="geometry" />
                            </mxCell>
                        `;
            }).join('')
        }
    
    `;
};

export const getTableModelData = (data: IERDData): ITableModel => {
    const tables: ITable[] = data?.entities?.map(entity => {
        return {
            id: entity.id,
            name: entity.name,
            properties: entity.properties?.map(property => ({
                id: uuidv4(),
                name: property.name,
                type: property.isPrimaryKey ? 'primary_key' : 'default',
            })) || [],
        };
    }) || [];

    const relationships = data.relationships || [];

    for (let i = 0; i < relationships.length; i++) {
        const relationship = relationships[i];

        const manyCardinalityEntities: IERDEntity[] = [];
        const oneCardinalityEntities: IERDEntity[] = [];

        for (let j = 0; j < relationship.entities.length; j++) {
            const entity = relationship.entities[j];
            const foundEntity = data.entities.find(item => item.id === entity.entityId);
            if (!foundEntity) continue;

            if (entity.cardinality === 'zero_or_many' ||
                entity.cardinality === 'one_or_many' ||
                entity.cardinality === 'many') {
                manyCardinalityEntities.push(foundEntity);
            }
            else {
                if (entity.cardinality === 'one' ||
                    entity.cardinality === 'one_or_one' ||
                    entity.cardinality === 'zero_or_one') {
                    oneCardinalityEntities.push(foundEntity);
                }
            }
        }

        // Handling Many-to-Many Relationships
        if (manyCardinalityEntities.length > 1) {
            const combinedId = manyCardinalityEntities.map(entity => entity.id).join('-');
            const combinedName = manyCardinalityEntities.map(entity => entity.name).join('');

            const properties: ITableProperty[] = [];

            for (const entity of manyCardinalityEntities) {
                for (const property of entity.properties) {
                    if (property.isPrimaryKey) {
                        properties.push({
                            id: uuidv4(),
                            name: property.name,
                            type: 'primary_key',
                        });
                    }
                }
            }

            tables.push({
                id: combinedId,
                name: combinedName,
                properties,
            });
        }

        // Handling One-to-Many or One-to-One Relationships (Adding Foreign Keys)
        if (oneCardinalityEntities.length === 1 && manyCardinalityEntities.length === 1) {
            const oneEntity = oneCardinalityEntities[0];
            const manyEntity = manyCardinalityEntities[0];

            // Find the table for the "many" entity and add the primary key of the "one" entity as a foreign key
            const manyTable = tables.find(table => table.id === manyEntity.id);
            const onePrimaryKey = oneEntity.properties.find(prop => prop.isPrimaryKey);

            if (manyTable && onePrimaryKey) {
                manyTable.properties.push({
                    id: uuidv4(),
                    name: onePrimaryKey.name,
                    type: 'foreign_key',
                });
            }
        }
    }

    return {
        tables,
    };
};

