import { Fragment, useState } from 'react';
import { CalendarCellType, IBlocksCalendarCell } from '../../../projects/blocks-year/blocks-year-interfaces';
import { getTodoBlocks } from '../../../projects/planner/data/planner-data-service';
import { PreloaderIcon } from '../icons';
import { ITodo } from '../../../projects/planner/planner-interfaces';

interface ICellViewProps {
    cell: IBlocksCalendarCell;
    setOpenedEditTodo: (todo: ITodo|null) => void;
}

const alpha = 0.9;

const CommonBlocksYearCellView = ({ cell, setOpenedEditTodo }: ICellViewProps) => {
    const [loading, setLoading] = useState(false);

    const onClick = async () => {

        const todo = {...cell.todo};

        setLoading(true);
        const response = await getTodoBlocks(todo.todo_id);
        setLoading(false);

        todo.blocks = response || [];

        setOpenedEditTodo(todo);
    };

    return (
        <Fragment key={ cell.id }>
            {
                cell.type === CalendarCellType.EmptyCell &&
                <div
                    data-index={ cell.index }
                    className={ `bg-slate-200 p-1 border-slate-600 border-b text-xs ${ cell.classes } ${ (cell.index + 1) % 7 === 0 ? '' : 'border-r' }` }
                    style={
                        cell.style
                    }
                >&nbsp;</div>
            }

            {
                cell.type === CalendarCellType.DayNumberCell &&
                <div
                    data-index={ cell.index }
                    className={ `p-1 border-slate-600 border-b ${ (cell.index + 1) % 7 === 0 ? '' : 'border-r' } text-center text-xs text-slate-100 ${ cell.isToday ? 'font-bold bg-cyan-700' : 'bg-slate-500' } ${ cell.classes ?? '' }` } style={ cell.style }>
                    { cell.text }
                </div>
            }

            {
                cell.type === CalendarCellType.TodoCell &&
                <>
                    {
                        !loading &&
                        <button
                            type="button"
                            data-block-start={ cell.blockStart }
                            data-block-end={ cell.blockEnd }
                            title={ cell.text }
                            className={ `bg-slate-50 py-1 px-2 border-slate-300 border-b ${ (cell.index + 1) % 7 === 0 ? '' : 'border-r' } text-xs whitespace-nowrap overflow-hidden text-black text-left ${ cell.classes }` }
                            onClick={ onClick }
                            style={ {
                                gridColumnStart: cell.columnStart,
                                gridColumnEnd: cell.columnEnd,
                                background: `linear-gradient(rgba(255, 255, 255, ${ alpha }), rgba(255, 255, 255, ${ alpha })), ${ cell.color }`,
                                ...cell.style,
                            } }>
                            { cell.text }
                        </button>
                    }

                    {
                        loading &&
                        <div className="flex items-center justify-center">
                            <PreloaderIcon
                                size={ 16 }
                                color={ '#fff' }
                            />
                        </div>
                    }
                </>
            }
        </Fragment>
    )
};

export default CommonBlocksYearCellView;