import { IERDData } from './interfaces';
import { getTableModelData } from './domain/erd-diagram-provider';

interface ITableModel {
    data: IERDData;
}

const TableModel = ({ data }: ITableModel) => {

    const tableData = getTableModelData(data);

    return (
        <div dir="rtl" className="mt-4">
            <h2 className="mb-4 text-xl"> מודל טבלאי</h2>

            <p className="mb-2">המרת דיאגרמת ישויות-קשרים למודל טבלאי:</p>

            <ul className="list-disc">
                {
                    tableData.tables.map(table => {
                        return (
                            <li key={ table.id } className="mx-2 px-2">
                                { table.name } &nbsp;

                                (
                                {
                                    table.properties.map((property, i) => {

                                        const isLast = i === table.properties.length - 1;
                                        let classes = '';

                                        if(property.type === 'primary_key') {
                                            classes = 'underline';
                                        }
                                        else{
                                            if(property.type === 'foreign_key') {
                                                classes = 'underline decoration-dashed';
                                            }
                                        }

                                        return (
                                            <span
                                                key={ `property-${ i }` }>
                                                <span className={ classes }>{ property.name }</span>{ isLast ? '' : ', ' }
                                            </span>
                                        )
                                    })
                                }
                                )
                            </li>
                        )
                    })
                }
            </ul>
            {/*-	כרטיס טיסה (מספר כרטיס טיסה, מחיר, תאריך טיסה, יעד, ברקוד, דרכון של לקוח, מזהה מחלקה, מזהה ארוחה)
            -	לקוח (דרכון, שם פרטי, שם משפחה, טלפון, אימייל)
            -	מחלקה (מזהה מחלקה, תשלום נוסף, שם מחלקה)
            -	ארוחה (מזהה ארוחה, שם ארוחה, תפריט)
            -	כבודה (מספר שובר כבודה, משקל, מספר כרטיס טיסה)
            -	סקר (מזהה סקר, פרטי סקר, תאריך סקר)
            -	סקר לקוחות (מזהה סקר, דרכון של לקוח)*/}

        </div>
    )
};

export default TableModel;

