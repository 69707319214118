import { endOfYear, startOfYear } from 'date-fns';
import { ITag, ITodo, TodoType } from '../../../projects/planner/planner-interfaces';
import {
    blocksUpdateTodo, deleteBlockForYBlocks,
    insertBlockForYBlocks,
    updateBlockForYBlocks
} from '../../../projects/blocks-year/data/blocks-year-data-service';
import { showToast } from '../../domain/toast-provider';
import { createYearBlocksCalendar } from '../../../projects/blocks-year/domain/blocks-year-provider';
import Popup from '../popup';
import { markdown2Html } from '../../markdown/markdown-provider';
import { ExternalLink } from '../icons';
import CommonTodoBlocksEdit from '../todo/todo-blocks/common-todo-blocks-edit';
import CommonTodoEdit from '../todo/common-todo-edit';
import { IBlocksCalendarYear } from '../../../projects/blocks-year/blocks-year-interfaces';

interface IBlocksTodoEditForm {
    onClose: () => void;
    currentYear: number;
    tags: ITag[];
    setData: (_data: IBlocksCalendarYear|null) => void;
    tag_id: number|null;
    openedEditTodo: ITodo|null;
}

const CommonBlocksYearTodoEditForm = ({ onClose, currentYear, tags , setData, tag_id, openedEditTodo }: IBlocksTodoEditForm) => {

    const save = async (
        todo_id: number | string,
        new_title: string,
        new_desc: string,
        todo_address: string,
        todo_date: number | null,
        todo_date_has_time: number,
        todo_is_active: number,
        show_in_overview: number,
        todo_type: TodoType,
        update_nested: boolean,
        tags: string,
        ideal_final_result: string,
        habit_active?: boolean,
        habit_interval?: number,
        habit_qty_per_interval?: number,
        habit_week_days?: string,
        projectId?: number,
        sectionId?: number,
    ) => {

        const date = new Date(currentYear, 0, 1); // January is 0
        const fromDate = startOfYear(date).getTime();
        const toDate = endOfYear(date).getTime();

        const response = await blocksUpdateTodo(
            // Blocks data that is used to render blocks view ------
            fromDate,
            toDate,
            tag_id || 0,

            // Todo_ data -------------
            todo_id,
            new_title.trim(),
            new_desc.trim(),
            todo_date,
            todo_date_has_time ? 1 : 0,
            todo_is_active ? 1 : 0,
            todo_type,
            update_nested,
            tags,
            todo_address,
            show_in_overview,
            ideal_final_result,
            habit_active,
            habit_interval,
            habit_qty_per_interval,
            habit_week_days,
            projectId,
            sectionId,
        );

        if(!response) {
            showToast('Update todo error.');
            return;
        }

        onClose();

        const result = createYearBlocksCalendar(date.getFullYear(), response);
        setData(result);
    };

    const onTodoClose = () => {
        onClose();
    };

    const onInsert = async (
        todo_id: string|number,
        startDate: number,
        endDate: number
    ) => {
        const date = new Date(currentYear, 0, 1); // January is 0
        const fromDate = startOfYear(date).getTime();
        const toDate = endOfYear(date).getTime();

        const response = await insertBlockForYBlocks(
            // Blocks data that is used to render blocks view ------
            fromDate,
            toDate,
            tag_id || 0,

            // The actual data
            todo_id,
            startDate,
            endDate
        );

        if(!response) {
            showToast('Insert block error.');
            return;
        }

        const result = createYearBlocksCalendar(date.getFullYear(), response);
        setData(result);
    };

    const onUpdate = async (
        block_id: number,
        startDate: number,
        endDate: number
    ) => {
        const date = new Date(currentYear, 0, 1); // January is 0
        const fromDate = startOfYear(date).getTime();
        const toDate = endOfYear(date).getTime();

        const response = await updateBlockForYBlocks(

            // Blocks data that is used to render blocks view ------
            fromDate,
            toDate,
            tag_id || 0,

            // The actual data
            block_id,
            startDate,
            endDate
        );

        if(!response) {
            showToast('Update block error.');
            return;
        }

        const result = createYearBlocksCalendar(date.getFullYear(), response);
        setData(result);
        onClose();
    };

    const onDelete = async (block_id: number) => {

        const date = new Date(currentYear, 0, 1); // January is 0
        const fromDate = startOfYear(date).getTime();
        const toDate = endOfYear(date).getTime();

        const response = await deleteBlockForYBlocks(
            // Blocks data that is used to render blocks view ------
            fromDate,
            toDate,
            tag_id || 0,

            // The actual data
            block_id
        );

        if(!response) {
            showToast('Delete block error.');
            return;
        }

        const result = createYearBlocksCalendar(date.getFullYear(), response);
        setData(result);
    };

    return (
        <Popup onClose={ onClose } width={ 650 } height={ 650 }>
            {
                openedEditTodo &&
                <div className="p-4 flex flex-col overflow-auto text-left font-normal text-gray-900">

                    <div className="flex items-center">
                        <h1
                            className="text-2xl text-slate-800"
                            dangerouslySetInnerHTML={ { __html: markdown2Html(openedEditTodo.todo_title) } }
                        />

                        <a
                            href={ `/planner/${ openedEditTodo.project_id }#todo-${ openedEditTodo.todo_id }` }
                            target="_blank"
                            rel="noreferrer"
                            className="mx-4">
                            <ExternalLink
                                title="Open in new tab"
                                size={ 20 }
                                color={ `#14638f` }
                            />
                        </a>
                    </div>

                    <div className={ `todo-blocks-edit flex` }>
                        <CommonTodoBlocksEdit
                            todo={ openedEditTodo }
                            close={ null }
                            onInsert={ onInsert }
                            onUpdate={ onUpdate }
                            onDelete={ onDelete }
                        />
                    </div>

                    <CommonTodoEdit
                        todo={ openedEditTodo }
                        allTags={ tags || [] }
                        saveCallback={ save }
                        onClose={ onTodoClose }
                        shouldEditTitle={ true }
                        showProjectsChooser={ true }
                    />

                </div>
            }
        </Popup>
    )
};

export default CommonBlocksYearTodoEditForm;