import { useFinanceStore } from '../../../data/finance-store';
import GasItem from './gas-item';
import GasListFilters from '../filters/gas-list-filters';

const GasList = () => {

    const gasData = useFinanceStore(store => store.gasData);

    return (
        <div>
            <GasListFilters />

            {
                gasData?.gasItems?.map(gas => {
                    return (
                        <GasItem
                            key={ gas.GasID }
                            gas={ gas }
                        />
                    )
                })
            }
        </div>
    )
};

export default GasList;