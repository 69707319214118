import OpenuSolverMenuItem from './openu-solver-menu-item';
import { NavLink } from 'react-router-dom';

const OpenuSolverMenu = () => {

    return (
        <div className="side-menu flex flex-col xl:w-[350px] bg-slate-800 text-slate-100 leading-7 h-screen color-scheme-dark">
            <div className="overflow-auto p-3">
                <div className="pb-3 pt-3 flex flex-col">

                    <NavLink
                        to={ '/openu-solver' }
                        end={ true }
                        className="text-left flex-1 ml-2 mb-2">
                        🛖 &nbsp; Root
                    </NavLink>

                    <OpenuSolverMenuItem
                        text={ 'כריית מידע 20595' }
                        isTitle={ true }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/data-mining/decision-tree' }
                        text={ 'Decision Tree' }
                        number={ 1 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        text={ 'עקרונות פיתוח מערכות מידע' }
                        isTitle={ true }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/context-diagram' }
                        text={ 'דיאגרמת תוכן' }
                        number={ 1 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/dfd-diagram' }
                        text={ 'דיאגרמת בועות (DFD)' }
                        number={ 2 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/dfd-diagram-2' }
                        text={ 'דיאגרמת בועות (DFD) 2' }
                        number={ 2 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/erd-diagram' }
                        text={ 'דיאגרמת ישיויות קשרים (ERD)' }
                        number={ 3 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/dialogue-diagram' }
                        text={ 'עץ התפריטים (מסכים)' }
                        number={ 4 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/structure-chart' }
                        text={ 'עץ מודולים' }
                        number={ 5 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/class-diagram' }
                        text={ 'דיאגרמת מחלקות' }
                        number={ 6 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/state-diagram' }
                        text={ 'דיאגרמת מצבים' }
                        number={ 7 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/sequence-diagram' }
                        text={ 'דיאגרמת רצף' }
                        number={ 8 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/collaboration-diagram' }
                        text={ 'דיאגרמת שיתוף פעולה' }
                        number={ 9 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/use-case-diagram' }
                        text={ 'דיאגרמת תרחישים' }
                        number={ 10 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/activity-diagram' }
                        text={ 'דיאגרמת פעילויות' }
                        number={ 11 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/report' }
                        text={ 'דו״ח' }
                        number={ 12 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/screen' }
                        text={ 'מסך' }
                        number={ 13 }
                        isTitle={ false }
                    />

                    <OpenuSolverMenuItem
                        link={ '/openu-solver/access/decision-table' }
                        text={ 'טבלת החלטה' }
                        number={ 13 }
                        isTitle={ false }
                    />

                </div>

            </div>
        </div>
    )
};

export default OpenuSolverMenu;