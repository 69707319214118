import { ICombinedSection, IProject } from '../../planner-interfaces';
import AddSection from './add-section';
import SectionTitle from './section-title';
import SectionTodos from './section-todos';
import SectionDescription from './description/section-description';
import SectionDragTarget from './drag/section-drag-target';
import { canAddSections, isSpecialProjectType, shouldHideTitles } from '../../domain/planner-provider';
import { usePlannerStore } from '../../data/planner-store';
import { getTodosFilteredByTags } from '../../domain/tags-provider';

export enum SectionMode {
    DEFAULT = 0,
    EDIT = 1,
}

interface ISectionProps {
    project: IProject;
    combinedSection: ICombinedSection;
    isFirst: boolean;
    showEmptyTitle: boolean;
}

const Section = (props: ISectionProps) => {

    const { combinedSection, project, showEmptyTitle } = props;
    const { section } = combinedSection;

    const listType = usePlannerStore(state => state.listType);
    const selectedFilterTags = usePlannerStore(state => state.selectedFilterTags);
    const _projectHideTitles = usePlannerStore(store => store.projectHideTitles);
    const projectHideTitles = shouldHideTitles(_projectHideTitles, listType, selectedFilterTags);
    const isSpecial = isSpecialProjectType(listType);

    const shouldHideProjectTitles = isSpecial && projectHideTitles;
    const titleVisible = !shouldHideProjectTitles && (!!section.section_title || (!section.section_title && showEmptyTitle));
    const ifCanAddSections = canAddSections(listType);

    const [isApplied, combinedSectionTodos] = getTodosFilteredByTags(combinedSection.todos, listType, selectedFilterTags);

    return (
        <>
            {
                (!isApplied || (isApplied && combinedSectionTodos.length > 0)) &&
                <div data-section-id={ combinedSection.section.section_id }>
                    {
                        titleVisible &&
                        <SectionTitle
                            combinedSection={ combinedSection }
                            project={ project }
                        />
                    }

                    {
                        combinedSection.isOpened && !isSpecial &&
                        <SectionDescription section={ section }/>
                    }

                    <SectionTodos
                        combinedSection={ combinedSection }
                        project_id={ project.project_id }
                        titleVisible={ titleVisible }
                    />

                    {
                        ifCanAddSections &&
                        <AddSection
                            isTodayProject={ project.project_title.toLowerCase() === 'today' }
                            section_order={ combinedSection.section.section_order }
                            project_id={ project.project_id }
                        />
                    }

                    <SectionDragTarget
                        targetProjectId={ project.project_id }
                        targetSectionId={ section.section_id }
                        targetOrder={ section.section_order }
                    />
                </div>
            }
        </>
    )
};

export default Section;