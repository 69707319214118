import { MouseEvent, useEffect } from 'react';
import { useAuthStatus } from '../auth/hooks/auth-status-hook';
import Preloader from '../../common/ui/preloader';
import MobileMenuBtn from '../../menu/ui/mobile-menu-btn';
import Menu from '../../menu/menu';
import OpenuSolverMenu from './ui/menu/openu-solver-menu';
import { useLocation } from 'react-router-dom';
import RootScreen from './ui/root-screen';
import DialogueDiagramHome from './ui/access/dialogue-diagram/dialogue-diagram-home';
import ContextDiagramHome from './ui/access/context-diagram/context-diagram-home';
import DfdDiagramHome2 from './ui/access/dfd-diagram-2/dfd-diagram-home';
import ErdDiagramHome from './ui/access/erd-diagram/erd-diagram-home';
import ClassDiagramHome from './ui/access/class-diagram/class-diagram-home';
import StateDiagramHome from './ui/access/state-diagram/state-diagram-home';
import SequenceDiagramHome from './ui/access/sequence-diagram/sequence-diagram-home';
import CollaborationDiagramHome from './ui/access/collaboration-diagram/collaboration-diagram-home';
import StructureChartHome from './ui/access/structure-chart/structure-chart-home';
import UseCaseDiagramHome from './ui/access/use-case-diagram/use-case-diagram-home';
import ActivityDiagramHome from './ui/access/activity-diagram/activity-diagram-home';
import ReportHome from './ui/access/report/report-home';
import ScreenHome from './ui/access/screen/screen-home';
import DecisionTableHome from './ui/access/decision-table/decision-table-home';
import DfdDiagramHome from './ui/access/dfd-diagram/dfd-diagram-home';
import DecisionTreeHome from './ui/data-mining/decision-tree/decision-tree-home';

const OpenuSolverHome = () => {

    const { loading } = useAuthStatus();

    const { pathname } = useLocation();

    useEffect(() => {
        document.title = 'OPENU Solver | Productivity';
    }, []);

    const stopPropagation = (evt: MouseEvent<HTMLDivElement>) => {
        evt.stopPropagation();
    };

    return (
        <div className="text-slate-800">
            { loading && <Preloader/> }

            {
                !loading &&
                <div className="flex min-h-screen relative overflow-hidden">

                    <MobileMenuBtn />

                    <div
                        onClick={ stopPropagation }
                        className="mobile-menu-sidebar flex absolute z-[2000] right-0 translate-x-full transition-transform xl:static xl:translate-x-0">
                        <Menu showIcons={ true } />
                        <OpenuSolverMenu />
                    </div>

                    <div className="flex flex-col flex-1 leading-7 h-screen relative z-50 bg-white w-screen">
                        <div className="overflow-auto px-4 pb-10">
                            <div>

                                {
                                    pathname === '/openu-solver' && <RootScreen />
                                }

                                {
                                    pathname === '/openu-solver/data-mining/decision-tree' && <DecisionTreeHome />
                                }

                                {
                                    pathname === '/openu-solver/access/context-diagram' && <ContextDiagramHome />
                                }

                                {
                                    pathname === '/openu-solver/access/dfd-diagram' && <DfdDiagramHome />
                                }

                                {
                                    pathname === '/openu-solver/access/dfd-diagram-2' && <DfdDiagramHome2 />
                                }

                                {
                                    pathname === '/openu-solver/access/erd-diagram' && <ErdDiagramHome />
                                }

                                {
                                    pathname === '/openu-solver/access/dialogue-diagram' && <DialogueDiagramHome />
                                }

                                {
                                    pathname === '/openu-solver/access/structure-chart' && <StructureChartHome />
                                }

                                {
                                    pathname === '/openu-solver/access/class-diagram' && <ClassDiagramHome />
                                }

                                {
                                    pathname === '/openu-solver/access/state-diagram' && <StateDiagramHome />
                                }

                                {
                                    pathname === '/openu-solver/access/sequence-diagram' && <SequenceDiagramHome />
                                }

                                {
                                    pathname === '/openu-solver/access/collaboration-diagram' && <CollaborationDiagramHome />
                                }

                                {
                                    pathname === '/openu-solver/access/use-case-diagram' && <UseCaseDiagramHome />
                                }

                                {
                                    pathname === '/openu-solver/access/activity-diagram' && <ActivityDiagramHome />
                                }

                                {
                                    pathname === '/openu-solver/access/report' && <ReportHome />
                                }

                                {
                                    pathname === '/openu-solver/access/screen' && <ScreenHome />
                                }

                                {
                                    pathname === '/openu-solver/access/decision-table' && <DecisionTableHome />
                                }

                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
};

export default OpenuSolverHome;